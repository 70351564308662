import { GetDataToTablePayload, TableServerResponse } from 'types/Table'

import { baseApi } from 'api/api'

import { NewAdmin, AdminMutationResponse, Admin, AdminReviewers, Seller } from './types'

export const reviewApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAdmins: build.query<TableServerResponse<Admin[]>, GetDataToTablePayload>({
      query: (data) => ({
        url: `/admins`,
        method: 'GET',
        params: data,
      }),
      providesTags: (result) => {
        if (!result?.data) {
          return [{ type: 'Admins', id: 'LIST' }]
        }
        return [
          ...result.data.map(({ id }) => ({ type: 'Admins', id }) as const),
          { type: 'Admins', id: 'LIST' },
        ]
      },
    }),
    getAdmin: build.query<TableServerResponse<Admin>, string>({
      //??? why table response?
      query: (id) => ({
        url: `/admins/${id}`,
        method: 'GET',
      }),
    }),
    createAdmin: build.mutation<AdminMutationResponse, NewAdmin>({
      query: (newAdmin: NewAdmin) => ({
        url: '/admins',
        method: 'POST',
        data: newAdmin,
        fromData: true,
      }),
      invalidatesTags: [{ type: 'Admins', id: 'LIST' }],
    }),
    updateAdmin: build.mutation<AdminMutationResponse, NewAdmin & { id: string }>({
      query: ({ id, ...data }) => ({
        url: `/admins/${id}`,
        method: 'PUT',
        data: data,
        fromData: true,
      }),
      invalidatesTags: [{ type: 'Admins', id: 'LIST' }],
    }),
    deleteAdmin: build.mutation({
      query: (id) => ({
        url: `admins/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Admins', id: 'LIST' }],
    }),

    //new Admin API
    getSellers: build.query<TableServerResponse<Seller[]>, GetDataToTablePayload>({
      query: (params) => ({
        url: `/admin/users/list_sellers`,
        method: 'GET',
        params,
      }),
    }),
    getReviewers: build.query<TableServerResponse<AdminReviewers[]>, GetDataToTablePayload>({
      query: (params) => ({
        url: `/admin/users/list_reviewers`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useCreateAdminMutation,
  useGetAdminsQuery,
  useGetAdminQuery,
  useUpdateAdminMutation,
  useDeleteAdminMutation,
  useGetSellersQuery,
  useGetReviewersQuery,
} = reviewApi
