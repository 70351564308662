import { isRejectedWithValue, type Middleware, PayloadAction } from '@reduxjs/toolkit'

import { TOO_MANY_REQUESTS_STATUS } from 'constants/requestsStatuses'

import { showNotification } from 'utils/showNotification'

const serverErrorToString = (error: any): string => {
  return error?.data?.detail ? error.data.detail : 'Something went wrong'
}

export const errorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if ((action as PayloadAction<{ status: number }>).payload.status !== TOO_MANY_REQUESTS_STATUS) {
      showNotification(serverErrorToString(action.payload || 'Error'), 'error')
    }
  }
  return next(action)
}
