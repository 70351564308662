import { FC } from 'react'

import { Box } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetShopsAdminQuery } from 'services/admin/shops/api'

import PageHeader from 'components/common/PageHeader'
import SearchInput from 'components/common/SearchInput'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'

const AdminShops: FC = () => {
  const { queryParams, pagination, sort, search, setPagination, setSort, setSearch, isFiltered } =
    useDataGridPagination()

  const { data, isLoading, isFetching } = useGetShopsAdminQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  })

  return (
    <>
      <PageHeader title="All Shops" wrapperSx={{ justifyContent: 'flex-start', gap: '1rem' }} />
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mb: '1.5rem' }}>
          <SearchInput initValue={search} onSearchChange={setSearch} />
        </Box>
        <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
          <CustomDataGrid
            data={data}
            columns={columns}
            loading={isLoading || isFetching}
            paginationModel={pagination}
            setPagination={setPagination}
            setSort={setSort}
            initSort={sort}
            isFiltered={isFiltered}
            rowHeight={72}
          />
        </Box>
      </Box>
    </>
  )
}

export default AdminShops
