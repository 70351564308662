import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ReactComponent as NoData } from 'assets/svg/noData.svg'

interface Props {
  title?: string
  isFiltered: boolean
}

const NoDataInDataGridTable = ({
  title = "There's no data in the table yet.",
  isFiltered,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: '0.5rem',
      }}
    >
      <NoData />
      <Typography sx={{ color: 'text.secondary', fontSize: '0.875rem', textAlign: 'center' }}>
        {isFiltered ? 'Nothing was found for your request.' : title}
      </Typography>
    </Box>
  )
}

export default NoDataInDataGridTable
