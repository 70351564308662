import { useState, MouseEvent, useEffect, Dispatch, SetStateAction } from 'react'

import {
  Box,
  Popover,
  List,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Chip,
  Divider,
} from '@mui/material'

import Button from 'components/common/Button'

import { Filter } from './types'

interface Props<T> {
  filters: Filter<T>[]
  init?: string[]
  filterChange: Dispatch<SetStateAction<string[]>>
}

const StatusesFilter = function <T>({ filters, init = [], filterChange }: Props<T>) {
  const activeFilters = filters.filter((item) => {
    const status = item.status as string
    return init.includes(status.toString())
  })
  const [checkedFilters, setCheckedFilters] = useState<Filter<T>[]>(activeFilters)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleFiltersOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const result = checkedFilters.map((a) => a.status as string)
    filterChange(result)
  }, [checkedFilters])

  const handleFilterToggle = (item: Filter<T>) => () => {
    const currentIndex = checkedFilters.map((e) => e.status).indexOf(item.status)
    const newChecked = filters.length > 2 ? [...checkedFilters] : []

    if (currentIndex === -1) {
      newChecked.push(item)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheckedFilters(newChecked)
  }

  const handleFilterDelete = (item: Filter<T>) => {
    const currentIndex = checkedFilters.map((e) => e.status).indexOf(item.status)
    const newChecked = [...checkedFilters]
    newChecked.splice(currentIndex, 1)
    setCheckedFilters(newChecked)
  }

  const handleResetFilter = () => {
    setCheckedFilters([])
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Button
        sx={{ padding: '0 2.125rem', mr: '0.5rem' }}
        onClick={handleFiltersOpen}
        variant="outlined"
      >
        Filter
      </Button>
      {checkedFilters.map((item, index) => (
        <Chip
          sx={{ mx: '0.125rem' }}
          key={index}
          label={item.label}
          onDelete={() => handleFilterDelete(item)}
        />
      ))}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: '0.25rem' }}
      >
        <List
          sx={{
            width: '13.75rem',
            bgcolor: 'background.paper',
            padding: 0,
          }}
          dense
          component="div"
          role="list"
        >
          {filters.map((value, index) => {
            const labelId = `transfer-list-all-item-${index}-label`
            return (
              <ListItemButton key={labelId} role="listitem" onClick={handleFilterToggle(value)}>
                <ListItemIcon sx={{ marginRight: '-1rem' }}>
                  <Checkbox
                    checked={checkedFilters.map((e) => e.status).indexOf(value.status) !== -1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${value.label}`}
                  primaryTypographyProps={{ fontSize: '1rem', color: '#828282' }}
                />
              </ListItemButton>
            )
          })}

          {filters.length > 3 && (
            <>
              <Divider />
              <ListItemButton
                onClick={handleResetFilter}
                sx={{ padding: '0.5rem', justifyContent: 'center' }}
                disabled={!checkedFilters.length}
              >
                Reset
              </ListItemButton>
            </>
          )}
        </List>
      </Popover>
    </Box>
  )
}

export default StatusesFilter
