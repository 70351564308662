import { Navigate, useLocation } from 'react-router-dom'
import { ReactElement } from 'react'

import KeycloakService from 'services/KeycloakService'

const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const location = useLocation()

  if (!KeycloakService.isLoggedIn() && location.pathname === '/dashboard') {
    return <Navigate to="/welcome" />
  }

  if (!KeycloakService.isLoggedIn()) {
    KeycloakService.doLogin()
    return null
  }

  return children
}

export default ProtectedRoute
