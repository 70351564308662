import { FC, useState, MouseEvent } from 'react'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Typography, Popover, Box } from '@mui/material'

interface Props {
  maxAmount: number
}

const AmountInfoPopover: FC<Props> = ({ maxAmount }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Typography
        component="span"
        lineHeight={0.5}
        color="#BDBDBD"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ErrorOutlineIcon />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ width: '17.4375rem', p: '1rem', backgroundColor: '#F2F4F8' }}>
          <Typography>The coupon amount should not exceed ${maxAmount}</Typography>
        </Box>
      </Popover>
    </>
  )
}

export default AmountInfoPopover
