import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Button, Link, Tooltip } from '@mui/material'
import debounce from '@mui/material/utils/debounce'
import { GridColDef } from '@mui/x-data-grid'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetProductsQuery } from 'services/products/api'
import { ListProductsRecord } from 'services/products/types'
import { useSyncShopWithShopifyMutation } from 'services/shops/api'
import { Shop } from 'services/shops/types'

import { ReactComponent as ProductImage } from 'assets/svg/product.svg'

import { EllipsedText } from 'components/common/EllipsedText'
import SearchInput from 'components/common/SearchInput'
import CustomDataGrid from 'components/table/CustomDataGrid'

const syncProductsDebounceTime = 500

interface Props {
  shop?: Shop
}

const UserProductsByStore: FC<Props> = ({ shop }) => {
  const { shopId } = useParams()

  const { queryParams, pagination, sort, search, setPagination, setSort, setSearch, isFiltered } =
    useDataGridPagination()

  const { data, isLoading, isFetching } = useGetProductsQuery(
    {
      ...queryParams,
      shopId: shopId,
    },
    { refetchOnMountOrArgChange: true }
  )

  const [startSync, { isLoading: syncLoading }] = useSyncShopWithShopifyMutation()

  const startSyncHandle = debounce(() => {
    if (shopId) startSync(shopId)
  }, syncProductsDebounceTime)

  const columns = useMemo<GridColDef<ListProductsRecord>[]>(
    () => [
      {
        field: 'title',
        headerName: 'Product Name',
        flex: 2,
        sortable: true,
        renderCell: ({ row }) => (
          <Link href={`${row.id}`} sx={{ textDecoration: 'none', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  borderRadius: '0.5rem',
                  overflow: 'hidden',
                  minWidth: '3rem',
                  width: '3rem',
                  height: '3rem',
                }}
              >
                {row.imageUrl ? (
                  <img
                    src={row.imageUrl}
                    alt={row.name}
                    loading="lazy"
                    style={{ minWidth: '3rem', width: '3rem', height: '3rem', objectFit: 'cover' }}
                  />
                ) : (
                  <ProductImage style={{ width: '3rem', height: '3rem' }} />
                )}
              </Box>
              <EllipsedText
                title={row.name}
                sx={{ fontSize: '0.875rem', fontWeight: 500, ml: '0.5rem', color: '#121619' }}
              />
            </Box>
          </Link>
        ),
      },
      {
        field: 'shop',
        headerName: 'Shop Name',
        type: 'string',
        flex: 2,
        filterable: false,
        valueGetter: ({ row }) => {
          return row.shopName
        },
      },
      {
        field: 'approvedReviewsCount',
        headerName: 'Published reviews',
        type: 'string',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
      },
      {
        field: 'moderationReviewsCount',
        headerName: 'Reviews for moderation',
        type: 'string',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
      },
    ],
    []
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
        {!shop?.shopifyAccessToken ? (
          <Tooltip title="Link your Shopify store to ensure our service works properly. Click 'Edit', check your store name and choose 'Save'">
            <Box>
              <Button variant="outlined" disabled>
                Sync with Shopify
              </Button>
            </Box>
          </Tooltip>
        ) : (
          <Tooltip title="Please wait until the synchronization is complete.This process may take a few moments.">
            <Button onClick={startSyncHandle} variant="outlined" disabled={syncLoading}>
              Sync with Shopify
            </Button>
          </Tooltip>
        )}
        <SearchInput initValue={search} onSearchChange={setSearch} />
      </Box>
      <Box sx={{ height: 'calc(100vh - 10.5rem)', width: '100%' }}>
        <CustomDataGrid
          data={data}
          columns={columns}
          loading={isLoading || isFetching}
          paginationModel={pagination}
          setPagination={setPagination}
          setSort={setSort}
          initSort={sort}
          rowHeight={72}
          isFiltered={isFiltered}
        />
      </Box>
    </Box>
  )
}

export default UserProductsByStore
