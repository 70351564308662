import { skipToken } from '@reduxjs/toolkit/query'
import { useParams } from 'react-router-dom'

import { Box, Skeleton } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetShopsAdminQuery } from 'services/admin/shops/api'
import { useGetUserQuery } from 'services/users/api'

import PageHeader from 'components/common/PageHeader'
import SearchInput from 'components/common/SearchInput'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'

export const ShopsBySeller = () => {
  const { sellerId } = useParams()
  const { queryParams, pagination, sort, search, setPagination, setSort, setSearch, isFiltered } =
    useDataGridPagination()

  const { data: seller, isLoading: isSellerLoading } = useGetUserQuery(
    sellerId ? { id: sellerId } : skipToken
  )

  const {
    data: shops,
    isLoading,
    isFetching,
  } = useGetShopsAdminQuery(
    { sellerId, ...queryParams },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  return (
    <>
      <PageHeader
        title={
          isSellerLoading ? (
            <Skeleton width="12rem" height="3.3rem" />
          ) : (
            `${seller?.firstName} ${seller?.lastName}`
          )
        }
        backPath="/admin/sellers"
      />
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mb: '1.5rem' }}>
          <SearchInput initValue={search} onSearchChange={setSearch} />
        </Box>
        <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
          <CustomDataGrid
            data={shops}
            columns={columns}
            loading={isLoading || isFetching}
            paginationModel={pagination}
            setPagination={setPagination}
            setSort={setSort}
            initSort={sort}
            isFiltered={isFiltered}
            rowHeight={72}
          />
        </Box>
      </Box>
    </>
  )
}
