import { FC } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'

import formatDate from 'utils/formatToLocalTimezone'

import { ListReviewsRecord } from 'services/reviews/types'

import CardPreloader from 'components/common/CardPreloader'

const cellStyle = { fontSize: '0.875rem', fontWeight: 600, whiteSpace: 'nowrap' }

interface Props {
  reviews: ListReviewsRecord[]
  isLoading?: boolean
}

const LatestPayments: FC<Props> = ({ reviews, isLoading = false }) => {
  return (
    <Card sx={{ height: '100%', position: 'relative' }}>
      {isLoading && <CardPreloader />}
      <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column', p: '1.25rem' }}>
        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.5rem' }}>
          Latest Payments
        </Typography>
        {reviews.length ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: grey[200] }}>
                <TableRow>
                  <TableCell sx={{ ...cellStyle, width: '20%' }}>Date</TableCell>
                  <TableCell sx={{ ...cellStyle, align: 'left', width: '50%' }}>Shop</TableCell>
                  <TableCell sx={{ ...cellStyle, align: 'center' }}>Payment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviews.map((review) => (
                  <TableRow
                    key={review.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      height: '3rem',
                    }}
                  >
                    <TableCell component="th" scope="row" sx={cellStyle}>
                      {formatDate(review.updatedAt, 'dd MMM, yyyy')}
                    </TableCell>
                    <TableCell sx={{ ...cellStyle, align: 'left' }}>{review.shopName}</TableCell>
                    <TableCell sx={{ ...cellStyle, align: 'center' }}>${review.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              py: '5rem',
            }}
          >
            There is no latest payments yet.
          </Box>
        )}
      </Box>
    </Card>
  )
}

export default LatestPayments
