import { Link } from 'react-router-dom'

import { Tooltip, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'

import { AdminProduct } from 'services/admin/products/types'

export const columns: GridColDef<AdminProduct>[] = [
  {
    field: 'name',
    headerName: 'Product name',
    type: 'string',
    flex: 2,
    sortable: false,
    renderCell: ({ row }) => (
      <Tooltip title={row.name}>
        <Link to={`${row.id}`}>{row.name}</Link>
      </Tooltip>
    ),
  },
  {
    field: 'shop',
    headerName: 'Shop Name',
    type: 'string',
    flex: 2,
    sortable: false,
    renderCell: ({ row }) => (
      <Tooltip title={row.shop.name}>
        <Link to={`/admin/shops/${row.shop.id}`}>{row.shop.name}</Link>
      </Tooltip>
    ),
  },
  {
    field: 'seller',
    headerName: 'Seller Name',
    type: 'string',
    flex: 2,
    sortable: false,
    renderCell: ({ row }) => (
      <Tooltip title={`${row.shop.user.firstName} ${row.shop.user.lastName}`}>
        <Link
          to={`/admin/sellers/${row.shop.user.id}/shops`}
        >{`${row.shop.user.firstName} ${row.shop.user.lastName}`}</Link>
      </Tooltip>
    ),
  },
  {
    field: 'link',
    headerName: 'Product Link',
    type: 'string',
    flex: 2,
    sortable: false,
    renderCell: ({ row }) => (
      <Tooltip title={row.link}>
        <Typography>{row.link}</Typography>
      </Tooltip>
    ),
  },
  {
    field: 'defaultReviewPrice',
    headerName: 'Bonus, $',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'approvedReviewsCount',
    headerName: 'Approved',
    type: 'string',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'moderationReviewsCount',
    headerName: 'On Moderation',
    type: 'string',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'rejectedReviewsCount',
    headerName: 'Rejected',
    type: 'string',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'totalPayments',
    headerName: 'Payments',
    type: 'string',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
]
