import { FC } from 'react'

import { Box } from '@mui/material'

import { useGetReviewerReceivedQuery } from 'services/balance/api'

import EmptyState from 'components/common/EmptyState'
import Preloader from 'components/common/Preloader'
import ReceivedItem from 'components/coupons/ReceivedItem'

const buyCoupons: FC = () => {
  const { data, isLoading } = useGetReviewerReceivedQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  if (isLoading) return <Preloader />

  return (
    <>
      {!data?.length && <EmptyState text="You haven't earned any rewards to purchase a coupon." />}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          mb: '2.5rem',
          gap: '1rem',

          justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
        }}
      >
        {data?.map((item) => <ReceivedItem received={item} key={item.sellerId} />)}
      </Box>
    </>
  )
}

export default buyCoupons
