import React, { useEffect, useRef, useState } from 'react'

import { Box, Typography, useMediaQuery, Theme } from '@mui/material'

import { HowItWorksItem } from 'types/Landings'

interface Props {
  direction: 'column' | 'row'
  items: HowItWorksItem[]
  textMaxWidth: number
}

const textAlignMap = {
  left: 'row',
  right: 'row-reverse',
  bottom: 'column',
  top: 'column-reverse',
} as const

export const HowItWorks = ({ direction, items, textMaxWidth }: Props) => {
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const ref = useRef<HTMLDivElement>()
  const [height, setHeight] = useState<number>()

  useEffect(() => {
    setHeight(ref.current?.clientHeight)
  }, [ref])
  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: direction,
        gap: '1.5rem',
        position: 'relative',
        width: '100%',
        alignItems: direction === 'column' ? 'center' : 'flex-start',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: -1,
          top: direction === 'column' ? '4rem' : '4rem',
          left: isXs ? '11%' : 'none',
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,

          width: direction === 'row' ? 'calc(100% - 10rem)' : '1px',
          height: direction === 'column' ? `calc(${height}px - 10rem)` : '1px',
        }}
      />
      {items.map((item, ind) => (
        <Box
          key={item.title}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection:
              direction === 'column'
                ? isXs
                  ? 'right'
                  : ind % 2 === 0
                    ? 'row'
                    : 'row-reverse'
                : textAlignMap[item.textPosition],
            alignItems: 'center',
            justifyContent: direction === 'column' ? (isXs ? 'flex-start' : 'center') : 'center',
            gap: '1rem',
            py: '1.5rem',
          }}
        >
          {direction === 'column' && !isXs && (
            <Box sx={{ maxWidth: textMaxWidth, width: '100%' }} />
          )}
          <Box
            sx={{
              minWidth: '5rem',
              width: '5rem',
              height: '5rem',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: '#CBFA92',
            }}
          >
            {item.icon}
          </Box>
          <Box sx={{ maxWidth: textMaxWidth, width: '100%' }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: ['1rem', '1.25rem', '1.25rem', '1.25rem', '1.25rem'],
                textAlign:
                  direction === 'column'
                    ? isXs
                      ? 'left'
                      : ind % 2 === 0
                        ? 'left'
                        : 'right'
                    : 'center',
              }}
            >
              {item.title}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
