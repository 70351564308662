import React, { FC, ReactNode } from 'react'

import { Box } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog/Dialog'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'

interface Props extends DialogProps {
  children: ReactNode
  loading?: boolean
  confirm?: string
  cancel?: string
  importantCancel?: boolean
  onConfirm: () => void
  onClose: () => void
}

const ConfirmModal: FC<Props> = ({
  children,
  confirm = 'Yes',
  cancel = 'No',
  importantCancel = true,
  loading,
  onClose,
  onConfirm,
}) => {
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onConfirm()
  }

  return (
    <Modal
      loading={loading}
      disableBackdropClick={true}
      open
      onClose={onClose}
      sx={{ maxWidth: '34.5rem' }}
    >
      <Modal.Content sx={{ p: '0 3.25rem', justifyContent: 'center' }}>
        <Box
          sx={{
            maxWidth: '31.25rem',
            width: '100%',
            p: '3.25rem 0 0',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {children}
        </Box>
      </Modal.Content>

      <Modal.Actions>
        <Button
          onClick={onClose}
          variant={importantCancel ? 'contained' : 'outlined'}
          loading={loading}
          fullWidth
        >
          {cancel}
        </Button>
        <Button
          loading={loading}
          variant={!importantCancel ? 'contained' : 'outlined'}
          onClick={onSubmit}
          fullWidth
        >
          {confirm}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmModal
