import { FC } from 'react'

import { Box, Typography } from '@mui/material'

import { MEDIA_URL } from 'constants/urls'

import { Review, ReviewStatus } from 'services/reviews/types'

import VideoPlayer from 'components/common/VideoPlayer'

import ReviewActions from '../ReviewActions'

const ReviewContent: FC<{ review: Review }> = ({ review }) => (
  <Box
    sx={{
      display: 'flex',
      gap: '1rem',
      width: '100%',
      flexDirection: {
        xs: 'column-reverse',
        sm: 'column-reverse',
        md: 'column-reverse',
        lg: 'row',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '42.25rem',
        width: '100%',
      }}
    >
      <VideoPlayer src={`${MEDIA_URL}${review.mediaFile}`} sx={{ aspectRatio: '16/9' }} />
      <Box>
        {review.status === ReviewStatus.MODERATION && (
          <ReviewActions
            reviewId={review.id}
            productName={review.productName}
            reviewPrice={review.price}
          />
        )}
      </Box>
    </Box>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        maxWidth: { xs: '100%', sm: '100%', md: '20.75rem' },
        width: '100%',
      }}
    >
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: '700',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          lineHeight: '110%',
        }}
      >
        {review.name || 'No title'}
      </Typography>
      <Typography sx={{ wordWrap: 'break-word' }}>
        {review.description || 'No description'}
      </Typography>
    </Box>
  </Box>
)

export default ReviewContent
