import { Link } from 'react-router-dom'

import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'

import { reviewStatusStyles as status } from 'constants/reviewStatusRender'
import { MEDIA_URL } from 'constants/urls'

import formatDate from 'utils/formatToLocalTimezone'

import { AdminReview } from 'services/admin/reviews/types'

import { EllipsedText } from 'components/common/EllipsedText'

export const columns: GridColDef<AdminReview>[] = [
  {
    field: 'user',
    headerName: 'Reviewer Name',
    type: 'string',
    flex: 3,
    renderCell: ({ row }) => (
      <Link to={`/admin/reviewers/${row.user.id}`}>{row.reviewerFullName}</Link>
    ),
  },
  {
    field: 'name',
    headerName: 'Review name',
    type: 'string',
    flex: 2,
    renderCell: ({ row }) => (
      <Link to={`${MEDIA_URL}${row.mediaFile}`} target="_blank" rel="noopener noreferrer">
        {row.name}
      </Link>
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Date',
    type: 'string',
    flex: 1,
    align: 'center',
    renderCell: ({ row }) => <>{formatDate(row.createdAt, 'MM.dd.yy')}</>,
  },
  {
    field: 'status',
    headerName: 'Review Status',
    type: 'string',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => (
      <EllipsedText
        title={status[row.status as keyof typeof status].text}
        sx={{ color: status[row.status as keyof typeof status].color, fontWeight: '600' }}
      />
    ),
  },
  {
    field: 'price',
    headerName: 'Reward amount',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
]
