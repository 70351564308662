import { Link } from 'react-router-dom'

import { Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'

import formatDate from 'utils/formatToLocalTimezone'

import { AdminCoupons } from 'services/admin/coupons/types'

import CouponStatus from 'components/coupons/CouponStatus'

export const columns: GridColDef<AdminCoupons>[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    flex: 3,
    sortable: false,
  },
  {
    field: 'reviewer',
    headerName: 'Reviewer',
    type: 'string',
    flex: 2,
    sortable: false,
    renderCell: ({ row }) => (
      <Tooltip title={`${row.reviewer.firstName} ${row.reviewer.lastName}`}>
        <Link
          to={`/admin/reviewers/${row.reviewer.id}`}
        >{`${row.reviewer.firstName} ${row.reviewer.lastName}`}</Link>
      </Tooltip>
    ),
  },
  {
    field: 'shop',
    headerName: 'Shop',
    type: 'string',
    flex: 2,
    sortable: false,
    renderCell: ({ row }) => (
      <Tooltip title={row.shop.name}>
        <Link to={`/admin/shops/${row.shop.id}`}>{row.shop.name}</Link>
      </Tooltip>
    ),
  },
  {
    field: 'value', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Value, $',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Date of issue',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    flex: 1.5,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <CouponStatus status={row.status} />,
  },
]
