import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import { RolesGroups } from 'types/User'

import { userRoles } from 'constants/roles'

import ProtectedRoute from 'components/auth/ProtectedRoute'
import RoleBasedRoute from 'components/auth/RoleBasedRoute'
import MainLayout from 'components/main-layout'
//public pages
import PublicLayout from 'components/public-layout'

import { InviteCheck } from 'pages/InviteCheck'
//admins pages
import AdminProducts from 'pages/admin/products'
import ProductsByShop from 'pages/admin/products/products-by-shop'
import { Reviewers } from 'pages/admin/reviewers'
import { ReviewersCoupons } from 'pages/admin/reviewers-coupons'
// import { Product as AdminProduct } from 'pages/admin/products/product'
import AdminReviews from 'pages/admin/reviews'
import AdminReviewsByReviewer from 'pages/admin/reviews/reviews-by-reviewer'
import AdminSales from 'pages/admin/sales'
import { Sellers } from 'pages/admin/sellers'
import { SellersCoupons } from 'pages/admin/sellers-coupons'
import AdminShops from 'pages/admin/shops'
import { ShopsBySeller } from 'pages/admin/shops/shopsBySeller'
import Dashboard from 'pages/dashboard'
import Error from 'pages/error'
import CookiePolicy from 'pages/landings/cookiePolicy'
import DemoStoreLanding from 'pages/landings/demoStoreLanding'
import PrivacyPolicy from 'pages/landings/privacyPolicy'
import ReviewerLanding from 'pages/landings/reviewerLanding'
import SellerLanding from 'pages/landings/sellerLanding'
import TermsAndConditions from 'pages/landings/termsAndConditions'
import Welcome from 'pages/landings/welcome'
import NotFound from 'pages/not-found'
//seller pages
import Profile from 'pages/profile'
import Registration from 'pages/registration'
// import MyBalance from 'pages/reviewer/myBalance'
import Coupons from 'pages/reviewer/coupons'
import BuyCoupons from 'pages/reviewer/coupons/buyCoupons'
import CouponsList from 'pages/reviewer/coupons/couponsList'
//reviewer pages
import MyReviews from 'pages/reviewer/myReviews'
import CreateReview from 'pages/reviewer/myReviews/createReview'
import MyReview from 'pages/reviewer/myReviews/review'
import Purchases from 'pages/reviewer/purchases'
import Root from 'pages/root'
import Balance from 'pages/seller/balance'
import AllProducts from 'pages/seller/products'
import Product from 'pages/seller/products/product'
import Reviews from 'pages/seller/reviews'
// import ProductReviews from 'pages/seller/reviews'
import ReviewForSeller from 'pages/seller/reviews/reviewForSeller'
// import ReviewsByProduct from 'pages/seller/reviews/reviewsByProduct'
import Sales from 'pages/seller/sales'
import ShopsTabs from 'pages/seller/shops'
import Shops from 'pages/seller/shops/shops'
import ShopView from 'pages/seller/shops/shops/shop'
import Support from 'pages/support'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<Error />}>
      <Route path={'invite/:token'} element={<InviteCheck />} />
      <Route index element={<Navigate to="/dashboard" replace />} />
      <Route element={<PublicLayout />}>
        <Route path="welcome" element={<Welcome />} />
        <Route path="sellers" element={<SellerLanding />} />
        <Route path="reviewers" element={<ReviewerLanding />} />
        <Route path="demo-store" element={<DemoStoreLanding />} />
        <Route path="seller-registration" element={<Registration role={RolesGroups.SELLER} />} />
        <Route
          path="reviewer-registration"
          element={<Registration role={RolesGroups.REVIEWER} />}
        />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      </Route>

      <Route
        element={
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="support" element={<Support />} />

        <Route element={<RoleBasedRoute roles={[userRoles[RolesGroups.SELLER]]} />}>
          <Route path="reviews" element={<Navigate to="/shops/all-reviews" />} />
          <Route path="reviews/:reviewId" element={<ReviewForSeller />} />

          <Route path="shops" element={<ShopsTabs />}>
            <Route index element={<Shops />} />
            <Route path="all-products" element={<AllProducts />} />
            <Route path="sales" element={<Sales />} />
            <Route path="all-reviews" element={<Reviews />} />
          </Route>

          <Route path="shops/:shopId/products" element={<ShopView />} />
          <Route path="shops/:shopId/products/:productId" element={<Navigate to="reviews" />} />
          <Route path="shops/:shopId/products/:productId/reviews" element={<Product />} />
          <Route
            path="shops/:shopId/products/:productId/reviews/:reviewId"
            element={<ReviewForSeller />}
          />

          <Route path="products/:productId" element={<Navigate to="reviews" />} />
          <Route path="products/:productId/reviews" element={<Product />} />
          <Route path="products/:productId/reviews/:reviewId" element={<ReviewForSeller />} />

          <Route path="balance" element={<Balance />} />
        </Route>

        <Route element={<RoleBasedRoute roles={[userRoles[RolesGroups.REVIEWER]]} />}>
          <Route path="add-review/:reviewId" element={<CreateReview />} />
          <Route path="my-reviews" element={<MyReviews />} />
          <Route path="my-reviews/:reviewId" element={<MyReview />} />
          <Route path="coupons" element={<Coupons />}>
            <Route index element={<BuyCoupons />} />
            <Route path="list" element={<CouponsList />} />
          </Route>
          <Route path="purchases" element={<Purchases />} />
        </Route>

        <Route path="admin" element={<RoleBasedRoute roles={[userRoles[RolesGroups.ADMIN]]} />}>
          <Route path="sellers" element={<Sellers />} />
          <Route path="sellers/:sellerId" element={<Navigate to="coupons" />} />
          <Route path="sellers/:sellerId/coupons" element={<SellersCoupons />} />
          <Route path="sellers/:sellerId/shops" element={<ShopsBySeller />} />
          <Route path="sellers/:sellerId/shops/:shopId" element={<Navigate to="products" />} />
          <Route path="sellers/:sellerId/shops/:shopId/products" element={<ProductsByShop />} />
          <Route
            path="sellers/:sellerId/shops/:shopId/products/:productId"
            element={<AdminReviews />}
          />

          <Route path="reviewers" element={<Reviewers />} />
          <Route path="reviewers/:reviewerId" element={<Navigate to="reviews" />} />
          <Route path="reviewers/:reviewerId/reviews" element={<AdminReviewsByReviewer />} />
          <Route path="reviewers/:reviewerId/coupons" element={<ReviewersCoupons />} />

          <Route path="shops" element={<AdminShops />} />
          <Route path="shops/:shopId" element={<Navigate to="products" />} />
          <Route path="shops/:shopId/products" element={<ProductsByShop />} />
          <Route path="shops/:shopId/products/:productId" element={<AdminReviews />} />
          <Route path="products" element={<AdminProducts />} />
          <Route path="products/:productId" element={<AdminReviews />} />
          <Route path="sales" element={<AdminSales />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
)
