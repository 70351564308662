import { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import { Card, Box, Typography } from '@mui/material'

import { useGetBalanceQuery } from 'services/balance/api'
import { PaymentsResponse } from 'services/users/types'

import CardPreloader from 'components/common/CardPreloader'

import LastPaymentsChart from './LastPaymentsChart'

interface Props {
  payments: PaymentsResponse | undefined
  isLoading?: boolean
}

const Finances: FC<Props> = ({ payments, isLoading = false }) => {
  const {
    data: balance,
    isLoading: getBalanceIsLoading,
    isError: getBalanceIsError,
  } = useGetBalanceQuery(undefined, { refetchOnMountOrArgChange: true })

  const paymentCategories = payments?.paymentsByInterval.map((payment) => payment.date) || []

  const paymentPayloads =
    payments?.paymentsByInterval.map((payment) => parseFloat(payment.amount.toString())) || []

  return (
    <Card sx={{ position: 'relative', height: '100%' }}>
      {isLoading && <CardPreloader />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '0.625rem',
          p: '1rem',
          height: '100%',
        }}
      >
        <Link
          to="/balance"
          style={{ textDecoration: 'none', display: 'block', textAlign: 'center' }}
        >
          <Typography
            component="span"
            sx={{
              fontWeight: 600,
              fontSize: '0.875rem',
              width: '100%',
              textAlign: 'center',
              color: 'text.primary',
            }}
          >
            Balance{' '}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: '1rem',
              fontWeight: 700,
              width: '100%',
              textAlign: 'center',
              color: 'text.primary',
            }}
          >
            {getBalanceIsLoading ? (
              <>Loading...</>
            ) : !getBalanceIsError ? (
              `$${balance?.balance}`
            ) : (
              'Not Available Yet'
            )}
          </Typography>
        </Link>
        <Box sx={{ height: '100%', position: 'relative' }}>
          <LastPaymentsChart categories={paymentCategories} data={paymentPayloads} />
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              py: '0.625rem',
              borderBottom: '1px solid #D0D0D0',
            }}
          >
            <Typography sx={{ fontSize: '0.750rem', fontWeight: 700 }}>Payments</Typography>
            <Typography sx={{ fontSize: '0.750rem', fontWeight: 700 }}>
              ${payments?.totalAmount}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', py: '0.625rem' }}>
            <Typography sx={{ fontSize: '0.750rem', fontWeight: 700 }}>Last week</Typography>
            <Typography sx={{ fontSize: '0.750rem', fontWeight: 700 }}>
              ${payments?.amountByPeriod}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default memo(Finances)
