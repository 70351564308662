import { FC, useEffect } from 'react'

import { Box } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useActivateInviteMutation } from 'services/invites/api'
import { useGetPurchasesQuery } from 'services/reviews/api'

import EmptyState from 'components/common/EmptyState'
import PageHeader from 'components/common/PageHeader'
import Pagination from 'components/common/Pagination'
import Preloader from 'components/common/Preloader'
import { PurchaseCard } from 'components/purchases/PurchaseCard'

const Purchases: FC = () => {
  const { pagination, setPagination } = useDataGridPagination()
  const [activateInvite] = useActivateInviteMutation()
  const { data, isLoading, isFetching } = useGetPurchasesQuery(
    {
      ...pagination,
    },
    { refetchOnMountOrArgChange: true }
  )
  const invite = localStorage.getItem('inviteToken')
  useEffect(() => {
    if (invite)
      activateInvite({ token: JSON.parse(invite) }).then(() =>
        localStorage.removeItem('inviteToken')
      )
  }, [])

  return (
    <Box>
      <PageHeader title="Purchases" />
      {isLoading || isFetching ? (
        <Preloader />
      ) : (
        <Box>
          {!data?.data.length && <EmptyState text="Your purchases will appear here soon." />}
          <Box
            sx={{
              mb: '2.5rem',
              gap: '1rem',
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
              flexWrap: 'wrap',
            }}
          >
            {data?.data.map((review) => (
              <Box key={review.id} sx={{ maxWidth: '21.5rem', width: '100%' }}>
                <PurchaseCard purchase={review} />
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {!!data?.meta.totalItems && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={Math.ceil(data.meta.totalItems / data.meta.itemsPerPage) || 1}
            page={pagination.page || 1}
            onChange={setPagination}
          />
        </Box>
      )}
    </Box>
  )
}

export default Purchases
