import { baseApi } from 'api/api'
import { NewUser, RegistrationResponse } from './type'

export const registrationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    registration: build.mutation<RegistrationResponse, NewUser>({
      query: (data) => ({
        url: `/users/registration`,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const { useRegistrationMutation } = registrationApi
