import { Box } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetReviewsQuery } from 'services/reviews/api'
import { ReviewStatus } from 'services/reviews/types'

import EmptyState from 'components/common/EmptyState'
import Pagination from 'components/common/Pagination'
import Preloader from 'components/common/Preloader'
import SearchInput from 'components/common/SearchInput'
import StatusesFilter from 'components/common/StatusesFilter'
import ReviewItem from 'components/reviews/ReviewItem'

import { sellerStatusFilters } from './filters'

const Reviews = () => {
  const {
    queryParams,
    pagination,
    statusFilter,
    search,
    setPagination,
    setStatusFilter,
    setSearch,
    isFiltered,
  } = useDataGridPagination()

  const { data, isLoading, isFetching } = useGetReviewsQuery(
    { ...queryParams, sortField: 'updatedAt', sortDir: 'desc' },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  return (
    <Box sx={{ mb: '2.5rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', mb: '1.5rem' }}>
        <StatusesFilter<ReviewStatus>
          init={statusFilter}
          filterChange={setStatusFilter}
          filters={sellerStatusFilters}
        />
        <SearchInput initValue={search} onSearchChange={setSearch} />
      </Box>
      <Box sx={{ maxHeight: 'calc(100vh - 17.125rem)' }}>
        {isLoading || isFetching ? (
          <Preloader />
        ) : (
          <Box>
            {!data?.data.length ? (
              <EmptyState
                isFiltered={isFiltered}
                text="Your products haven't received any reviews yet."
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  mb: '2.5rem',
                  gap: '1rem',
                  width: '100%',
                  justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
                }}
              >
                {data.data.map((review) => (
                  <Box key={review.id} sx={{ display: 'flex', width: '100%', maxWidth: '32rem' }}>
                    <ReviewItem key={review.id} review={review} />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {!!data?.meta.totalItems && (
          <Box display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(data.meta.totalItems / data.meta.itemsPerPage) || 1}
              page={pagination.page || 1}
              onChange={setPagination}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Reviews
