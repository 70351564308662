import { FC } from 'react'

import { Theme, Box, Container, Divider, Link, Typography, useMediaQuery } from '@mui/material'
import { grey } from '@mui/material/colors'

import { ReactComponent as LinkedInIcon } from 'assets/svg/social/in.svg'

import { ExternalLink } from 'components/common/ExternalLink'
import Logo from 'components/common/Logo'

import { footerMenu, legal } from './menus'

const styles = {
  link: {
    fontSize: '0.875rem',
    fontFamily: '"Nunito Sans", sans-serif',
    '&:hover': { color: '#FFFFFF' },
    cursor: 'pointer',
  },
}

const PublicFooter: FC = () => {
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  return (
    <Box
      component="footer"
      sx={{ py: '4rem', bgcolor: '#262626', display: 'flex', color: '#FFFFFF' }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5rem',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            mb: '4rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              gap: { md: '6.25rem', sm: '3.5rem', xs: '3.5rem' },
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
              <Logo />
              <Link
                href="mailto: support@shopiview.com"
                sx={{
                  textDecoration: 'underline',
                  '&:hover': { color: '#ffffff' },
                }}
              >
                support@shopiview.com
              </Link>
              <Link
                href="#"
                sx={{
                  '&:hover': { color: '#ffffff' },
                  display: 'inline-flex',
                  justifyContent: 'start',
                  width: '100%',
                }}
              >
                <LinkedInIcon />
              </Link>
            </Box>
            <Box sx={{ display: 'flex', gap: '2rem' }}>
              {footerMenu.map((block, i) => (
                <Box key={i} sx={{ width: '8rem' }}>
                  <Typography
                    sx={{
                      fontSize: '1.125rem',
                      fontWeight: 600,
                      color: '#FFFFFF',
                      fontFamily: '"Nunito Sans", sans-serif',
                      pb: '1rem',
                    }}
                  >
                    {block.title}
                  </Typography>
                  <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                    {block.items.map((item, j) => (
                      <Box key={j} component="li" sx={{ py: '0.5rem' }}>
                        <Link
                          href={item.link}
                          sx={{
                            textDecoration: 'none',
                            color: grey[500],
                            ...styles.link,
                          }}
                        >
                          {item.text}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
            <Box>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 600,
                  color: '#FFFFFF',
                  fontFamily: '"Nunito Sans", sans-serif',
                  pb: '1rem',
                }}
              >
                {legal.title}
              </Typography>
              <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                {legal.items.map((item) => (
                  <Box key={item.link} component="li" sx={{ py: '0.5rem' }}>
                    <Link
                      href={item.link}
                      sx={{
                        color: grey[500],
                        ...styles.link,
                      }}
                    >
                      {item.text}
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ opacity: '.2' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: '1rem',
            flexWrap: 'wrap',
            gap: '1rem',
            flexDirection: isXs ? 'column' : 'row',
          }}
        >
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 400,
              fontFamily: '"Nunito Sans", sans-serif',
              order: isXs ? 3 : 0,
            }}
          >
            ShopiView © {new Date().getFullYear()}. All rights reserved.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: isXs ? 'left' : 'center',
              gap: '0.75rem',
              order: isXs ? 2 : 3,
              flexDirection: isXs ? 'column' : 'row',
            }}
          >
            <ExternalLink
              href="https://www.freepik.com/"
              sx={{ color: '#fff', textDecoration: 'none', fontSize: '0.875rem' }}
            >
              Designed by FREEPIK
            </ExternalLink>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default PublicFooter
