import { FC } from 'react'
import { Link } from 'react-router-dom'

import styles from './ReviewCard.module.scss'

import { Box, Card, CardContent, Typography } from '@mui/material'

import { reviewStatusStyles } from 'constants/reviewStatusRender'
import { MEDIA_URL } from 'constants/urls'

import formatDate from 'utils/formatToLocalTimezone'

import { ListReviewsRecord } from 'services/reviews/types'

import { ReactComponent as ProductIcon } from 'assets/svg/product.svg'

import VideoPlayer from 'components/common/VideoPlayer'

interface Props {
  review: ListReviewsRecord
}

const ReviewCardItem: FC<Props> = ({ review }) => {
  const { status, id, createdAt, price, mediaFile, updatedAt } = review

  return (
    // <Box className="hoverCard">
    <Card sx={{ width: '100%', maxWidth: '21.5rem' }} className="hoverCard">
      <CardContent
        sx={{
          p: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', minWidth: 0 }}>
            <Typography
              sx={{
                minWidth: 0,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontSize: '1.5rem',
                fontWeight: 700,
                lineHeight: '1.6rem',
              }}
            >
              {review.productName}
            </Typography>
            <Typography
              sx={{
                minWidth: 0,
                fontSize: '0.75rem',
                color: '#828282',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {review.name || 'No title'}
            </Typography>
          </Box>
          <Box>
            <Box sx={{ overflow: 'hidden', borderRadius: '0.5rem', width: '3rem', height: '3rem' }}>
              {review.productImageUrl ? (
                <img
                  src={review.productImageUrl}
                  alt={review.productName}
                  loading="lazy"
                  style={{ width: '3rem', height: '3rem', objectFit: 'cover' }}
                />
              ) : (
                <ProductIcon />
              )}
            </Box>
          </Box>
        </Box>

        {mediaFile ? (
          <VideoPlayer src={`${MEDIA_URL}${mediaFile}`} />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              alignItems: ' center',
              justifyContent: 'center',
              display: 'flex',
              aspectRatio: '16/9',
            }}
          >
            No video assigned
          </Box>
        )}

        <Link to={`/my-reviews/${id}`} style={{ textDecoration: 'none' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
            <Box className={styles.dataRow}>
              <Typography color="text.primary">Status</Typography>
              <Typography color={reviewStatusStyles[status].color} fontWeight={700}>
                {reviewStatusStyles[status].text}
              </Typography>
            </Box>
            <Box className={styles.dataRow}>
              <Typography color="text.primary">Date</Typography>
              <Typography color="text.primary" fontWeight={700}>
                {formatDate(updatedAt || createdAt, 'MM.dd.yy')}
              </Typography>
            </Box>
            <Box className={styles.dataRow}>
              <Typography color="text.primary">Bonus</Typography>
              <Typography color="text.primary" fontWeight={700}>
                ${price || review.shopDefaultReviewPrice}
              </Typography>
            </Box>
          </Box>
        </Link>
      </CardContent>
    </Card>
    // </Box>
  )
}

export default ReviewCardItem
