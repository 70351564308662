import { TableServerResponse, GetDataToTablePayload } from 'types/Table'

import { baseApi } from 'api/api'

import { Sale } from 'services/sales/types'

export const adminSalesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSalesAdmin: build.query<TableServerResponse<Sale[]>, GetDataToTablePayload>({
      query: (params) => ({
        url: `/admin/sales`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const { useGetSalesAdminQuery } = adminSalesApi
