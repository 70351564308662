import {
  FC,
  ChangeEventHandler,
  ChangeEvent,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'

import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  debounce,
} from '@mui/material'

import { ReactComponent as Search } from 'assets/svg/Search.svg'

interface Props {
  onSearchChange: Dispatch<SetStateAction<string>>
  initValue?: string
}

const SEARCH_DELAY = 300

const SearchInput: FC<Props> = ({ onSearchChange, initValue = '' }) => {
  const [searchValue, setSearchValue] = useState(initValue)

  const setDebounceSearchValue = useCallback(
    debounce((value: ChangeEvent<HTMLInputElement>) => {
      onSearchChange(value.target.value)
    }, SEARCH_DELAY),
    []
  )

  const handlerSetSearchValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value)
    setDebounceSearchValue(e)
  }

  return (
    <Box
      component="form"
      autoComplete="on"
      sx={{ width: '100%', maxWidth: '21.5rem' }}
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <FormControl fullWidth>
        <InputLabel htmlFor="shop-search" sx={{ top: '-0.187rem' }}>
          Search
        </InputLabel>
        <OutlinedInput
          name="search"
          value={searchValue}
          onChange={handlerSetSearchValue}
          sx={{ height: '3rem' }}
          endAdornment={
            <InputAdornment position="end">
              <Box
                sx={{
                  display: 'flex',
                  borderLeft: '1px solid rgba(0,0,0,.54)',
                  pl: '1rem',
                  py: '0.3rem',
                }}
              >
                <Search />
              </Box>
            </InputAdornment>
          }
          label="Search"
        />
      </FormControl>
    </Box>
  )
}

export default SearchInput
