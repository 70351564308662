import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

import { showNotification } from 'utils/showNotification'

import { useSendMailMutation } from 'services/admin/mailer/api'
import { MailTemplates as templates } from 'services/admin/mailer/types'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'
import Select from 'components/common/Select'

import { sendMailSchema } from './validation'

interface Props {
  productId: string
  customerEmail: string
  onClose: () => void
}

interface FormData {
  notificationTemplate: string
}

const SendMailToReviewerModal: FC<Props> = ({ customerEmail, productId, onClose }) => {
  const [sendMail, { isLoading: isMailSending }] = useSendMailMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(sendMailSchema),
  })

  const onSubmit = ({ notificationTemplate }: FormData) => {
    sendMail({ customerEmail, productId, notificationTemplate })
      .unwrap()
      .then(() => {
        showNotification('Email sent successfully', 'success')
      })
      .catch(() => {
        showNotification('Some error occurred', 'error')
      })
  }

  return (
    <Modal
      open
      loading={isMailSending}
      onClose={onClose}
      disableBackdropClick
      title="Send email"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ maxWidth: '34.5rem' }}
    >
      <Modal.Content>
        <Select
          control={control}
          name="notificationTemplate"
          label="Select template"
          errors={errors}
          options={templates}
        />
      </Modal.Content>
      <Modal.Actions sx={{ display: 'flex', gap: '1rem' }}>
        <Button loading={isMailSending} type="submit" fullWidth>
          Send
        </Button>
        <Button loading={isMailSending} onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default SendMailToReviewerModal
