import { number, string, object } from 'yup'

export const requiredMessage = 'This field is required'

export const buyCouponSchema = object({
  shopId: string().required(requiredMessage),
  value: number()
    .required(requiredMessage)
    .typeError('Amount must be a number')
    .min(1, `The coupon amount should not be less $1`)
    .when('$maxAmount', (maxAmount, schema) => {
      return schema.max(maxAmount[0], `The coupon amount should not exceed $${maxAmount}`)
    }),
})
