import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

import { TOO_MANY_REQUESTS_STATUS } from 'constants/requestsStatuses'

import { showNotification } from 'utils/showNotification'

import { useFeedbackMutation } from 'services/support/api'
import { Feedback } from 'services/support/types'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'
import TextInput from 'components/common/TextInput'

import { feedbackSchema } from './validation'

interface Props {
  onClose: () => void
}

const FeedbackModal: FC<Props> = ({ onClose }) => {
  const [feedback, { isLoading }] = useFeedbackMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(feedbackSchema),
  })

  const onSubmit = (newFeedback: Feedback) => {
    feedback(newFeedback)
      .unwrap()
      .then(() => {
        showNotification('Feedback successfully sent', 'success')
        onClose()
      })
      .catch((error) => {
        if (error.status === TOO_MANY_REQUESTS_STATUS) {
          showNotification('Please repeat your request in 5 mins', 'error')
        }
      })
  }

  return (
    <Modal
      open
      title="Leave a Feedback"
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      sx={{ width: '100%', maxWidth: '34.5rem' }}
    >
      <Modal.Content>
        <TextInput
          label="Subject"
          name="subject"
          control={control}
          errors={errors}
          placeholder="Subject of the Feedback"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextInput
          label="Description"
          name="description"
          control={control}
          errors={errors}
          multiline
          rows={8}
          placeholder="Enter text up to 150 characters"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button loading={isLoading} onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button type="submit" fullWidth loading={isLoading}>
          Send
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default FeedbackModal
