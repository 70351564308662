import axios from 'axios'

import { CountPayload, CountResponse } from 'types/Count'

import { MAIN_API_URL } from 'constants/urls'

import { baseApi } from 'api/api'

import KeycloakService from 'services/KeycloakService'

import { User, SystemUserInfo, PaymentsResponse } from './types'

export const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query<User, void>({
      query: () => ({
        url: `/users/me`,
        method: 'GET',
      }),
      providesTags: ['CurrentUser'],
    }),
    getUser: build.query<User, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}`,
        method: 'GET',
      }),
    }),
    getUsersCount: build.query<CountResponse, CountPayload>({
      query: (params) => ({
        url: `/users/count`,
        method: 'GET',
        params,
      }),
    }),
    sellerPayments: build.query<PaymentsResponse, CountPayload>({
      query: (params) => ({
        url: `/users/seller_payments`,
        method: 'GET',
        params,
      }),
    }),
    sellerPaymentsWithNull: build.query<PaymentsResponse, CountPayload>({
      query: (params) => ({
        url: `/users/seller_payments_with_null`,
        method: 'GET',
        params,
      }),
    }),
    getCWSystemUserInfo: build.query<SystemUserInfo, void>({
      query: () => ({
        url: `/users/system_user_info`,
        method: 'GET',
      }),
    }),
    uploadProfileImage: build.mutation<
      Promise<{ data: object | { error: object } }>,
      { file: Blob }
    >({
      queryFn: ({ file }) => {
        try {
          const res = axios.post(
            `${MAIN_API_URL}/users/upload_profile_image`,
            { file: file },
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${KeycloakService.getToken()}`,
              },
              onUploadProgress: (upload) => {
                if (!upload.total) return
                //setUploadProgress(Math.floor((PERCENTS * upload.loaded) / upload.total))
              },
            }
          )
          return { data: res }
        } catch (error) {
          return { error }
        }
      },
      invalidatesTags: ['CurrentUser'],
    }),
  }),
})

export const {
  useGetUserQuery,
  useSellerPaymentsQuery,
  useSellerPaymentsWithNullQuery,
  useGetCurrentUserQuery,
  useGetUsersCountQuery,
  useGetCWSystemUserInfoQuery,
  useUploadProfileImageMutation,
} = usersApi
