const mainMenu = [
  { text: 'Demo Store', link: `/demo-store` },
  { text: 'Features', link: `#`, scrollToComponentWithId: 'features' },
  { text: 'How it works?', link: `#`, scrollToComponentWithId: 'howItWorks' },
  { text: 'For Sellers', link: `/sellers` },
  { text: 'For Reviewers', link: `/reviewers` },
]

const legalMenu = [
  { text: 'Demo Store', link: `/demo-store` },
  { text: 'For Sellers', link: `/sellers` },
  { text: 'For Reviewers', link: `/reviewers` },
]

const sellerMenu = [
  { text: 'For Sellers', link: `/sellers`, scrollToComponentWithId: 'header' },
  { text: 'Benefits', link: `#`, scrollToComponentWithId: 'benefits' },
  {
    text: 'How it works?',
    link: `#`,
    scrollToComponentWithId: 'howItWorks',
  },
  { text: 'Pricing', link: `#`, scrollToComponentWithId: 'pricing' },
  { text: 'FAQ', link: `#`, scrollToComponentWithId: 'faq' },
]

const reviewerMenu = [
  { text: 'For Reviewers', link: `/reviewers`, scrollToComponentWithId: 'header' },
  { text: 'Benefits', link: `#`, scrollToComponentWithId: 'benefits' },
  { text: 'How it works?', link: `#`, scrollToComponentWithId: 'howItWorks' },
  { text: 'Features', link: `#`, scrollToComponentWithId: 'features' },
  { text: 'FAQ', link: `#`, scrollToComponentWithId: 'faq' },
]

const demoStoreMenu = [
  { text: 'Demo Store', link: '/demo-store', active: true },
  { text: 'For Sellers', link: `/sellers` },
  { text: 'For Reviewers', link: `/reviewers` },
]

export const menus = {
  '/welcome': mainMenu,
  '/cookie-policy': legalMenu,
  '/privacy-policy': legalMenu,
  '/terms-and-conditions': legalMenu,
  '/sellers': sellerMenu,
  '/reviewers': reviewerMenu,
  '/demo-store': demoStoreMenu,
}
