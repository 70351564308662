import { FC } from 'react'

import { Typography, Box } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'

import { couponsStatusStyle } from 'constants/couponsStatusStyle'

import formatDate from 'utils/formatToLocalTimezone'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetCouponsQuery } from 'services/coupons/api'
import { Coupon, CouponStatus } from 'services/coupons/types'

import { EllipsedText } from 'components/common/EllipsedText'
import StatusesFilter from 'components/common/StatusesFilter'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { couponsFilters } from './filters'

const SellerCouponsHistory: FC = () => {
  const { queryParams, pagination, statusFilter, setPagination, setStatusFilter, isFiltered } =
    useDataGridPagination()

  const { data, isLoading } = useGetCouponsQuery(
    { ...queryParams },
    { refetchOnMountOrArgChange: true }
  )

  const columns: GridColDef<Coupon>[] = [
    {
      field: 'name',
      headerName: 'Coupon name',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <EllipsedText title={row.name} sx={{ fontSize: '0.875rem', fontWeight: '600' }} />
      ),
    },
    {
      field: 'shopName',
      headerName: 'Shop name',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <EllipsedText title={row.shopName} sx={{ fontSize: '0.875rem', fontWeight: '600' }} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Date of issue',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Typography fontSize="0.875rem" fontWeight={600}>
          {formatDate(row.createdAt, 'MM.dd.yy')}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Typography
          fontSize="0.875rem"
          fontWeight={600}
          color={couponsStatusStyle[row.status as keyof typeof couponsStatusStyle].color.textSecond}
        >
          {couponsStatusStyle[row.status as keyof typeof couponsStatusStyle].text}
        </Typography>
      ),
    },
  ]

  return (
    <>
      <Typography sx={{ fontSize: '1.125rem', fontWeight: '600', pb: '1.5rem' }}>
        Coupons History
      </Typography>
      <Box sx={{ mb: '2.5rem' }}>
        <Box sx={{ mb: '1.5rem' }}>
          <StatusesFilter<CouponStatus>
            init={statusFilter}
            filterChange={setStatusFilter}
            filters={couponsFilters}
          />
        </Box>
        <Box sx={{ height: 'calc(100vh - 18rem)', width: '100%' }}>
          <CustomDataGrid
            data={data}
            columns={columns}
            loading={isLoading} //TODO: add loader
            paginationModel={pagination}
            setPagination={setPagination}
            rowHeight={72}
            isFiltered={isFiltered}
          />
        </Box>
      </Box>
    </>
  )
}

export default SellerCouponsHistory
