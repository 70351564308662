import { Box, Container, Typography } from '@mui/material'

import { DEMO_STORE_VIDEO_FEEDBACKS } from 'constants/guest-layout'

import priceTag from 'assets/png/demo_store_bg/img1.png'
import basket from 'assets/png/demo_store_bg/img2.png'
// import greenDote from 'assets/png/demo_store_bg/img3.png'
import greenStar from 'assets/png/demo_store_bg/img4.png'
import grayStar from 'assets/png/demo_store_bg/img5.png'
import present from 'assets/png/demo_store_bg/img6.png'
import persent from 'assets/png/demo_store_bg/img7.png'
import bag from 'assets/png/demo_store_bg/img8.png'

import LandingTitle from 'components/common/LandingTitle'

const background = {
  background: `url(${priceTag}) 2rem 1.5rem no-repeat,
  url(${basket}) left bottom no-repeat,
  url(${greenStar}) 16.75rem 38.5rem no-repeat,
  url(${grayStar}) 11.25rem 22.625rem no-repeat,
  url(${present}) 85% 6.25rem no-repeat,
  url(${persent}) 98% 11rem no-repeat,
  url(${bag}) right bottom no-repeat,
  url(${greenStar}) 63% 98% no-repeat`,
  backgroundSize: {
    lg: '10%, 10%, 2%, 3%, 10%, 4%, 10%, 2%',
    md: '10%, 10%, 2%, 3%, 10%, 4%, 10%, 2%',
    sm: '8%, 5%, 2%, 3%, 7%, 3%, 7%, 2%',
    xs: '9%, 1%, 1%, 1%, 1%, 1%, 1%, 1%',
  },
}

const DemoStoreVideoFeedbacks = () => {
  return (
    <Box sx={background}>
      <Container
        sx={{
          p: '4.625rem 0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '3rem',
        }}
      >
        <LandingTitle text="Video feedbacks" />
        <Box sx={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap', justifyContent: 'center' }}>
          {DEMO_STORE_VIDEO_FEEDBACKS.map(({ user, url }) => (
            <Box key={url}>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 700,
                  lineHeight: '1.4rem',
                  color: '#000000',
                  mb: '0.5rem',
                }}
              >
                {user}
              </Typography>
              <img width="100%" src={url} alt={user} />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default DemoStoreVideoFeedbacks
