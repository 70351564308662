import React from 'react'
import { Link } from 'react-router-dom'

import styles from './PurchaseCard.module.scss'

import Box from '@mui/material/Box/Box'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import Typography from '@mui/material/Typography/Typography'

import formatDate from 'utils/formatToLocalTimezone'

import { Purchase } from 'services/reviews/types'

import { ReactComponent as ImageIcon } from 'assets/svg/image-icon.svg'

import Button from 'components/common/Button'
import { EllipsedText } from 'components/common/EllipsedText'
import { ExternalLink } from 'components/common/ExternalLink'

interface Props {
  purchase: Purchase
}
export const PurchaseCard = ({ purchase }: Props) => {
  return (
    <Card className={styles.reviewItem} sx={{ maxWidth: '21.5rem', width: '100%' }}>
      <CardContent
        sx={{
          p: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem',
          width: '100%',
        }}
      >
        <EllipsedText
          title={purchase.productName}
          sx={{ fontSize: '1.5rem', fontWeight: 700, lineHeight: '1.6rem', width: '100%' }}
        />
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Box sx={{ display: 'flex', width: '100%', gap: '0.25rem' }}>
            <Typography sx={{ color: 'text.primary' }}>Seller: </Typography>
            <EllipsedText
              title={`${purchase.sellerFirstName} ${purchase.sellerLastName}`}
              sx={{ color: 'text.primary', fontWeight: 700 }}
            />
          </Box>
          <Box className={styles.dataRow}>
            <Typography
              component="span"
              sx={{
                color: 'text.primary',
                width: '100%',
                textWrap: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Product link:{' '}
              <Typography
                component="span"
                sx={{
                  color: 'text.primary',
                  fontWeight: 700,
                }}
              >
                <ExternalLink href={purchase.productLink}>Click here</ExternalLink>
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box className={styles.imagePreview}>
          {purchase.productImageUrl ? (
            <img
              src={purchase.productImageUrl}
              alt={purchase.productName}
              loading="lazy"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <ImageIcon fill="#fff" />
          )}
        </Box>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Box className={styles.dataRow}>
            <Typography sx={{ color: 'text.primary' }}>Date of purchase:</Typography>
            <Typography fontWeight={700}>{formatDate(purchase.createdAt, 'MM.dd.yy')}</Typography>
          </Box>
          <Box className={styles.dataRow}>
            <Typography sx={{ color: 'text.primary' }}>Bonus:</Typography>
            <Typography sx={{ fontWeight: 700 }}>${purchase.price}</Typography>
          </Box>
        </Box>
        <Link to={`/add-review/${purchase.id}`} style={{ textDecoration: 'none', width: '100%' }}>
          <Button sx={{ width: '100%' }}>Leave feedback</Button>
        </Link>
      </CardContent>
    </Card>
  )
}
