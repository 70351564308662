import React, { FC } from 'react'

import { Box, Link, SxProps, Typography } from '@mui/material'

import { ReactComponent as ArrowBack } from 'assets/svg/arrow_back.svg'

export interface Props {
  title: string | React.ReactNode
  backPath?: string
  rightContent?: React.ReactNode
  wrapperSx?: SxProps
}

const PageHeader: FC<Props> = ({ title, backPath, rightContent, wrapperSx }) => {
  return (
    <Box
      sx={{
        pb: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...wrapperSx,
      }}
    >
      <Box sx={{ minWidth: '0' }}>
        {backPath ? (
          <Link
            href={backPath}
            underline="none"
            sx={{ display: 'flex', alignItems: 'center', '&:hover': { color: '#1e1d1d' } }}
          >
            <ArrowBack style={{ minWidth: '3rem', width: '3rem' }} />
            <Box
              sx={{
                color: '#000000',
                ml: '2rem',
                minWidth: 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  lineHeight: '1.75rem',
                  fontWeight: '600',
                  color: '#000000',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {title}
              </Typography>
            </Box>
          </Link>
        ) : (
          <Box
            sx={{
              fontSize: '1.5rem',
              lineHeight: '1.75rem',
              fontWeight: '600',
              color: '#000000',
              minWidth: 0,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.5rem',
                lineHeight: '1.75rem',
                fontWeight: '600',
                color: '#000000',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {title}
            </Typography>
          </Box>
        )}
      </Box>
      <Box>{rightContent}</Box>
    </Box>
  )
}

export default PageHeader
