import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import NavigationTabs, { TabItem } from 'components/common/NavigationTabs'
import PageHeader from 'components/common/PageHeader'

const Coupons: FC = () => {
  const tabs: TabItem[] = [
    { id: '', label: 'Buy Coupons' },
    { id: 'list', label: 'My Coupons' },
  ]

  return (
    <>
      <PageHeader title="Coupons" />
      <Typography
        sx={{
          mb: '2.5rem',
          maxWidth: '48.375rem',
          fontWeight: '400',
          letterSpacing: '0.15px',
          lineHeight: '1.5rem',
        }}
      >
        In order to use the coupons received from the seller, you need to click on the “Buy a
        coupon” button and then select the store where you plan to spend it
      </Typography>
      <NavigationTabs basePath={`/coupons`} tabs={tabs} />
      <Box sx={{mt: '2.5rem'}}>
        <Outlet />
      </Box>
    </>
  )
}

export default Coupons
