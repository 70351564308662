export const MailTemplates = [
  {
    label: 'New',
    value: 'status_new',
  },
  {
    label: '1 Week',
    value: 'status_one_week',
  },
  {
    label: '2 Weeks',
    value: 'status_two_weeks',
  },
  {
    label: 'Reg only',
    value: 'status_reg_only',
  },
  {
    label: 'Old',
    value: 'status_old',
  },
]

export interface SendMailPayload {
  productId: string
  customerEmail: string
  notificationTemplate: string
}
