export const features = [
  'Integration with Shopify store',
  'Money emission for video reviews',
  'Customer support',
  'Mail system (sends invites to customers who purchased products)',
  'Track coupon history',
  'Statistics on users, reviews, and popular products',
]

export const landingPricesText = [
  {
    level: 'Standard Plan (monthly)',
    sum: '10',
    sale: false,
    salePrice: '',
    subscriptionType: 1,
    features: features,
  },
  {
    level: 'Standard Plan (annually)',
    sum: '120',
    sale: true,
    saleSum: { whole: 100, decimal: '00' },
    subscriptionType: 3,
    features: features,
  },
]

export const allPrices = [
  {
    level: 'Free Unlimited',
    sum: '0',
    sale: false,
    subscriptionType: 0,
    features: ['All possible features are free for you.'],
  },
  ...landingPricesText,
]
