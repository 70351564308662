import { FC } from 'react'

import { Box, Typography, Divider } from '@mui/material'
import { grey } from '@mui/material/colors'

import { landingPricesText } from 'constants/prices'

import { ReactComponent as CheckIcon } from 'assets/svg/land_pricing_check.svg'

import LandingTitle from 'components/common/LandingTitle'

const SellerPlansPricing: FC = () => {
  return (
    <Box
      id="pricing"
      sx={{ width: '100%', pt: '5rem', display: 'flex', flexDirection: 'column', gap: '3rem' }}
    >
      <LandingTitle text="Pricing" />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1.5rem', justifyContent: 'center' }}>
        {landingPricesText.map((item) => (
          <Box
            key={item.level}
            sx={{
              maxWidth: '23rem',
              width: '100%',
              bgcolor: '#F7F5F7',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              p: '1.5rem',
              borderRadius: '1.25rem',
              gap: '1.5rem',
            }}
          >
            <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{item.level}</Typography>
            <Box
              sx={{
                position: 'relative',
                height: '5rem',
                display: 'flex',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', gap: '0.125rem' }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    lineHeight: 1,
                    alignSelf: 'end',
                  }}
                >
                  $
                </Typography>
                <Typography sx={{ fontSize: '2rem', fontWeight: 700, lineHeight: '80%' }}>
                  {item.sum}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: 700,
                    alignSelf: 'start',
                    lineHeight: '100%',
                    marginTop: '-0.5rem',
                  }}
                >
                  00
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1.5rem',
                    fontWeigh: 300,
                    alignSelf: 'end',
                    lineHeight: '100%',
                    color: grey[600],
                  }}
                >
                  /
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 300,
                    alignSelf: 'end',
                    color: grey[600],
                    ml: '0.375rem',
                  }}
                >
                  per store
                </Typography>
              </Box>
              {item.sale && (
                <>
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '6rem',
                      height: '2px',
                      backgroundColor: '#EA1616',
                      borderRadius: '2px',
                      transform: 'rotate(-24deg)',
                      bottom: '0.95rem',
                      left: '-0.5rem',
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      display: 'flex',
                      gap: '0.125rem',
                      bgcolor: '#EA1616',
                      borderRadius: '0.25rem',
                      padding: '0.25rem 0.125rem',
                      bottom: '2.5rem',
                      left: '5.125rem',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        lineHeight: 1,
                        display: 'flex',
                        alignItems: 'end',
                        color: '#FFFFFF',
                      }}
                    >
                      $
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '2rem',
                        fontWeight: 700,
                        lineHeight: '80%',
                        color: '#FFFFFF',
                      }}
                    >
                      {item.saleSum?.whole}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                        fontWeight: 400,
                        alignSelf: 'start',
                        lineHeight: '100%',
                        color: '#FFFFFF',
                      }}
                    >
                      {item.saleSum?.decimal}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            <Typography
              sx={{
                fontSize: '0.725rem',
                fontWeight: 400,
                textAlign: 'left',
              }}
            >
              Includes a 2-week free trial period for new users
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundImage:
                  'repeating-linear-gradient(90deg, #d0d0d0, #d0d0d0 10px, transparent 10px, transparent 24px)',
                backgroundPosition: 'left top',
                backgroundRepeat: 'repeat-x',
                backgroundSize: '100% 2px',
                borderStyle: 'none',
                height: '2px',
              }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: 600 }}>Plan Features:</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                {item.features.map((feature) => (
                  <Box
                    key={feature}
                    sx={{ display: 'flex', alignItems: 'start', gap: '0.5rem', py: '0.125rem' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pt: '0.125rem',
                      }}
                    >
                      <CheckIcon />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        lineHeight: '1.225rem',
                      }}
                    >
                      {feature}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default SellerPlansPricing
