import { CountPayload, CountResponse } from 'types/Count'
import { TableServerResponse } from 'types/Table'

import { baseApi } from 'api/api'

import { IPopularProduct } from 'services/dashboard/types'

import { GetProductsPayload, NewProduct, Product, ListProductsRecord } from './types'

export const productsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createProducts: build.mutation<Product, NewProduct>({
      query: (data) => ({
        url: '/products',
        method: 'POST',
        data: data,
        fromData: true,
      }),
      invalidatesTags: [{ type: 'Products', id: 'LIST' }],
    }),
    getProducts: build.query<TableServerResponse<ListProductsRecord[]>, GetProductsPayload>({
      query: (params) => ({
        url: `/products`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'Products', id: 'LIST' }],
    }),
    getPopularProducts: build.query<IPopularProduct[], void>({
      query: () => ({
        url: `/products/popular`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Products', id: 'LIST' }],
    }),

    getProduct: build.query<Product, string>({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'GET',
      }),
    }),
    deleteProduct: build.mutation({
      query: (id) => ({
        url: `products/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Products', id: 'LIST' }],
    }),
    getProductsCount: build.query<CountResponse, CountPayload>({
      query: (params) => ({
        url: `/product/count`,
        method: 'GET',
        params,
      }),
    }),
    setNotificationStatus: build.mutation<
      {
        ок: boolean
      },
      { use_notification: boolean; id: string }
    >({
      query: ({ id, ...data }) => ({
        url: `/products/${id}/toggle_product_notification`,
        method: 'POST',
        data,
        fromData: true,
      }),
      invalidatesTags: () => [{ type: 'Products' }],
    }),
  }),
})

export const {
  useGetProductsQuery,
  useGetPopularProductsQuery,
  useGetProductQuery,
  useCreateProductsMutation,
  useSetNotificationStatusMutation,
  useDeleteProductMutation,
  useGetProductsCountQuery,
} = productsApi
