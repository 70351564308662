import { number, object } from 'yup'

const MAX_BONUS = 50

export const editBonusSchema = object({
  bonus: number()
    .required('This field is required')
    .typeError('Bonus amount must be a number')
    .max(MAX_BONUS, `The bonus amount must not be more than $${MAX_BONUS}`)
    .when('$defaultBonus', (defaultBonus, schema) => {
      return schema.min(defaultBonus[0], `The bonus amount must not be less than $${defaultBonus}`)
    }),
})
