import { Link } from 'react-router-dom'

import { Tooltip, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'

import { AdminProduct } from 'services/admin/products/types'

export const columns: GridColDef<AdminProduct>[] = [
  {
    field: 'name',
    headerName: 'Product name',
    type: 'string',
    flex: 2,
    sortable: false,
    renderCell: ({ row }) => (
      <Tooltip title={row.name}>
        <Link to={`${row.id}`}>{row.name}</Link>
      </Tooltip>
    ),
  },
  {
    field: 'link',
    headerName: 'Product Link',
    type: 'string',
    flex: 2,
    sortable: false,
    renderCell: ({ row }) => (
      <Tooltip title={row.link}>
        <Typography>{row.link}</Typography>
      </Tooltip>
    ),
  },
  {
    field: 'defaultReviewPrice',
    headerName: 'Bonus, $',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'approvedReviewsCount',
    headerName: 'Approved',
    type: 'number',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'moderationReviewsCount',
    headerName: 'On Moderation',
    type: 'number',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'rejectedReviewsCount',
    headerName: 'Rejected',
    type: 'number',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'totalPayments',
    headerName: 'Payments',
    type: 'number',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
]
