import { FC, useState } from 'react'
import Cropper from 'react-easy-crop'

import { Box, SxProps } from '@mui/material'

import { cropImage } from 'utils/cropImage'

import Button from '../Button'
import Modal from '../Modal'

interface Props {
  open: boolean
  image: string | undefined
  onComplete: (imagePromise: Promise<string>) => void
  loading?: boolean
  sx?: SxProps
  onClose: () => void
}

const ImageCropper: FC<Props> = ({
  open,
  image,
  onComplete,
  sx,
  onClose,
  loading = false,
  ...props
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    width: number
    height: number
    x: number
    y: number
  } | null>(null)

  return (
    <Modal open={open} onClose={onClose} loading={loading}>
      <Modal.Content>
        <Box sx={sx}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels)
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button
          onClick={() =>
            image && onComplete(cropImage(image, croppedAreaPixels, (cropped) => cropped))
          }
          type="submit"
          variant="contained"
          fullWidth
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ImageCropper
