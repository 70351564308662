import { FC } from 'react'

import { Box, Container, Typography } from '@mui/material'

import LegalLendings from 'components/public-layout/LegalLendings'

import {
  POLICIES_TITLE_STYLES,
  POLICIES_TEXT_STYLES,
  POLICIES_LIST_STYLES,
  POLICIES_LIST_ITEM_STYLES,
  POLICIES_LIST_DOTE_STYLES,
} from '../cookiePolicy'

const PrivacyPolicy: FC = () => {
  return (
    <LegalLendings title="Shopiview Privacy Policy" date="Oct 29, 2024">
      <Container
        sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginBottom: '6.25rem' }}
      >
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            1. Introduction
          </Typography>
          <Typography component="p" sx={POLICIES_TEXT_STYLES}>
            Welcome to Shopiview. This Privacy Policy describes how We Can Develop IT LLC
            (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) collects, uses, and shares your
            personal information when you use our platform (&quot;Platform&quot;).
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            2. Information We Collect
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Personal Information: When you create an account, we collect information such as
                your name, email address, and payment details.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Usage Data: We collect information about your interactions with the Platform,
                including the pages you visit and the actions you take.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Cookies and Tracking Technologies: We use cookies and similar tracking technologies
                to collect information about your use of the Platform and to enhance your user
                experience.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            3. How We Use Your Information
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                To Provide and Improve Our Services: We use your information to operate, maintain,
                and improve the Platform.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                To Process Payments: We use your payment information to process transactions and
                compensate you for reviews.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                To Communicate with You: We use your contact information to send you updates,
                promotional materials, and other information related to your use of the Platform.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                To Ensure Security and Compliance: We use your information to detect and prevent
                fraud, abuse, and violations of our Terms and Conditions.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            4. How We Share Your Information
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                With Service Providers: We may share your information with third-party service
                providers who help us operate the Platform and provide our services.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                With Business Partners: We may share your information with business partners for
                marketing and promotional purposes, but only with your consent.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                For Legal Reasons: We may disclose your information if required by law or in
                response to a legal request, such as a subpoena, court order, or government demand.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            5. Data Security
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Security Measures: We implement appropriate security measures to protect your
                personal information from unauthorized access, alteration, disclosure, or
                destruction.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Data Breach Response: In the event of a data breach, we will notify you and the
                appropriate authorities as required by law.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            6. Your Rights
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Access and Correction: You have the right to access and correct your personal
                information. You can update your account information through the Platform.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Deletion: You have the right to request the deletion of your personal information,
                subject to certain legal exceptions.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Opt-Out: You can opt-out of receiving promotional emails from us by following the
                unsubscribe instructions in those emails.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            7. Privacy
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Shopiview collects and uses your personal information in accordance with its Privacy
                Policy.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                By using the Platform, you consent to the collection and use of your information as
                described in the Privacy Policy.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </LegalLendings>
  )
}

export default PrivacyPolicy
