import axios from 'axios'

import { CountPayload, CountResponse } from 'types/Count'
import { TableServerResponse } from 'types/Table'

import { CLEVER_API_URL } from 'constants/urls'

import { baseApi } from 'api/api'

import KeycloakService from 'services/KeycloakService'

import { GetCouponsListPayload, NewCoupon, Coupon, NewInvoice, CreateInvoiceResp } from './types'

export const couponsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCoupons: build.query<TableServerResponse<Coupon[]>, GetCouponsListPayload>({
      query: (data) => ({
        url: '/coupons',
        method: 'GET',
        params: data,
      }),
      providesTags: () => [{ type: 'Coupons', id: 'LIST' }],
    }),
    getCouponsCount: build.query<CountResponse, CountPayload & { isReviewer: 0 | 1 }>({
      query: (params) => ({
        url: `/coupons/count`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'Coupons', id: 'COUNT' }],
    }),
    createCoupon: build.mutation<Coupon, NewCoupon>({
      query: (data) => ({
        url: '/coupons/create',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: [
        { type: 'Coupons', id: 'LIST' },
        { type: 'Coupons', id: 'COUNT' },
      ],
    }),
    getCouponById: build.query<Coupon, string>({
      query: (id) => ({
        url: `/coupons/${id}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Coupons', id: 'LIST' }],
    }),
    createInvoice: build.mutation<CreateInvoiceResp, NewInvoice>({
      queryFn: async (data) => {
        try {
          const response = await axios.post(`${CLEVER_API_URL}/invoice/token`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${KeycloakService.getToken()}`,
            },
          })
          return response
        } catch (error) {
          return { error }
        }
      },
    }),
  }),
})

export const {
  useGetCouponsQuery,
  useGetCouponByIdQuery,
  useCreateCouponMutation,
  useGetCouponsCountQuery,
  useCreateInvoiceMutation,
} = couponsApi
