import {
  Controller,
  FieldErrors,
  FieldPathValue,
  FieldValues,
  Path,
  UseControllerProps,
} from 'react-hook-form'

import { StandardTextFieldProps, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

export interface SelectOption {
  value: string | number
  label: string
}

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName>,
    StandardTextFieldProps {
  name: TName
  defaultValue?: FieldPathValue<TFieldValues, TName>
  label: string
  errors: FieldErrors<FieldValues>
  options: SelectOption[] | undefined
  disabled?: boolean
}

const Select = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
  control,
  errors,
  name,
  label,
  options = [],
  disabled,
  size = 'medium',
  ...rest
}: Props<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, name, value, ref } }) => (
        <TextField
          onChange={onChange}
          value={value || ''}
          name={name}
          inputRef={ref}
          label={label}
          fullWidth
          error={!!errors[name]}
          helperText={errors[name]?.message as string}
          size={size}
          select
          disabled={disabled}
          {...rest}
        >
          {options.map((o, index) => (
            <MenuItem key={index} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}

export default Select
