import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type RequestStatusType = 'idle' | 'loading' | 'succeeded' | 'failed'

export interface AppError {
  title: string
  message: string
}

interface InitialState {
  status: RequestStatusType
  error: AppError | null
  notification: string | null
}

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    notification: null,
    status: 'idle',
  } as InitialState,
  reducers: {
    setAppStatus(state, action: PayloadAction<RequestStatusType>) {
      state.status = action.payload
    },
    setAppError(state, action: PayloadAction<null | AppError>) {
      state.error = action.payload
    },
    setAppNotification(state, action: PayloadAction<null | string>) {
      state.notification = action.payload
    },
  },
})

export const appReducer = slice.reducer
export const { setAppStatus, setAppError, setAppNotification } = slice.actions
