import { FC } from 'react'

import { Button as MuiButton, ButtonProps } from '@mui/material'

interface Props extends ButtonProps {
  loading?: boolean
}

const Button: FC<Props> = ({
  loading,
  type = 'button',
  children,
  variant = 'contained',
  disabled,
  ...rest
}) => {
  return (
    <MuiButton
      disabled={disabled || loading}
      type={type}
      variant={variant}
      disableRipple={variant === 'text'}
      {...rest}
      sx={{ whiteSpace: 'nowrap', ...rest.sx }}
    >
      {children}
    </MuiButton>
  )
}

export default Button
