import { GetDataToTablePayload } from 'types/Table'

export enum CouponStatus {
  INACTIVE = 1,
  INPROGRESS = 2,
  ACTIVE = 3,
  USED = 4,
  FAILED = 5,
}

export interface Coupon {
  id: string
  name: string
  value: number
  status: CouponStatus
  statusLabel: string
  createdAt: string
  shopId: string
  shopName: string
  sellerId: string
  sellerFirstName: string
  sellerLastName: string
}

export interface NewCoupon {
  shopId: string
  value: number
}

export interface CreateInvoiceResp {
  token: string
}

export interface NewInvoice {
  amount: number
  currency: string
  seller: string
  redirectUrl: string
  operationType: string
  metadata: {
    shopId: string
    shopName: string
    reviewerId: string
    sellerName: string
  }
}

export interface GetCouponsListPayload extends GetDataToTablePayload {
  isReviewer?: 0 | 1
}
