import { FC } from 'react'

import { Box, Backdrop, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

interface Props {
  value: number
  loader: boolean
  loaderUpload: boolean
}

const PercentageCircularProgress: FC<Props> = ({ value, loader, loaderUpload }) => {
  if (!loaderUpload) return null

  return (
    <Backdrop
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '9999',
        backgroundColor: 'rgba(255, 255, 255, .6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={loaderUpload}
    >
      <Box sx={{ position: 'absolute', display: 'inline-flex' }}>
        <CircularProgress variant={loader ? 'indeterminate' : 'determinate'} value={value} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.primary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    </Backdrop>
  )
}

export default PercentageCircularProgress
