import { FC, ReactNode } from 'react'

import { Box, Typography } from '@mui/material'

interface Props {
  title: string
  text: string
  icon: ReactNode
}

const DemoStoreFeature: FC<Props> = ({ title, text, icon }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '22.66rem',
      }}
    >
      {icon}
      <Box sx={{ mt: '-57px' }}>
        <Typography
          sx={{
            fontSize: '1.5rem',
            fontWeight: 600,
            lineHeight: '2rem',
            textAlign: 'center',
            mb: '0.5rem',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: '0.875rem', fontWeight: 400, lineHeight: '1rem', textAlign: 'center' }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default DemoStoreFeature
