import { TableServerResponse } from 'types/Table'

import { baseApi } from 'api/api'

import { AdminShop, GetShopsDataToTablePayload } from './types'

export const adminShopsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getShopsAdmin: build.query<TableServerResponse<AdminShop[]>, GetShopsDataToTablePayload>({
      query: (params) => ({
        url: `/admin/shops`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const { useGetShopsAdminQuery } = adminShopsApi
