import { FC, ReactNode } from 'react'

import { Box, Typography } from '@mui/material'

import firstPageBg from 'assets/png/legalHeaderBg.png'

interface Props {
  title: string
  date: string
  children: ReactNode
}

const LegalLendings: FC<Props> = ({ children, title, date }) => {
  return (
    <Box>
      <Box
        sx={{
          height: '26.5rem',
          background: `linear-gradient(177.43deg, rgba(255, 255, 255, 0.04) 21.15%, rgba(255, 255, 255, 0.36) 54.79%, #FFFFFF 85.69%), url(${firstPageBg}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Typography
            component="span"
            sx={{ fontSize: '2rem', fontWeight: 700, color: '#FFFFFF', lineHeight: '2.375rem' }}
          >
            {title}
          </Typography>
          <Typography
            component="span"
            sx={{ fontSize: '0.875rem', color: '#FFFFFF', lineHeight: '1.225rem' }}
          >
            Last Updated: {date}
          </Typography>
        </Box>
      </Box>
      <Box>{children}</Box>
    </Box>
  )
}

export default LegalLendings
