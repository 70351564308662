import { FC, ReactNode, useState, useEffect } from 'react'
import ImageUploading, { ImageListType } from 'react-images-uploading'

import { IconButton, SxProps } from '@mui/material'

import { base64ToBlob } from 'utils/cropImage'

import ImageCropper from './ImageCropper'

interface Props {
  children: ReactNode
  setCropImage: (image: Blob) => void
  loading?: boolean
  sx?: SxProps
}

const ImageUploader: FC<Props> = ({ children, setCropImage, loading = false, sx }) => {
  const [image, setImage] = useState<ImageListType>([])
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    if (!loading) {
      setDialogOpen(false)
    }
  }, [loading])

  return (
    <>
      <ImageUploading
        value={image}
        onChange={(newImage) => {
          setDialogOpen(true)
          setImage(newImage)
        }}
      >
        {({ onImageUpload, onImageUpdate }) => (
          <IconButton sx={sx} onClick={image.length > 0 ? onImageUpload : () => onImageUpdate(0)}>
            {children}
          </IconButton>
        )}
      </ImageUploading>
      <ImageCropper
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
        loading={loading}
        image={image.length > 0 ? image[0].dataURL : undefined}
        onComplete={(imagePromise) => {
          imagePromise.then(async (image) => {
            const file = await base64ToBlob(image)
            setCropImage(file)
          })
        }}
        sx={{
          position: 'relative',
          width: '100%',
          height: '18.75rem',
          background: '#333',
          mt: '3.25rem',
        }}
      />
    </>
  )
}

export default ImageUploader
