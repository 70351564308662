import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'

import EastIcon from '@mui/icons-material/East'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Box, Card, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { IPopularProduct } from 'services/dashboard/types'

import { ReactComponent as ProductImage } from 'assets/svg/product.svg'

import CardPreloader from 'components/common/CardPreloader'
import { EllipsedText } from 'components/common/EllipsedText'

interface Props {
  products: IPopularProduct[]
  isLoading?: boolean
}

const PopularProducts: FC<Props> = ({ products, isLoading = false }) => {
  const navigate = useNavigate()

  const openProductPage = (id: string) => {
    navigate(`/products/${id}`)
  }
  return (
    <Card sx={{ height: '100%', position: 'relative' }}>
      {isLoading && <CardPreloader />}
      <Box
        sx={{ display: 'flex', gap: '1rem', flexDirection: 'column', p: '1.25rem', width: '100%' }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.5rem' }}>
          Popular Products
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.375rem', width: '100%' }}>
          {products.length ? (
            products.map((product) => (
              <Box
                key={product.id}
                //onClick={() => openProductPage(product.id)}
                sx={{
                  padding: '0.375rem 0.625rem',
                  border: `1px dashed ${grey[200]}`,
                  borderRadius: 1.5,
                  cursor: 'pointer',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  '&:hover': {
                    backgroundColor: grey[100],
                  },
                }}
              >
                <Box sx={{ display: 'flex', minWidth: 0 }}>
                  <Box sx={{ width: '3rem', height: '3rem' }}>
                    {product.image_url ? (
                      <Box
                        sx={{
                          width: '3rem',
                          height: '3rem',
                          borderRadius: '0.5rem',
                          overflow: 'hidden',
                        }}
                      >
                        <img
                          src={product.image_url}
                          alt={product.name}
                          loading="lazy"
                          style={{ width: '3rem', height: '3rem', objectFit: 'cover' }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          borderRadius: '0.5rem',
                          overflow: 'hidden',
                          minWidth: '3rem',
                          width: '3rem',
                          height: '3rem',
                        }}
                      >
                        <ProductImage style={{ width: '3rem', height: '3rem' }} />
                      </Box>
                    )}
                  </Box>

                  <Box sx={{ pl: '0.5rem', minWidth: '0' }}>
                    <EllipsedText title={product.name} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ShoppingCartIcon sx={{ fontSize: '0.875rem', color: grey[900] }} />
                      <Typography sx={{ pl: '0.375rem', color: grey[700], fontSize: '0.875rem' }}>
                        {product.products_count}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignSelf: 'center',
                    backgroundColor: grey[800],
                    padding: '0.5rem',
                    borderRadius: '0.375rem',
                    display: 'flex',
                    color: grey[100],
                  }}
                  onClick={() => openProductPage(product.id)}
                >
                  <EastIcon sx={{ fontSize: '1rem' }} />
                </Box>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                py: '5rem',
              }}
            >
              There is no popular products yet.
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  )
}

export default memo(PopularProducts)
