import { skipToken } from '@reduxjs/toolkit/query'
import { FC } from 'react'
import { useParams, Link } from 'react-router-dom'

import { Box, Skeleton } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetAdminProductsQuery } from 'services/admin/products/api'
import { useGetShopQuery } from 'services/shops/api'

import PageHeader from 'components/common/PageHeader'
import SearchInput from 'components/common/SearchInput'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'

const ProductsByShop: FC = () => {
  const { shopId, sellerId } = useParams()
  const { data: shop, isLoading: isShopLoading } = useGetShopQuery(shopId ?? skipToken)

  const { queryParams, pagination, sort, search, setPagination, setSort, setSearch, isFiltered } =
    useDataGridPagination()

  const { data, isLoading, isFetching } = useGetAdminProductsQuery(
    shopId ? { shopId, ...queryParams } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  )

  return (
    <>
      <PageHeader
        title={isShopLoading ? <Skeleton width="12rem" height="3.3rem" /> : shop?.name}
        backPath={sellerId ? `/admin/sellers/${sellerId}/shops` : '/admin/shops'}
        rightContent={
          isShopLoading ? (
            <Skeleton width="10rem" />
          ) : (
            shop && (
              <Link to={shop.url} target="_blank">
                {shop.url}
              </Link>
            )
          )
        }
        wrapperSx={{ justifyContent: 'flex-start', gap: '1rem' }}
      />
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mb: '1.5rem' }}>
          <SearchInput initValue={search} onSearchChange={setSearch} />
        </Box>
        <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
          <CustomDataGrid
            data={data}
            columns={columns}
            loading={isLoading || isFetching}
            paginationModel={pagination}
            setPagination={setPagination}
            setSort={setSort}
            initSort={sort}
            isFiltered={isFiltered}
            rowHeight={72}
          />
        </Box>
      </Box>
    </>
  )
}

export default ProductsByShop
