export const couponsStatusStyle = {
  1: {
    text: 'Inactive',
    color: {
      main: '#AAAAAA',
      text: '#FFFFFF',
      textSecond: '#D0D0D0',
    },
  },
  2: {
    text: 'In Progress',
    color: {
      main: '#488437',
      text: '#FFFFFF',
      textSecond: '#D0D0D0',
    },
  },
  3: {
    text: 'Active',
    color: {
      main: '#DCFFDD',
      text: '#09C132',
      textSecond: '#09C132',
    },
  },
  4: {
    text: 'Used',
    color: {
      main: 'rgba(234, 22, 22, 0.2)',
      text: '#EA1616',
      textSecond: '#EA1616',
    },
  },
  5: {
    text: 'Failed',
    color: {
      main: 'rgba(234, 22, 22, 1)',
      text: '#000000',
      textSecond: '#000000',
    },
  },
} as const
