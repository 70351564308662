import { useNavigate } from 'react-router-dom'

import { useMediaQuery, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import { HOW_IT_WORKS_WELCOME, LANDING_TEXT } from 'constants/guest-layout'

import ColorBox from 'components/common/ColorBox'
import LandingTitle from 'components/common/LandingTitle'
import { HowItWorks } from 'components/public-layout/HowItWorks'
import LandingCarousel from 'components/public-layout/LandingCarousel'

const Welcome = () => {
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const navigate = useNavigate()
  return (
    <>
      <LandingCarousel
        title={LANDING_TEXT.forWelcomePage.title}
        text={LANDING_TEXT.forWelcomePage.text}
        carouselItems={LANDING_TEXT.forWelcomePage.carouselItems}
        buttonText="View demo store"
        buttonAction={() => {
          navigate('/demo-store')
        }}
      />

      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5rem',
          alignItems: 'center',
          pb: '5rem',
        }}
      >
        <Box
          id="features"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            alignItems: 'center',

            pt: '5rem',
          }}
        >
          <Box maxWidth="768px">
            <LandingTitle text="Key Features" />
            <Typography
              sx={{ fontSize: '1.125rem', fontWeight: 400, textAlign: 'center', mt: '2rem' }}
            >
              At ShopiView, we empower businesses to gather authentic video reviews from their
              customers effortlessly. By leveraging the power of video feedback, businesses can
              enhance their credibility, increase sales, and foster stronger connections with their
              audience.
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap' }}>
              <ColorBox
                sx={{
                  bgcolor: '#262626',
                  color: '#FFFFFF',
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  minWidth: '20rem',
                }}
              >
                Easily request video reviews from customers
              </ColorBox>
              <ColorBox sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' }, minWidth: '20rem' }}>
                Seamless integration with Shopify stores
              </ColorBox>
              <ColorBox sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' }, minWidth: '20rem' }}>
                Comprehensive analytics dashboard
              </ColorBox>
              <ColorBox
                sx={{
                  flex: '1 0 48%',
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  minWidth: '20rem',
                }}
              >
                Engage with reviewers and respond to feedback
              </ColorBox>
              <ColorBox
                sx={{
                  flex: '1 0 48%',
                  bgcolor: '#CBFA92',
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  minWidth: '20rem',
                }}
              >
                Reward reviewers with discounts for future purchases
              </ColorBox>
            </Box>
          </Box>
        </Box>

        <Box
          id="howItWorks"
          sx={{
            width: '100%',
            pt: '5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            alignItems: 'center',
          }}
        >
          <LandingTitle text="How It Works?" />

          <HowItWorks
            items={HOW_IT_WORKS_WELCOME}
            direction={isLg ? 'row' : 'column'}
            textMaxWidth={172}
          />
        </Box>

        <ColorBox
          sx={{
            p: '3rem',
            flexDirection: 'column',
            color: '#FFFFFF',
            bgcolor: '#262626',
            width: '100%',
            fontSize: { xs: '1.25rem', md: '2rem' },
            alignItems: 'start',
          }}
        >
          <Box width="100%" textAlign="center">
            Thousands of businesses and sellers who trust ShopiView to revolutionize their feedback
            process have joined us
          </Box>
        </ColorBox>
      </Container>
    </>
  )
}

export default Welcome
