//TODO: add text colors and separate pallets for each status set
import { forwardRef } from 'react'
import { NavLink as RouterLink, NavLinkProps as RouterLinkProps } from 'react-router-dom'

import { LinkProps } from '@mui/material'
import { createTheme } from '@mui/material/styles'

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  return <RouterLink ref={ref} to={href} {...other} />
})
LinkBehavior.displayName = 'LinkBehavior'

const primary = {
  main: '#007bff',
  text: '#262626',
}

// reviews statuses colors
declare module '@mui/material/styles' {
  interface PaletteOptions {
    moderation?: PaletteOptions['primary']
    draft?: PaletteOptions['primary']
    returned?: PaletteOptions['primary']
    reject?: PaletteOptions['primary']
    approve?: PaletteOptions['primary']
    pending?: PaletteOptions['primary']
  }
  interface Palette {
    moderation: Palette['primary']
    draft: Palette['primary']
    returned: Palette['primary']
    reject: Palette['primary']
    approve?: PaletteOptions['primary']
    pending: PaletteOptions['primary']
  }
}

export const theme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary,
    secondary: {
      main: '#E0F1F0',
    },
    text: {
      primary: primary.text,
      secondary: '#797D80',
      disabled: '#A6A8AA',
    },
    common: {
      black: primary.text,
      white: '#FFFFFF',
    },
    warning: {
      main: '#ed6c02',
      light: '#ee6881',
      dark: '#e65100',
    },
    error: {
      main: '#f44336',
      light: '#f6685e',
      dark: '#aa2e25',
    },
    grey: {
      300: '#D2D4D5',
      600: '#797D80',
    },
    approve: {
      main: '#09C132',
    },
    moderation: {
      main: '#EFDC00',
    },
    draft: {
      main: '#1771CC',
    },
    returned: {
      main: '#FF8A00',
    },
    reject: {
      main: '#EA1616',
    },
    pending: {
      main: '#9AC0DB',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontWeightRegular: 400,
    fontSize: 14,
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: 1.44,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '3.5rem',
          '& fieldset.MuiOutlinedInput-notchedOutline legend>span': {
            paddingRight: '0.5rem !important',
            paddingLeft: '0.5rem !important',
          },
          '&.Mui-focused': {
            borderColor: '#000000',
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: '#000000',
            },
          },
          '&.MuiInputBase-multiline': {
            height: '12.875rem',
          },
          '&.MuiInputBase-multiline textarea.MuiInputBase-inputMultiline': {
            height: '10.875rem',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&:hover': {
            border: 'none',
          },

          '& label.Mui-focused': {
            color: '#000000',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#000000',
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: '#000000',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#000000',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          padding: '0.75rem 1.25rem',
          '& label.Mui-focused': {
            color: '#000000',
          },
          // focused color for input with variant='standard'
          '& .MuiInput-underline:after': {
            borderBottomColor: '#000000',
          },
          // focused color for input with variant='filled'
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: '#000000',
          },
          // focused color for input with variant='outlined'
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#000000',
            },
          },
        },
        input: {
          padding: '0',
        },
        sizeSmall: {
          padding: '0.5rem 0.875rem',
          borderRadius: '0.5rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          // top: '-0.25rem',
          width: 'fit-content',
          '&.Mui-focused': {
            color: '#000000',
          },
        },
        shrink: {
          top: '0px',
        },
        sizeSmall: {
          top: '0px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '0',
          borderRadius: '0.5rem',
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1.25rem',
          height: '3rem',
          padding: '0.875rem 1.5rem',
          textTransform: 'none',
          boxShadow: 'none',
          boxSizing: 'border-box',
          '&:hover': {
            boxShadow: 'none',
          },

          // '& .MuiButton-endIcon': {
          //   margin: '0 0 0 0.25rem',
          //   fontSize: '1.5rem',
          //   width: '1.5rem',
          //   height: '1.5rem',
          // },
          // '& .MuiSvgIcon-root': {
          //   fontSize: '1.5rem',
          //   width: '1.5rem',
          //   height: '1.5rem',
          // },
        },
        outlinedPrimary: {
          color: '#000000',
          border: '1px #000000 solid',
          '&:hover': {
            borderColor: '#262626',
            color: '#fff',
            backgroundColor: '#262626',
          },
          '&.Mui-disabled': {
            color: 'rgba(130, 130, 130, 0.4)',
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
            border: '1px solid rgba(130, 130, 130, 0.4)',
          },
        },
        outlinedSecondary: {
          color: '#FFFFFF',
          border: '1px #FFFFFF solid',
          '&:hover': {
            borderColor: '#FFFFFF',
            backgroundColor: '#000000',
          },
        },
        containedPrimary: {
          boxSizing: 'border-box',
          backgroundColor: '#262626',
          border: '1px transparent solid',
          '&:hover': {
            color: '#262626',
            border: '1px #262626 solid',
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            color: '#fff',
            boxShadow: 'none',
            backgroundColor: 'rgba(130, 130, 130, 0.4)',
          },
        },
        sizeSmall: {
          padding: '0.625rem 1rem',
          height: '2.5rem',
          fontSize: '1rem',
          borderRadius: '0.5rem',
        },
        outlined: {
          border: '1px #FFFFFF solid',
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          '&:hover': {
            color: '#007bff',
          },
          '&:active': {
            color: '#007bff',
          },
          '&.Mui-disabled': {
            color: '#797D80',
            opacity: 1,
          },
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#f0f0f0',
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root a': {
            color: primary.main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
          '& .MuiButtonBase-root': {
            fontSize: '1rem',
            fontFamily: 'Roboto, sans-serif',
            color: 'gray',
            borderBottom: '1px solid #828282',
            padding: '0.75rem 1rem',
          },
          '& .MuiTabs-scroller': {
            paddingBottom: '0.25rem',
          },
          '& .MuiButtonBase-root.Mui-selected': {
            color: '#21272A',
          },
          '& .MuiTabs-indicator': {
            height: '0.375rem',
            bottom: '0',
            backgroundColor: '#262626',
            zIndex: 10,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          lineHeight: '144%',
          textDecoration: 'none',
          minWidth: 0,
          textTransform: 'capitalize',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          height: 'fit-content',
          minHeight: 'fit-content',
          '& .MuiDataGrid-menuList': {
            minWidth: 'auto',
            '& .MuiListItemText-primary': {
              fontSize: 14,
            },
            '& .MuiMenuItem-root': {
              //height: '2.5rem',
            },
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          height: 'fit-content',
          minHeight: 'fit-content',
          '& .MuiDataGrid-panelContent': {
            minHeight: 'fit-content',
            //height: '100%'
          },
          '& .MuiDataGrid-filterForm': {
            minHeight: 'fit-content',
            height: '100%',
            width: '37.5rem',
          },
          '& .MuiDataGrid-filterFormValueInput': {
            minHeight: 'fit-content',
            width: '15.625rem',
          },
          '& .MuiDataGrid-filterFormDeleteIcon': {
            alignSelf: 'flex-start',
          },
          '& .MuiCalendarOrClockPicker-root': {
            width: 'fit-content',
          },
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        color: '#636465FF',
        transition: '.3s',
        // '&:hover': { color: 'rgb(0, 123, 255)' },
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: '#888',
          '&.Mui-checked': {
            // Controls checked color for the thumb
            color: '#262626',
          },
        },

        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: '#262626',
          '$checked$checked + &': {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: '#262626',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          color: '#262626',
          backgroundColor: '#fff',
          p: '0.625rem',
          boxShadow: '0px 2px 4px 0px #00000040',
        },
      },
    },
  },
})
