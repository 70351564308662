import { TableServerResponse } from 'types/Table'

import { baseApi } from 'api/api'

import { AdminCoupons, GetCouponsDataToTablePayload } from './types'

export const adminCouponApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCouponsAdmin: build.query<TableServerResponse<AdminCoupons[]>, GetCouponsDataToTablePayload>(
      {
        query: (params) => ({
          url: '/admin/coupons',
          method: 'GET',
          params,
        }),
      }
    ),
  }),
})

export const { useGetCouponsAdminQuery } = adminCouponApi
