import { FC, useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Paper, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'

import { SELLER_FAQ, REVIEWER_FAQ } from 'constants/faq'

import KeycloakService from 'services/KeycloakService'

import Button from 'components/common/Button'
import PageHeader from 'components/common/PageHeader'
import FeedbackModal from 'components/support/FeedbackModal'

const Support: FC = () => {
  const faq = KeycloakService.hasRole(['Seller']) ? SELLER_FAQ : REVIEWER_FAQ

  const [modalShow, setModalShow] = useState(false)

  const handleModalClose = () => {
    setModalShow(false)
  }
  return (
    <>
      <PageHeader
        title="Support"
        rightContent={
          <Button onClick={() => setModalShow(true)} sx={{ px: '2.5rem', letterSpacing: '0.5px' }}>
            Leave a feedback
          </Button>
        }
      />

      <Paper sx={{ p: { xs: '1rem 2rem', md: '1.5rem 4rem' }, width: '100%' }}>
        <Typography sx={{ fontSize: '2rem', fontWeight: 800 }}>FAQ</Typography>
        {faq.map(({ summary, details }, index) => (
          <Accordion
            key={`faq-${index}`}
            disableGutters
            elevation={0}
            sx={{
              borderBottom: '1px #DFDFDF solid',
              py: '1rem',
              '&.MuiButtonBase-root-MuiAccordionSummary-root': { p: '0' },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
              <Typography sx={{ fontSize: '1.25rem', fontWeight: 500, p: 0 }}>{summary}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ fontSize: '0.875rem', textAlign: 'justify', pl: '1rem' }}>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '1rem',
                  fontWeight: '400',
                  lineHeight: '1.5rem',
                  textAlign: 'left',
                }}
              >
                {details}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>

      {modalShow && <FeedbackModal onClose={handleModalClose} />}
    </>
  )
}

export default Support
