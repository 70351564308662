import { FC } from 'react'

import { Box } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetProductsQuery } from 'services/products/api'

import SearchInput from 'components/common/SearchInput'
import StatusesFilter from 'components/common/StatusesFilter'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'
import { filters } from './filters'

const AllProducts: FC = () => {
  const {
    queryParams,
    pagination,
    sort,
    search,
    statusFilter,
    setPagination,
    setSort,
    setSearch,
    isFiltered,
    setStatusFilter,
  } = useDataGridPagination('notificationStatus', false)

  const { data, isLoading, isFetching } = useGetProductsQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  })

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', mb: '1.5rem' }}>
        <StatusesFilter filters={filters} init={statusFilter} filterChange={setStatusFilter} />
        <SearchInput initValue={search} onSearchChange={setSearch} />
      </Box>
      <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
        <CustomDataGrid
          data={data}
          columns={columns}
          loading={isLoading || isFetching}
          paginationModel={pagination}
          setPagination={setPagination}
          setSort={setSort}
          initSort={sort}
          isFiltered={isFiltered}
          rowHeight={72}
        />
      </Box>
    </Box>
  )
}

export default AllProducts
