import { requiredMessage } from 'validation'
import { object, string } from 'yup'

const MAX_NAME = 20
const MAX_DESCRIPTION = 100

export const videoDetailsSchema = object({
  name: string().min(1).max(MAX_NAME).required(requiredMessage),
  description: string()
    .max(MAX_DESCRIPTION)
    .notRequired()
    .nullable()
    .transform((v) => (v ? v : null)),
})
