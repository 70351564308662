export const filters = [
  {
    label: 'Active',
    status: '1',
  },
  {
    label: 'Inactive',
    status: '0',
  },
]
