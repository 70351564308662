import { ReviewStatus } from 'services/reviews/types'

export const modalHeaders = {
  [ReviewStatus.REJECTED]: {
    header: 'Reject',
    confirm: 'Are you sure you want to reject this review?',
  },
  [ReviewStatus.APPROVED]: {
    header: '',
    confirm: 'Are you sure you want to Approve this video and publish it?',
  },
  [ReviewStatus.RETURNED]: {
    header: 'Return for further improvements',
    confirm: 'Are you sure you want to send this comment?',
  },
} as const
