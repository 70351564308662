import { skipToken } from '@reduxjs/toolkit/query'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Skeleton } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetReviewsAdminQuery } from 'services/admin/reviews/api'
import { useGetUserQuery } from 'services/users/api'

import PageHeader from 'components/common/PageHeader'
import SearchInput from 'components/common/SearchInput'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'

const AdminReviewsByReviewer: FC = () => {
  const { reviewerId } = useParams()

  const { data: reviewer, isLoading: isReviewerLoading } = useGetUserQuery(
    reviewerId ? { id: reviewerId } : skipToken
  )

  const { queryParams, pagination, sort, search, setPagination, setSort, setSearch, isFiltered } =
    useDataGridPagination()

  const { data, isLoading, isFetching } = useGetReviewsAdminQuery(
    { reviewerId, ...queryParams },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  return (
    <>
      <PageHeader
        title={
          isReviewerLoading ? (
            <Skeleton width="12rem" height="3.2rem" />
          ) : (
            `${reviewer?.firstName} ${reviewer?.lastName}`
          )
        }
        backPath={`/admin/reviewers`}
      />
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mb: '1.5rem' }}>
          <SearchInput initValue={search} onSearchChange={setSearch} />
        </Box>
        <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
          <CustomDataGrid
            data={data}
            columns={columns}
            loading={isLoading || isFetching}
            paginationModel={pagination}
            setPagination={setPagination}
            setSort={setSort}
            initSort={sort}
            isFiltered={isFiltered}
            rowHeight={72}
          />
        </Box>
      </Box>
    </>
  )
}

export default AdminReviewsByReviewer
