import { baseApi } from 'api/api'

import { Balance, Received, BalanceTopUpPayload } from './types'

export const balanceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBalance: build.query<Balance, void>({
      query: () => ({
        url: '/users/balance',
        method: 'GET',
      }),
      providesTags: ['Balance'],
    }),
    getReviewerReceived: build.query<Received[], void>({
      query: () => ({
        url: '/coupons/balance_by_sellers',
        method: 'GET',
      }),
      providesTags: ['Received'],
    }),
    emission: build.mutation<Balance, BalanceTopUpPayload>({
      query: (data) => ({
        url: '/users/refill',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Balance'],
    }),
  }),
})

export const { useGetBalanceQuery, useGetReviewerReceivedQuery, useEmissionMutation } = balanceApi
