import { MouseEvent } from 'react'

const THRESHOLD = 50

const scrollToComponent = (event: MouseEvent | undefined, id: string) => {
  event?.preventDefault()
  const component = document.getElementById(id)
  if (component) {
    window.scrollTo({ top: component.offsetTop - THRESHOLD, behavior: 'smooth' })
  }
}

export default scrollToComponent
