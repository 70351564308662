import { ChangeEvent, FC, useState } from 'react'

import { ButtonProps } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { BYTES_IN_KBYTES, KBYTES_IN_MBYTES } from 'constants/file-size'

import Button from 'components/common/Button'

interface Props extends ButtonProps {
  setFile: (files: File | null) => void
  acceptedFormats: string[]
  maxSize: number
  name?: string
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const UploadFileButton: FC<Props> = ({
  setFile,
  acceptedFormats,
  maxSize,
  name = 'Upload file',
  ...rest
}) => {
  const [fileErrors, setFileErrors] = useState<string[] | null>(null)

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const addedFile = event.target.files?.[0]

    if (addedFile) {
      const calculateFileSize = addedFile.size / (BYTES_IN_KBYTES * KBYTES_IN_MBYTES)
      const fileExtension = `.${addedFile.name.split('.').pop()?.toLowerCase()}`

      if (calculateFileSize > maxSize) {
        setFileErrors(['File size exceeds the maximum allowed.'])
      } else if (fileExtension && !acceptedFormats.includes(fileExtension)) {
        setFileErrors(['Invalid file format.'])
      } else {
        setFile(addedFile)
        setFileErrors(null)
      }
    }
  }
  return (
    <Box>
      <Button component="label" variant="outlined" sx={{ width: '100%' }} {...rest}>
        {name}
        <VisuallyHiddenInput
          type="file"
          accept={acceptedFormats.join(',')}
          onChange={handleUploadFile}
        />
      </Button>
      {fileErrors && (
        <Box sx={{ textAlign: 'center', mt: '0.5rem' }}>
          {fileErrors.map((error, index) => (
            <Typography key={index} variant="body2" color="error">
              {error}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default UploadFileButton
