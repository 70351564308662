import AxiosInterceptor from 'api'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'

const Root = () => {
  return (
    <>
      <AxiosInterceptor />
      <CssBaseline />
      <ScrollRestoration />
      <Outlet />
    </>
  )
}

export default Root
