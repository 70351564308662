import Keycloak, { KeycloakConfig } from 'keycloak-js'

const MIN_VALIDITY = 10

const _kc = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
  'ssl-required': 'external',
  'public-client': true,
} as KeycloakConfig)

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      pkceMethod: 'S256',
      redirectUri:
        window.location.pathname === '/welcome' || window.location.pathname === '/sellers'
          ? //window.location.pathname === '/reviewers'
            process.env.REACT_APP_CLIENT_URL
          : window.location.href,
    })
    .then((authenticated) => {
      onAuthenticatedCallback()
      if (!authenticated) {
        // console.log('user is not authenticated..!')
      }
    })
    .catch()
}

const doRegistration = _kc.register

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const updateToken = _kc.updateToken

const isTokenExpired = () => _kc.isTokenExpired(MIN_VALIDITY)

const getTokenParsed = () => _kc.tokenParsed

const isLoggedIn = () => !!_kc.token

const getUsername = () => _kc.tokenParsed?.name

const getUserId = () => _kc.tokenParsed?.sub

const hasRole = (roles: string[]) => roles.some((role) => _kc.hasRealmRole(role))

const getTokenWithUpdate = () => {
  const tokenExp = _kc.isTokenExpired(MIN_VALIDITY)
  return new Promise((resolve) => {
    if (tokenExp) {
      _kc
        .updateToken(MIN_VALIDITY)
        .then(() => {
          resolve(_kc.token)
        })
        .catch(() => {
          window.location.replace('/')
        })
    } else {
      resolve(_kc.token)
    }
  })
}

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  doRegistration,
  isLoggedIn,
  getToken,
  getTokenParsed,
  getUsername,
  getUserId,
  updateToken,
  isTokenExpired,
  getTokenWithUpdate,
  hasRole,
}

export default KeycloakService
