import { FC, ReactNode } from 'react'

import { BoxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { grey } from '@mui/material/colors'

interface Props extends BoxProps {
  children?: ReactNode
}

const ColorBox: FC<Props> = ({ children, sx, ...rest }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '15rem',
        p: '2rem',
        fontSize: '1.5rem',
        fontWeight: '600',
        borderRadius: '1.25rem',
        flex: '1 0 30%',
        bgcolor: grey[200],
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default ColorBox
