import { FC } from 'react'
import Carousel from 'react-material-ui-carousel'

import { Typography, Box, useMediaQuery, Theme } from '@mui/material'

import scrollToComponent from 'utils/scrollToComponent'

import Button from 'components/common/Button'

interface Props {
  title: string
  text: string
  buttonText?: string
  buttonAction?: () => void
  secondButtonText?: string
  secondButtonAction?: () => void
  carouselItems: { url: string; mobilePosition?: string }[]
}

const LandingCarousel: FC<Props> = ({
  title,
  text,
  buttonText,
  carouselItems,
  buttonAction,
  secondButtonText,
  secondButtonAction,
}) => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Box sx={{ width: '100%', height: '56.25rem' }}>
      <Carousel
        sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '56.25rem', zIndex: 1 }}
        duration={2000}
        interval={4000}
        navButtonsAlwaysInvisible={true}
        stopAutoPlayOnHover={false}
        indicatorIconButtonProps={{
          style: {
            color: '#FFFFFF',
            opacity: '0.5',
            width: '1rem',
            height: '1rem',
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            opacity: 1,
          },
        }}
        indicatorContainerProps={{
          style: {
            position: 'absolute',
            bottom: '3.75rem',
            zIndex: 15,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '0.5rem',
          },
        }}
      >
        {carouselItems.map((item, key) => (
          <Box
            key={key}
            sx={{
              width: '100%',
              height: '56.25rem',
              background: `linear-gradient(110.41deg, rgba(0, 0, 0, 0.8) -4.47%, rgba(0, 0, 0, 0.788887) ${
                isSm ? '17.91%' : '6.18%'
              }, rgba(0, 0, 0, 0) ${isSm ? '74.57%' : '53.82%'}), url(${item.url})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: {
                xs: item.mobilePosition || 'right',
                sm: item.mobilePosition || 'right',
                md: 'right',
              },
            }}
          />
        ))}
      </Carousel>
      <Box
        id="header"
        sx={{
          display: 'flex',
          gap: '3rem',
          pt: { xs: '17.25rem', sm: '17.25rem', md: '17.25rem', lg: '17.25rem', xl: '17.25rem' },
          position: 'relative',
          zIndex: 10,
          maxWidth: '1152px',
          margin: '0 auto',
        }}
      >
        <Box sx={{ maxWidth: '41.375rem', alignSelf: 'center', px: '1.5rem' }}>
          <Typography
            sx={{
              fontSize: { xs: '2.5rem', sm: '3.5rem', md: '3.5rem', lg: '3.5rem', xl: '3.5rem' },
              fontWeight: 900,
              lineHeight: 1.2,
              color: '#ffffff',
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: '1.125rem',
              fontWeight: 400,
              mt: '1.5rem',
              textAlign: 'justify',
              color: '#ffffff',
            }}
          >
            {text}
          </Typography>
          <Box sx={{ mt: '3rem', display: 'flex', gap: '1rem' }}>
            {buttonText && (
              <Button
                onClick={(event) =>
                  buttonAction ? buttonAction() : scrollToComponent(event, 'pricing')
                }
                sx={{
                  width: {
                    xs: '100%',
                    sm: '18.75rem',
                  },
                  fontSize: '1.25rem',
                  fontWeight: '600',
                  height: '70px',
                  borderRadius: '35px',
                  color: '#262626',
                  backgroundColor: '#CBFA92',
                  transition: '.3s',
                  borderColor: '#CBFA92',
                  '&:hover': {
                    backgroundColor: '#8CFF00',
                    borderColor: '#CBFA92',
                  },
                  '&:active': {
                    transform: 'scale(0.97)',
                  },
                  '& .MuiTouchRipple-root': {
                    opacity: 0,
                  },
                }}
              >
                {buttonText}
              </Button>
            )}
            {secondButtonText && (
              <Button
                onClick={() => {
                  if (secondButtonAction) {
                    secondButtonAction()
                  }
                }}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '18.75rem',
                  },
                  fontSize: '1.25rem',
                  fontWeight: '600',
                  height: '70px',
                  borderRadius: '35px',
                  border: '1px solid #FFFFFF',
                  color: '#FFFFFF',
                  backgroundColor: 'transparent',
                  transition: '.3s',
                  '&:hover': {
                    backgroundColor: '#FFFFFF',
                    color: '#262626',
                    borderColor: '#FFFFFF',
                  },
                  '&:active': {
                    transform: 'scale(0.97)',
                  },
                  '& .MuiTouchRipple-root': {
                    opacity: 0,
                  },
                }}
              >
                {secondButtonText}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default LandingCarousel
