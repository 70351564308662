import { ReactComponent as LinkedInIcon } from 'assets/svg/social/in.svg'

// import { ReactComponent as InstagramIcon } from 'assets/svg/social/inst.svg'
// import { ReactComponent as XIcon } from 'assets/svg/social/x.svg'
// import { ReactComponent as YouTubeIcon } from 'assets/svg/social/youtube.svg'
// import { ReactComponent as FacebookIcon } from 'assets/svg/social/fb.svg'

export const footerMenu = [
  {
    title: 'Sellers',
    items: [
      {
        link: '/sellers#benefits',
        text: 'Benefits',
      },
      {
        link: '/sellers#howItWorks',
        text: 'How it works?',
      },
      {
        link: '/sellers#pricing',
        text: 'Pricing',
      },
      {
        link: '/sellers#faq',
        text: 'FAQ',
      },
    ],
  },
  {
    title: 'Reviewers',
    items: [
      {
        link: '/reviewers#benefits',
        text: 'Benefits',
      },
      {
        link: '/reviewers#howItWorks',
        text: 'How it works?',
      },
      {
        link: '/reviewers#features',
        text: 'Features',
      },
      {
        link: '/reviewers#faq',
        text: 'FAQ',
      },
    ],
  },
]

export const legal = {
  title: 'Legal',
  items: [
    {
      link: '/privacy-policy',
      text: 'Privacy Policy',
    },
    {
      link: '/terms-and-conditions',
      text: 'Terms of Service',
    },
    {
      link: '/cookie-policy',
      text: 'Cookies Settings',
    },
  ],
}

export const socialLinks = [
  // {
  //   icon: <FacebookIcon />,
  //   link: '#',
  // },
  // {
  //   icon: <InstagramIcon />,
  //   link: '#',
  // },
  // {
  //   icon: <XIcon />,
  //   link: '#',
  // },
  {
    icon: <LinkedInIcon />,
    link: '#',
  },
  // {
  //   icon: <YouTubeIcon />,
  //   link: '#',
  // },
]
