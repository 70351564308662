import { FC } from 'react'

import { Box, Avatar } from '@mui/material'

import { MEDIA_URL } from 'constants/urls'

import { showNotification } from 'utils/showNotification'

import { useUploadProfileImageMutation } from 'services/users/api'

import { ReactComponent as PencilIcon } from 'assets/svg/gravity-ui_pencil-to-line.svg'

import ImageUploader from 'components/common/ImageUploader'

interface Props {
  width?: string
  height?: string
  src?: string
}

const EditAvatar: FC<Props> = ({ src = '' }) => {
  const [uploadProfileImage, { isLoading }] = useUploadProfileImageMutation()
  const handleSetCropImage = (image: Blob) => {
    uploadProfileImage({ file: image })
      .then(() => {
        showNotification('Profile photo successfully updated', 'success')
      })
      .catch((error) => {
        showNotification('Some error occurred', error)
      })
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Avatar
        src={src ? `${MEDIA_URL}${src}?${Date.now()}` : ''}
        sx={{ width: '6.25rem', height: '6.25rem', backgroundColor: 'rgba(0,0,0,.2)' }}
      />
      <ImageUploader
        setCropImage={(image) => {
          handleSetCropImage(image)
        }}
        loading={isLoading}
        sx={{
          width: '6.25rem',
          height: '6.25rem',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0,
          transition: '0.3s',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            opacity: 1,
          },
        }}
      >
        <PencilIcon />
      </ImageUploader>
    </Box>
  )
}

export default EditAvatar
