import { FC } from 'react'

import { Box } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetCouponsQuery } from 'services/coupons/api'
import { CouponStatus } from 'services/coupons/types'

import EmptyState from 'components/common/EmptyState'
import Pagination from 'components/common/Pagination'
import Preloader from 'components/common/Preloader'
import StatusesFilter from 'components/common/StatusesFilter'
import CouponItem from 'components/coupons/CouponItem'

import { couponsFilters } from './filters'

const CouponsList: FC = () => {
  const { queryParams, pagination, statusFilter, setPagination, setStatusFilter, isFiltered} =
    useDataGridPagination()

  const { data, isLoading } = useGetCouponsQuery(
    { ...queryParams, isReviewer: 1 },
    { refetchOnMountOrArgChange: true }
  )

  if (isLoading) return <Preloader />

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <Box mb="1.5rem" display="flex" justifyContent="space-between">
        <StatusesFilter<CouponStatus>
          init={statusFilter}
          filterChange={setStatusFilter}
          filters={couponsFilters}
        />
      </Box>
      {!data?.data?.length && <EmptyState isFiltered={isFiltered} text="You haven't got any coupons yet." />}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          mb: '2.5rem',
          gap: '1rem',
          justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
        }}
      >
        {data?.data?.map((coupon) => <CouponItem coupon={coupon} key={coupon.id} />)}
      </Box>
      {!!data?.meta?.totalItems && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={Math.ceil(data.meta.totalItems / data.meta.itemsPerPage) || 1}
            page={pagination.page || 1}
            onChange={setPagination}
          />
        </Box>
      )}
    </Box>
  )
}

export default CouponsList
