import { FC, ReactNode } from 'react'
import { useMatch } from 'react-router-dom'

import { Link } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

interface MenuItemProps {
  text: string
  icon?: ReactNode
  link: string
  open: boolean
}

const MenuItem: FC<MenuItemProps> = ({ text, icon, link, open }) => {
  const match = useMatch({
    path: link,
    end: false,
  })

  return (
    <ListItem component="nav" disablePadding>
      <Link
        href={link}
        sx={[
          {
            width: '100%',
            textDecoration: 'none',
            color: '#262626',
            backgroundColor: match ? '#F7F5F7' : 'transparent',
          },
        ]}
      >
        <ListItemButton
          sx={{
            height: '3rem',
            justifyContent: open ? 'initial' : 'center',
            px: '1.25rem',
            '&:hover': {
              color: '#262626',
              backgroundColor: !match ? 'rgba(0,0,0,0.08)' : 'transparent',
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? '1rem' : 'auto',
              justifyContent: 'center',
              color: 'inherit',
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} sx={{ fontSize: '0.875rem', opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </Link>
    </ListItem>
  )
}

export default MenuItem
