import { Link } from 'react-router-dom'

import { Box, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'

import formatDate from 'utils/formatToLocalTimezone'

import { AdminReviewers } from 'services/admins/types'

export const columns: GridColDef<AdminReviewers>[] = [
  {
    field: 'name',
    headerName: 'Full Name',
    type: 'string',
    flex: 1.5,
    valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`,
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    flex: 1.5,
  },
  {
    field: 'createdAt',
    headerName: 'Date of Reg',
    type: 'string',
    flex: 1.5,
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    field: 'sellersCount',
    headerName: 'Sellers',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'salesCount',
    headerName: 'Purchases',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'reviewsCount',
    headerName: 'Reviews',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography>{row.reviewsCount}</Typography>
        <Link to={`${row.id}/reviews`}>Show Details</Link>
      </Box>
    ),
  },
  {
    field: 'approvedReviewsCount',
    headerName: 'Approved',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'moderationReviewsCount',
    headerName: 'On Moderation',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'rejectedReviewsCount',
    headerName: 'Rejected',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'totalEarned',
    headerName: 'Earnings',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'couponsCount',
    headerName: 'Coupons',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography>{row.couponsCount}</Typography>
        <Link to={`${row.id}/coupons`}>Show Details</Link>
      </Box>
    ),
  },
  {
    field: 'totalPurchased',
    headerName: 'Coupons Value, $',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
]
