import { TableServerResponse } from 'types/Table'

import { baseApi } from 'api/api'

import { AdminProduct, GetProductsDataToTablePayload } from './types'

export const adminProductsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminProducts: build.query<TableServerResponse<AdminProduct[]>, GetProductsDataToTablePayload>({
      query: (params) => ({
        url: `/admin/products`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const { useGetAdminProductsQuery } = adminProductsApi
