import { FC } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography, Divider } from '@mui/material'

import { useGetAdminDashboardStatsQuery } from 'services/admin/dashboard/api'

import PageHeader from 'components/common/PageHeader'

import StatisticsCard from '../StatisticsCards'

const statisticCartWrapperStyle = {
  width: '100%',
  maxWidth: '21.5rem',
}

const cardsWrapperStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  mb: '2.5rem',
  gap: '1rem',
  justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
}

const dividerStyle = { mb: 2, borderColor: '#E0E0E0' }

const AdminDashboard: FC = () => {
  const { data, isLoading } = useGetAdminDashboardStatsQuery()

  return (
    <>
      <PageHeader title="Dashboard" />
      <Typography variant="h3">Users</Typography>
      <Divider sx={dividerStyle}/>
      <Box sx={cardsWrapperStyle}>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Sellers"
            count={data?.totalSellersCount || 0}
            additional={{
              amount: '',
              label: 'Total number of users registered as a seller',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Reviewers"
            count={data?.totalReviewsCount || 0}
            additional={{
              amount: '',
              label: 'Total number of users registered as a reviewer',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <Typography variant="h3">Reviews</Typography>
      <Divider sx={dividerStyle}/>
      <Box sx={cardsWrapperStyle}>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Reviews"
            count={data?.totalReviewsCount || 0}
            additional={{
              amount: '',
              label: 'Total number of uploaded reviews',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Approved Reviews"
            count={data?.totalApprovedReviewsCount || 0}
            additional={{
              amount: '',
              label: 'Total number of reviews with status Approved',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Reviews On Moderation"
            count={data?.totalModerationReviewsCount || 0}
            additional={{
              amount: '',
              label: 'Total reviews with status On Moderation',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Reviews Rejected"
            count={data?.totalRejectedReviewsCount || 0}
            additional={{
              amount: '',
              label: 'Total reviews with status Rejected',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <Typography variant="h3">Payments</Typography>
      <Divider sx={dividerStyle}/>
      <Box sx={cardsWrapperStyle}>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Total payments"
            count={data?.totalAmountEarned || 0}
            additional={{
              amount: '',
              label: 'Amount of all transferred funds',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Coupons Value"
            count={data?.totalAmountPurchased || 0}
            additional={{
              amount: '',
              label: 'Total coupons purchase value',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <Typography variant="h3">Subscriptions</Typography>
      <Divider sx={dividerStyle}/>
      <Box sx={cardsWrapperStyle}>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Trial Subscription Plan"
            count={data?.totalTrialSubscriptionCount || 0}
            additional={{
              amount: '',
              label: 'Total users with Trial Subscription Plan',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Standard Subscription Plan"
            count={data?.totalStandardSubscriptionCount || 0}
            additional={{
              amount: '',
              label: 'Total users with Standard Subscription Plan',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
        <Box sx={statisticCartWrapperStyle}>
          <StatisticsCard
            icon={<InfoIcon />}
            title="Pro Subscription Plan"
            count={data?.totalProSubscriptionCount || 0}
            additional={{
              amount: '',
              label: 'Total users with Pro Subscription Plan',
            }}
            background={'#AAAAAA'}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </>
  )
}

export default AdminDashboard
