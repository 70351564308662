import { FC } from 'react'

import { RolesGroups } from 'types/User'

import { userRoles } from 'constants/roles'

import KeycloakService from 'services/KeycloakService'

import AdminDashboard from 'components/dashboard/AdminDashboard'
import ReviewerDashboard from 'components/dashboard/ReviewerDashboard'
import SellerDashboard from 'components/dashboard/SellerDashboard'

const Dashboard: FC = () => {
  if (KeycloakService.hasRole([userRoles[RolesGroups.SELLER]])) return <SellerDashboard />
  if (KeycloakService.hasRole([userRoles[RolesGroups.REVIEWER]])) return <ReviewerDashboard />
  return <AdminDashboard />
}

export default Dashboard
