import { FC } from 'react'

import { Paper, Box, Skeleton, useMediaQuery, Theme } from '@mui/material'

import KeycloakService from 'services/KeycloakService'
import { useGetCurrentUserQuery } from 'services/users/api'

import Button from 'components/common/Button'
import { EllipsedText } from 'components/common/EllipsedText'
import EditAvatar from 'components/profile/EditAvatar'

const UserInfo: FC = () => {
  const { data, isLoading } = useGetCurrentUserQuery()
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const handlerChangePassword = () => {
    KeycloakService.doLogin({ action: 'UPDATE_PASSWORD' })
  }

  if (isLoading) {
    return (
      <Skeleton variant="rounded" sx={{ width: '100%', maxWidth: '1056px', height: '10.5rem' }} />
    )
  }

  return (
    <Paper
      sx={{
        p: { xs: '1rem', sm: '2rem' },
        display: 'flex',
        justifyItems: 'start',
        gap: '1.5rem',
        maxWidth: '1056px',
        flexDirection: { xs: 'column', sm: 'row', md: 'row' },
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          gap: '1rem',
          minWidth: 0,
          flexDirection: { xs: 'column', sm: 'row', md: 'row' },
        }}
      >
        <Box alignSelf="center">
          <EditAvatar src={data?.profileImageUrl} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            minWidth: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
          }}
        >
          <EllipsedText
            title={`${data?.firstName} ${data?.lastName}`}
            sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              minWidth: 0,
              width: isSm ? '100%' : null,
              textAlign: 'center',
            }}
          />
          <EllipsedText
            title={data?.email || ''}
            sx={{
              minWidth: 0,
              width: isSm ? '100%' : null,
              fontSize: '0.875rem',
              textAlign: 'center',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', width: isSm ? '100%' : 'fit-content' }}>
        <Button onClick={handlerChangePassword} fullWidth={isSm}>
          Change password
        </Button>
      </Box>
    </Paper>
  )
}

export default UserInfo
