import { skipToken } from '@reduxjs/toolkit/query'
import { FC, useState, SyntheticEvent } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Tab, Tabs } from '@mui/material'

import { useGetReviewByIdQuery } from 'services/reviews/api'

import PageBox from 'components/common/Page'
import PageHeader from 'components/common/PageHeader'
import Preloader from 'components/common/Preloader'
import ReviewContent from 'components/reviews/ReviewContent'
import ReviewHistory from 'components/reviews/ReviewHistory'
import SellerReviewInfo from 'components/reviews/SellerReviewInfo'

const ReviewForSeller: FC = () => {
  const { productId, reviewId } = useParams()
  const [currentTabIndex, setCurrentTabIndex] = useState<0 | 1>(0)
  const handleTabChange = (e: SyntheticEvent, tabIndex: 0 | 1) => {
    setCurrentTabIndex(tabIndex)
  }

  const { data, isLoading } = useGetReviewByIdQuery(reviewId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  })

  if (isLoading || !data) return <Preloader />

  return (
    <>
      <PageHeader
        title={data.productName}
        backPath={productId ? `/products/${productId}` : `/reviews`}
      />
      <PageBox paperMaxWidth="70rem">
        <SellerReviewInfo review={data} />
        <Box>
          <Tabs value={currentTabIndex} onChange={handleTabChange}>
            <Tab label="video" sx={{ width: '8.5rem' }} />
            <Tab label="history" sx={{ width: '8.5rem' }} />
          </Tabs>
        </Box>
        <Box sx={{ mt: '0.5rem', maxWidth: '70rem', width: '100%', position: 'relative' }}>
          <ReviewContent review={data} />
          {currentTabIndex === 1 && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: 'calc(100% + 0.5rem)',
                top: '-0.5rem',
                left: 0,
                overflow: 'hidden',
                bgcolor: '#FFFFFF',
                zIndex: 9,
              }}
            >
              <ReviewHistory reviewId={data.id} />
            </Box>
          )}
        </Box>
      </PageBox>
    </>
  )
}

export default ReviewForSeller
