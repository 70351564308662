import { FC, SyntheticEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, useMediaQuery, Theme } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import { TabsProps } from '@mui/material/Tabs/Tabs'

export interface TabItem {
  id: string
  label: string
  mobileOnly?: boolean
}

interface Props extends TabsProps {
  tabs: TabItem[]
  basePath: string
}

const NavigationTabs: FC<Props> = ({ tabs, sx, basePath, ...rest }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    navigate(newValue)
  }
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Box>
      <Tabs
        {...rest}
        value={location.pathname}
        onChange={handleChange}
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
          zIndex: 10,
          ...sx,
        }}
      >
        {tabs.map(({ id, label }) => (
          <Tab
            key={id}
            value={id ? `${basePath}/${id}` : basePath}
            label={label}
            sx={{
              zIndex: 10,
              width: isSm ? 'fit-content' : '8.5rem',
            }}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default NavigationTabs
