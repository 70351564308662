import { FC, ReactNode } from 'react'

import { Card, Divider, Typography, Box } from '@mui/material'

import CardPreloader from 'components/common/CardPreloader'

interface Props {
  title: string
  count: string | number
  icon: ReactNode
  additional: {
    amount: string | number
    label: string
  }
  background?: string
  isLoading?: boolean
}

const StatisticsCard: FC<Props> = ({
  title,
  count,
  additional,
  icon,
  background = '',
  isLoading = false,
}) => {
  return (
    <Card sx={{ backgroundColor: background, position: 'relative' }}>
      {isLoading && <CardPreloader />}
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '1rem' }}
      >
        <Box
          sx={{
            //textAlign: 'right',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontWeight: 600, color: '#ffffff', fontSize: '1rem' }}
          >
            {title}
          </Typography>
          <Typography
            component="span"
            sx={{ fontSize: '2rem', fontWeight: 'bold', color: '#ffffff' }}
          >
            {count || 0}
          </Typography>
        </Box>
        <Box
          sx={{
            color: '#ffffff',
            '& svg': {
              fontSize: '2.5rem',
            },
          }}
        >
          {icon}
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: '0.5rem', px: '1rem', display: 'flex', alignItems: 'end' }}>
        <Typography component="p" variant="caption" sx={{ color: '#ffffff', fontSize: '0.875rem' }}>
          <Typography
            component="span"
            variant="caption"
            sx={{ color: '#ffffff', fontSize: '0.875rem' }}
          >
            {!!additional.amount && '+'}
            {additional.amount}
          </Typography>{' '}
          {additional.label}
        </Typography>
      </Box>
    </Card>
  )
}

export default StatisticsCard
