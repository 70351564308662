import { FC } from 'react'

import {
  Box,
  List,
  ListItem,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Theme,
} from '@mui/material'

import { DRAWER_WIDTH, MINI_DRAWER_WIDTH, APP_BAR_HEIGHT } from 'constants/main-layout'

import KeycloakService from 'services/KeycloakService'

import { ReactComponent as ConstructIcon } from 'assets/svg/sidebar/construct-outline.svg'
import { ReactComponent as LogoutIcon } from 'assets/svg/sidebar/logout.svg'

import MenuItem from '../SidebarMenuItem'
import { MenuItemType } from '../index'

interface Props {
  open: boolean
  menuItems: MenuItemType[]
  onClick: () => void
}

const SidebarMenu: FC<Props> = ({ open, menuItems, onClick }) => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  return (
    <Drawer
      sx={{
        width: open ? DRAWER_WIDTH : isSm ? '0' : MINI_DRAWER_WIDTH,
        flexShrink: 0,
        transition: '0.3s',
        '& .MuiDrawer-paper': {
          width: open ? DRAWER_WIDTH : isSm ? '0' : MINI_DRAWER_WIDTH,
          boxSizing: 'border-box',
          border: 'none',
          overflow: 'hidden',
          backgroundColor: '#DFDFDF',
          transition: '0.3s',
        },
      }}
      variant={isLg ? 'temporary' : 'permanent'}
      open={open}
      onClick={isLg ? onClick : undefined}
    >
      <Box
        sx={{
          pt: APP_BAR_HEIGHT,
        }}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <List sx={{ pt: 0 }}>
          {menuItems.map((item) => {
            return <MenuItem key={item.text} open={open} {...item} />
          })}
        </List>
        <List sx={{ marginBottom: '1rem' }}>
          <MenuItem open={open} text="Support" icon={<ConstructIcon />} link="/support" />
          <ListItem component="nav" disablePadding>
            <ListItemButton
              onClick={() => KeycloakService.doLogout()}
              sx={{
                height: '3rem',
                justifyContent: open ? 'initial' : 'center',
                px: '1.25rem',
                color: '#262626',
                '&:hover': { color: '#262626' },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? '1rem' : 'auto',
                  justifyContent: 'center',
                  color: 'inherit',
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary="Log out"
                sx={{
                  fontSize: '0.875rem',
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  )
}

export default SidebarMenu
