import React, { FC, useState } from 'react'

import styles from './ShopCard.module.scss'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  Card,
  MenuItem,
  Menu,
  IconButton,
  Tooltip,
  CardActionArea,
  Typography,
} from '@mui/material'

import { showNotification } from 'utils/showNotification'

import { useDeleteShopMutation } from 'services/shops/api'
import { Shop } from 'services/shops/types'

import { ReactComponent as ExclamationIcon } from 'assets/svg/exclamation.svg'

import ConfirmModal from 'components/common/ConfirmModal'

interface Props {
  shop: Shop
  edit: (shop: Shop) => void
}

const ShopCard: FC<Props> = ({ shop, edit }) => {
  const [modalDeleteShop, setModalDeleteShop] = useState<boolean>(false)
  const [deleteShop, { isLoading }] = useDeleteShopMutation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(null)
  }

  const handelShopOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    window.open(shop.url, 'blank')
  }

  const confirmDelete = () => {
    deleteShop(shop.id)
      .unwrap()
      .then(() => {
        setModalDeleteShop(false)
        showNotification('ShopCard deleted successfully', 'success')
      })
      .catch(() => {
        setModalDeleteShop(false)
        showNotification('Some error occurred', 'error')
      })
  }

  return (
    <Card className={`${styles.shopItem} hoverCard`}>
      <CardActionArea
        href={shop.isApplicationInstalled ? `${shop.id}/products` : ''}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          p: '1.5rem',
          '&:hover': { bgcolor: '#FFFFFF' },
          '.MuiTouchRipple-child': {
            backgroundColor: '#EEEEEE',
          },
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Tooltip title={shop.name}>
            <Typography
              className={styles.title}
              component="span"
              sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                lineHeight: '1.6rem',
              }}
            >
              {shop.name}
            </Typography>
          </Tooltip>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {!shop.isApplicationInstalled && (
              <Tooltip title="You've uninstalled the app. If you wish to restore access to your app management features, simply navigate to the app store and reinstall the application">
                <Box sx={{ width: '1.25rem', height: '1.25rem' }}>
                  <ExclamationIcon />
                </Box>
              </Tooltip>
            )}
            <IconButton
              sx={{ maxWidth: '2rem', maxHeight: '2rem' }}
              aria-label="settings"
              onClick={handleClick}
            >
              <MoreVertIcon sx={{ color: '#636465FF' }} />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem
                sx={{ color: '#636465FF' }}
                onClick={(event) => {
                  event.preventDefault()
                  edit(shop)
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                sx={{ color: '#636465FF' }}
                onClick={(event) => {
                  event.preventDefault()
                  setModalDeleteShop(true)
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <img
          src="https://cplusdesign.lk/wp-content/uploads/2023/09/Mobile-Shop-Design-Ideas-6.jpg"
          alt="Site Image"
          className={styles.cardMedia}
        />
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
          <Typography
            className={styles.description}
            color="text.primary"
            onClick={(event) => event.stopPropagation()}
          >
            Link:{' '}
            <Typography
              component="span"
              sx={{ color: '#1771CC', textDecoration: 'underline' }}
              onClick={handelShopOpen}
            >
              {shop.url}
            </Typography>
          </Typography>
          <Typography className={styles.description} color="text.primary">
            Default Review Reward: ${shop.defaultReviewPrice}
          </Typography>
          <Tooltip title={shop.description}>
            <Typography className={styles.description} color="text.primary">
              Description: {shop.description}
            </Typography>
          </Tooltip>
        </Box>
      </CardActionArea>
      {modalDeleteShop && (
        <ConfirmModal
          open
          onConfirm={confirmDelete}
          loading={isLoading}
          onClose={() => setModalDeleteShop(false)}
        >
          <Typography
            sx={{
              fontSize: '1.25rem',
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            {`Are you sure you want to delete ${shop.name} shop and all its products?`}
          </Typography>
        </ConfirmModal>
      )}
    </Card>
  )
}

export default ShopCard
