import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Typography } from '@mui/material'

import { CLEVER_PAYMENT_URL } from 'constants/urls'

import { showNotification } from 'utils/showNotification'

import { useCreateInvoiceMutation } from 'services/coupons/api'
import { useGetShopsQuery } from 'services/shops/api'
import { useGetCWSystemUserInfoQuery, useGetCurrentUserQuery } from 'services/users/api'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'
import Select from 'components/common/Select'
import TextInput from 'components/common/TextInput'

import AmountInfoPopover from './AmountInfoPopover'
import { buyCouponSchema } from './validation'

interface Props {
  sellerId: string
  sellerName: string
  currencyCode: string
  maxAmount: number
  onClose: () => void
}

interface FormData {
  shopId: string
  value: number
}

const BuyCouponModal: FC<Props> = ({ sellerId, sellerName, currencyCode, maxAmount, onClose }) => {
  // const [createCoupon, { isLoading: isCreateLoading }] = useCreateCouponMutation()
  const [createInvoice, { isLoading: isCreateLoading }] = useCreateInvoiceMutation()
  const { data: shops, isLoading: shopsLoading } = useGetShopsQuery(
    { sellerId, itemsPerPage: 100 },
    { refetchOnMountOrArgChange: true }
  )
  const { data: systemUser, isLoading: systemUserLoading } = useGetCWSystemUserInfoQuery()
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery()

  const shopsToSelect = shops?.data.map((shop) => ({
    label: shop.name,
    value: shop.id,
  }))

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(buyCouponSchema),
    context: { maxAmount },
  })

  const onSubmit = (newCoupon: FormData) => {
    const shop = shopsToSelect?.find((shop) => shop.value === newCoupon.shopId)
    createInvoice({
      amount: newCoupon.value,
      currency: currencyCode,
      seller: systemUser?.username || '',
      redirectUrl: `${location.origin}/coupons`,
      operationType: 'payment',
      metadata: {
        shopId: newCoupon.shopId,
        shopName: shop?.label || '',
        reviewerId: currentUser?.id || '',
        sellerName: sellerName,
      },
    })
      .unwrap()
      .then((response) => {
        if (response.token) {
          window.location.href = `${CLEVER_PAYMENT_URL}?token=${response.token}`
        }
      })
      .catch(() => {
        showNotification('Some error occurred', 'error')
      })
  }

  return (
    <Modal
      open
      loading={shopsLoading || systemUserLoading || isCreateLoading || currentUserLoading}
      onClose={onClose}
      disableBackdropClick
      title="Choose a store"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ maxWidth: '34.5rem' }}
    >
      <Modal.Content>
        <Typography
          sx={{
            fontSize: '0.875rem',
            textAlign: 'center',
            marginBottom: '1rem',
            letterSpacing: '0.15px',
          }}
        >
          You need to select a store where you can redeem the coupon. The coupon amount should not
          be more than the total earned amount for one seller
        </Typography>
        <Select
          control={control}
          name="shopId"
          label="Name of Shop"
          errors={errors}
          options={shopsToSelect}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TextInput
            label="Amount, $"
            name="value"
            type="number"
            control={control}
            errors={errors}
            width="10rem"
          />
          <AmountInfoPopover maxAmount={maxAmount} />
        </Box>
      </Modal.Content>
      <Modal.Actions sx={{ display: 'flex', gap: '1rem' }}>
        <Button
          loading={shopsLoading || systemUserLoading || isCreateLoading}
          type="submit"
          fullWidth
        >
          Buy
        </Button>
        <Button
          loading={shopsLoading || systemUserLoading || isCreateLoading}
          onClick={onClose}
          variant="outlined"
          fullWidth
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default BuyCouponModal
