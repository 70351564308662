import { FC } from 'react'

import { Card, Box, Typography } from '@mui/material'

import Button from 'components/common/Button'

const Subscription: FC = () => {
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1.5rem',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
          <Typography
            variant="h6"
            sx={{
              color: 'text.primary',
              fontSize: '0.875rem',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            Subscription Details
          </Typography>
          <Typography
            component="div"
            variant="caption"
            sx={{
              color: 'text.primary',
              fontSize: '0.875rem',
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            To learn more about your subscription and manage your plan, please open your Shopify
            account, where you can select the store where the app is installed and check all the
            details.
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default Subscription
