import ReactGA from 'react-ga4'

import { GA_MEASUREMENT_ID } from 'constants/ga-measurement-id'

const init = () => {
  if (GA_MEASUREMENT_ID) {
    ReactGA.initialize(GA_MEASUREMENT_ID)
  }
}

const event = (category: string, action: string, label?: string) => {
  if (GA_MEASUREMENT_ID) {
    ReactGA.event({ category: category, action: action, label: label })
  }
}

const googleAnalyticsService = {
  init,
  event,
}

export default googleAnalyticsService
