import axios from 'axios'
import React from 'react'

import { CountPayload, CountResponse } from 'types/Count'
import { TableServerResponse } from 'types/Table'

import { MAIN_API_URL } from 'constants/urls'

import { baseApi } from 'api/api'

import KeycloakService from 'services/KeycloakService'

import {
  Review,
  ListReviewsRecord,
  GetReviewsPayload,
  ReviewHistoryRecord,
  Purchase,
} from './types'

const PERCENTS = 100

export const reviewApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getReviews: build.query<TableServerResponse<ListReviewsRecord[]>, GetReviewsPayload>({
      query: (params) => ({
        url: `/reviews`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'Reviews', id: 'LIST' }],
    }),
    getPurchases: build.query<TableServerResponse<Purchase[]>, GetReviewsPayload>({
      query: (data) => ({
        url: '/reviews/list_purchases',
        method: 'GET',
        params: data,
      }),
      providesTags: () => [{ type: 'Reviews', id: 'LIST' }],
    }),
    getReviewsCount: build.query<CountResponse, CountPayload & { status?: number }>({
      query: (params) => ({
        url: `/reviews/count`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'Reviews', id: 'COUNT' }],
    }),
    getReviewById: build.query<Review, string>({
      query: (id) => ({
        url: `/reviews/${id}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'ReviewItemResponse' }],
    }),
    getHistoryRecordByReview: build.query<TableServerResponse<ReviewHistoryRecord[]>, string>({
      query: (id) => ({
        url: `/reviews/${id}/history`,
        method: 'GET',
        providesTags: [{ type: 'Reviews', id: 'LIST' }, { type: 'ReviewItemResponse' }],
      }),
    }),

    //seller methods
    approveReview: build.mutation<Review, string>({
      query: (id) => ({
        url: `/reviews/${id}/approve`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Reviews', id: 'LIST' }, { type: 'ReviewItemResponse' }, 'Balance'], //TODO: add ids for refresh
    }),
    rejectReview: build.mutation<Review, { id: string; message: string }>({
      query: ({ id, message }) => ({
        url: `/reviews/${id}/reject`,
        method: 'POST',
        data: { message },
      }),
      invalidatesTags: [{ type: 'Reviews', id: 'LIST' }, { type: 'ReviewItemResponse' }, 'Balance'], //TODO: add ids for refresh
    }),
    returnReview: build.mutation<Review, { id: string; message: string }>({
      query: ({ id, message }) => ({
        url: `/reviews/${id}/return`,
        method: 'POST',
        data: { message },
      }),
      invalidatesTags: [{ type: 'Reviews', id: 'LIST' }, { type: 'ReviewItemResponse' }, 'Balance'], //TODO: add ids for refresh
    }),
    updateReviewPrice: build.mutation<Review, { id: string; price: number }>({
      query: ({ id, price }) => ({
        url: `/reviews/${id}/update_price`,
        method: 'POST',
        data: { price },
      }),
      invalidatesTags: [{ type: 'Reviews', id: 'LIST' }, { type: 'ReviewItemResponse' }], //TODO: add ids for refresh
    }),
    //reviewer methods
    saveReviewAsDraft: build.mutation<
      Promise<{ data: Review | { error: object } }>,
      {
        id: string
        formData: FormData
        setUploadProgress: React.Dispatch<React.SetStateAction<number>>
      }
    >({
      queryFn: ({ id, formData, setUploadProgress }) => {
        try {
          const res = axios.post(`${MAIN_API_URL}/reviews/${id}/save_draft`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${KeycloakService.getToken()}`,
            },
            onUploadProgress: (upload) => {
              if (!upload.total) return
              setUploadProgress(Math.floor((PERCENTS * upload.loaded) / upload.total))
            },
          })

          return { data: res }
        } catch (error) {
          return { error }
        }
      },
      invalidatesTags: [{ type: 'Reviews', id: 'LIST' }, { type: 'ReviewItemResponse' }], //TODO: add ids for refresh
    }),
    sendReviewForModeration: build.mutation<
      Promise<{ data: Review | { error: object } }>,
      {
        id: string
        formData: FormData
        setUploadProgress: React.Dispatch<React.SetStateAction<number>>
      }
    >({
      queryFn: ({ id, formData, setUploadProgress }) => {
        try {
          const res = axios.post(`${MAIN_API_URL}/reviews/${id}/send_for_moderation`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${KeycloakService.getToken()}`,
            },
            onUploadProgress: (upload) => {
              if (!upload.total) return
              setUploadProgress(Math.floor((PERCENTS * upload.loaded) / upload.total))
            },
          })
          return { data: res }
        } catch (error) {
          return { error }
        }
      },
      invalidatesTags: [{ type: 'Reviews', id: 'LIST' }, { type: 'ReviewItemResponse' }],
    }),
  }),
})

export const {
  useGetReviewsQuery,
  useGetPurchasesQuery,
  useGetReviewsCountQuery,
  useGetReviewByIdQuery,
  useGetHistoryRecordByReviewQuery,
  useUpdateReviewPriceMutation,
  useSaveReviewAsDraftMutation,
  useSendReviewForModerationMutation,
  useApproveReviewMutation,
  useReturnReviewMutation,
  useRejectReviewMutation,
} = reviewApi
