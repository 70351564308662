import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import { AxiosError, AxiosRequestConfig } from 'axios'

import { axiosApi } from './index'

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async (params) => {
    try {
      const result = await axiosApi(params)
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      // console.log('err: ', err)
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: [
    'Shops',
    'Products',
    'Invites',
    'Reviews',
    'ReviewItemResponse',
    'Admins',
    'Received',
    'Coupons',
    'Sales',
    'Feedback',
    'Balance',
    'CurrentUser',
    'SubscriptionDetails'
  ],
})
