import { FC, useCallback, useState } from 'react'
import { ErrorCode, FileRejection, useDropzone, FileError } from 'react-dropzone'

import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { BYTES_IN_KBYTES, KBYTES_IN_MBYTES } from 'constants/file-size'

import { ReactComponent as AddVideoIcon } from 'assets/svg/addVideo.svg'

interface VideoDropzoneProps {
  file: File | null
  setFile: (file: File | null) => void
  acceptedFormats: { [key: string]: string[] }
  maxSize: number
  wrapperSx?: SxProps
}

const VideoDropzone: FC<VideoDropzoneProps> = ({
  file,
  setFile,
  acceptedFormats,
  maxSize,
  wrapperSx,
}) => {
  const [fileErrors, setFileErrors] = useState<string[] | null>(null)

  const onDropRejected = (fileRejections: FileRejection[]) => {
    setFileErrors(null)

    const errorsSet = new Set<string>()

    if (fileRejections.length > 1) {
      errorsSet.add('Only 1 file can be added')
    }

    fileRejections.forEach((rejection) => {
      const isSizeError = rejection.errors.some(
        (error: FileError) => error.code === ErrorCode.FileTooLarge
      )
      if (isSizeError) {
        errorsSet.add('This file is larger than 256MB')
      }

      const isTypeError = rejection.errors.some(
        (error: FileError) => error.code === ErrorCode.FileInvalidType
      )
      if (isTypeError) {
        errorsSet.add('Only the following formats can be added: MP4')
      }
    })

    setFileErrors(Array.from(errorsSet))
  }

  const onDrop = useCallback(
    (acceptedFiles: File) => {
      setFileErrors(null)
      setFile(acceptedFiles)
    },
    [file]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: acceptedFormats,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: maxSize,
    onDrop: (acceptedFiles) => onDrop(acceptedFiles[0]),
    onDropRejected,
  })

  const calculateMaxSize = maxSize / (BYTES_IN_KBYTES * KBYTES_IN_MBYTES)

  return (
    <Box sx={{ height: '100%', width: '100%', ...wrapperSx }}>
      <Box
        {...getRootProps()}
        onClick={open}
        sx={{
          bgcolor: '#F7F5F7',
          overflow: 'hidden',
          cursor: 'pointer',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '0.25rem',
          border: '2px solid #D0D0D0',
          p: '2rem',
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: 'flex',
            gap: '1.5rem',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <AddVideoIcon />

          <Box>
            <Typography
              sx={{ fontFamily: '"DM Mono", monospace', fontSize: '1.125rem', fontWeight: 500 }}
            >
              Drag&Drop video here or click to upload
            </Typography>
            <Box
              sx={{
                fontFamily: '"DM Mono", monospace',
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'text.secondary',
              }}
            >
              Maximum file size allowed is {calculateMaxSize}Mb
              <br />
              Files Supported: MP4
            </Box>
          </Box>
        </Box>
      </Box>

      {fileErrors && (
        <Box sx={{ mt: '0.5rem' }}>
          {fileErrors.map((error, index) => (
            <Typography key={index} variant="body2" color="error">
              {error}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default VideoDropzone
