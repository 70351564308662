import { format } from 'date-fns'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import DiscountIcon from '@mui/icons-material/Discount'
import ReviewsIcon from '@mui/icons-material/Reviews'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import VerifiedIcon from '@mui/icons-material/Verified'
import Grid from '@mui/material/Grid'
import { teal } from '@mui/material/colors'

import { reviewStatusStyles } from 'constants/reviewStatusRender'
import { DAYS_IN_WEEK, HOURS_IN_DAY, MINS_IN_HOUR, MSECS_IN_SEC, SECS_IN_MIN } from 'constants/time'

import { useGetCouponsCountQuery } from 'services/coupons/api'
import { CouponStatus } from 'services/coupons/types'
import { useGetReviewsCountQuery, useGetReviewsQuery } from 'services/reviews/api'
import { ReviewStatus } from 'services/reviews/types'

import PageHeader from 'components/common/PageHeader'
import LatestReviews from 'components/dashboard/LatestReviews'
import StatisticsCard from 'components/dashboard/StatisticsCards'

import LatestPayments from '../LatestPayments'

const ReviewerDashboard: FC = () => {
  const { data: latestReviews, isLoading: isLatestReviewsLoading } = useGetReviewsQuery(
    {
      page: 0,
      itemsPerPage: 5,
      sortField: 'updatedAt',
      sortDir: 'desc',
    },
    { refetchOnMountOrArgChange: true }
  )

  const { data: reviewsCountTotal, isLoading: isReviewsCountTotalLoading } =
    useGetReviewsCountQuery({}, { refetchOnMountOrArgChange: true })
  const { data: reviewsCountLastWeek, isLoading: isReviewsCountLastWeekLoading } =
    useGetReviewsCountQuery(
      {
        startDate: format(
          new Date(
            Date.now() - MSECS_IN_SEC * SECS_IN_MIN * MINS_IN_HOUR * HOURS_IN_DAY * DAYS_IN_WEEK
          ),
          'yyyy-MM-dd'
        ),
        endDate: format(Date.now(), 'yyyy-MM-dd'),
      },
      { refetchOnMountOrArgChange: true }
    )
  const { data: returnedReviewsCountTotal, isLoading: isReturnedReviewsCountTotalLoading } =
    useGetReviewsCountQuery(
      {
        status: ReviewStatus.RETURNED,
      },
      { refetchOnMountOrArgChange: true }
    )
  const { data: newReviewsCountTotal, isLoading: isNewReviewsCountTotalLoading } =
    useGetReviewsCountQuery({ status: ReviewStatus.NEW }, { refetchOnMountOrArgChange: true })
  const { data: newReviewsCountLastWeek, isLoading: isNewReviewsCountLastWeekLoading } =
    useGetReviewsCountQuery(
      {
        startDate: format(
          new Date(
            Date.now() - MSECS_IN_SEC * SECS_IN_MIN * MINS_IN_HOUR * HOURS_IN_DAY * DAYS_IN_WEEK
          ),
          'yyyy-MM-dd'
        ),
        endDate: format(Date.now(), 'yyyy-MM-dd'),
        status: ReviewStatus.NEW,
      },
      { refetchOnMountOrArgChange: true }
    )
  const { data: activeCoupons, isLoading: isActiveCouponsLoading } = useGetCouponsCountQuery(
    { isReviewer: 1 },
    { refetchOnMountOrArgChange: true }
  )

  const { data: latestPayments, isLoading: isLatestPaymentsLoading } = useGetReviewsQuery(
    {
      page: 0,
      itemsPerPage: 3,
      sortField: 'updatedAt',
      sortDir: 'desc',
      statuses: [ReviewStatus.APPROVED.toString()],
    },
    { refetchOnMountOrArgChange: true }
  )

  return (
    <>
      <PageHeader title="Dashboard" />
      <Grid container spacing={3} sx={{ mb: '1.5rem' }}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={6}>
              <Link
                className="hoverCard"
                to={`/my-reviews?statuses=${ReviewStatus.RETURNED}`}
                style={{ textDecoration: 'none' }}
              >
                <StatisticsCard
                  icon={<VerifiedIcon />}
                  title="Returned Reviews"
                  count={returnedReviewsCountTotal?.count || 0}
                  additional={{
                    amount: '',
                    label: 'Requires your attention',
                  }}
                  background={reviewStatusStyles[ReviewStatus.RETURNED].color}
                  isLoading={isReturnedReviewsCountTotalLoading}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Link className="hoverCard" to="/purchases" style={{ textDecoration: 'none' }}>
                <StatisticsCard
                  icon={<ShoppingCartIcon />}
                  title="Purchases"
                  count={newReviewsCountTotal?.count || 0}
                  additional={{
                    amount: newReviewsCountLastWeek?.count || 0,
                    label: 'for the last week',
                  }}
                  background={teal[500]}
                  isLoading={isNewReviewsCountTotalLoading || isNewReviewsCountLastWeekLoading}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Link className="hoverCard" to="/my-reviews" style={{ textDecoration: 'none' }}>
                <StatisticsCard
                  icon={<ReviewsIcon />}
                  title="Total Reviews"
                  count={reviewsCountTotal?.count || 0}
                  additional={{
                    amount: reviewsCountLastWeek?.count || 0,
                    label: 'for the last week',
                  }}
                  background={reviewStatusStyles[ReviewStatus.APPROVED].color}
                  isLoading={isReviewsCountTotalLoading || isReviewsCountLastWeekLoading}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Link
                className="hoverCard"
                to={`/coupons/list?statuses=${CouponStatus.ACTIVE}`}
                style={{ textDecoration: 'none' }}
              >
                <StatisticsCard
                  icon={<DiscountIcon />}
                  title="Active Coupons"
                  count={activeCoupons?.count || 0}
                  additional={{
                    amount: '',
                    label: 'Use your coupons!',
                  }}
                  background={reviewStatusStyles[ReviewStatus.DRAFT].color}
                  isLoading={isActiveCouponsLoading}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <LatestPayments
            reviews={latestPayments?.data || []}
            isLoading={isLatestPaymentsLoading}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <LatestReviews
            dashboard="REVIEWER"
            reviews={latestReviews?.data || []}
            isLoading={isLatestReviewsLoading}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ReviewerDashboard
