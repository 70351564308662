import { ReactNode, ReactEventHandler } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogContentProps,
  DialogActions,
  DialogActionsProps,
  Box,
  CircularProgress,
  IconButton,
  SxProps,
  Theme,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  open: boolean
  children: ReactNode
  top?: string
  disableBackdropClick?: boolean
  loading?: boolean
  title?: ReactNode
  showCloseButton?: boolean
  onClose: () => void
  onSubmit?: () => void
  titleAlign?: 'left' | 'center'
  titleStyles?: SxProps
}

const styles = {
  paperProps: {
    'MuiPaper-root-MuiDialog-paper': {
      width: '100%',
      m: 0,
    },
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    width: '100%',
    maxHeight: '100dvh',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '1.25rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    lineHeight: '1.6rem',
    textOverflow: 'ellipsis',
    p: '1.5rem 1.5rem 0',
    textWrap: 'wrap',
  },
  closeButton: {
    position: 'absolute',
    right: '0.625rem',
    top: '0.625rem',
    cursor: 'pointer',
    color: '#666666',
    zIndex: 9000,
    '&:hover': {
      color: '#000000',
    },
  },
  loader: {
    wrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,.3)',
      zIndex: 9999,
    },
    progress: {
      position: 'absolute',
      top: 'calc(50% - 1.25rem)',
      left: 'calc(50% - 1.25rem)',
      color: '#FFFFFF',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    gap: '1rem',
    p: '0 1.5rem',
    overflowY: 'auto',
    maxHeight: 'calc(100dvh - 300px)',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    gap: '1rem',
    pt: '0.5rem',
  },
  actions: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    p: '0 1.5rem 1.5rem',
  },
  mobile: {
    main: {
      // maxWidth: '100%',
      // height: '100vh',
      // maxHeight: '100%',
      // margin: 0,
    },
    actions: {
      pb: '1rem',
    },
  },
} as const

const mobileWidth = 'sm'

const Modal = ({
  children,
  disableBackdropClick = false,
  open,
  onClose,
  onSubmit,
  loading = false,
  showCloseButton = true,
  title,
  titleAlign = 'center',
  titleStyles = {},
  sx,
  ...rest
}: Props & DialogProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(mobileWidth))
  const handlerClose = (event: ReactEventHandler, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return
    }
    onClose()
  }
  return (
    <Dialog
      component={onSubmit ? 'form' : 'div'}
      open={open}
      onClose={handlerClose}
      onSubmit={onSubmit}
      sx={{
        color: 'text.primary',
        m: '1rem',
      }}
      PaperProps={{
        sx: { ...styles.paperProps, ...sx, ...(isMobile ? styles.mobile.main : {}) },
      }}
      {...rest}
    >
      {loading && (
        <Box sx={styles.loader.wrapper}>
          <CircularProgress sx={styles.loader.progress} />
        </Box>
      )}
      {showCloseButton && (
        <IconButton onClick={onClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      )}
      {title && (
        <DialogTitle sx={{ textAlign: titleAlign, ...styles.title, ...titleStyles }}>
          {title}
        </DialogTitle>
      )}
      {children}
    </Dialog>
  )
}

const Content = ({ children, sx, ...rest }: DialogContentProps) => {
  return (
    <DialogContent sx={{ ...styles.content, ...sx }} {...rest}>
      <Box sx={{ ...styles.contentWrapper }}>{children}</Box>
    </DialogContent>
  )
}

Modal.Content = Content

const Actions = ({ children, sx, ...rest }: DialogActionsProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(mobileWidth))
  return (
    <DialogActions
      sx={{
        ...styles.actions,
        ...(isMobile ? styles.mobile.actions : {}),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </DialogActions>
  )
}
Modal.Actions = Actions

export default Modal