import { FC, useState } from 'react'

import { Box, Tooltip, Typography } from '@mui/material'

import { showNotification } from 'utils/showNotification'

import { useGetBalanceQuery } from 'services/balance/api'
import {
  useApproveReviewMutation,
  useRejectReviewMutation,
  useReturnReviewMutation,
} from 'services/reviews/api'
import { ReviewStatus, ModerationStatus } from 'services/reviews/types'

import Button from 'components/common/Button'
import ConfirmModal from 'components/common/ConfirmModal'

import ModerationReviewModal from '../ModerationReviewModal'
import { modalHeaders } from '../ModerationReviewModal/modalHeaders'

interface Props {
  reviewId: string
  productName: string
  reviewPrice: number
}

const ReviewActions: FC<Props> = ({ reviewId, productName, reviewPrice }) => {
  const [confirmModalShow, setConfirmModalShow] = useState(false)
  const [moderationModalShow, setModerationModalShow] = useState(false)

  const [status, setStatus] = useState<ModerationStatus>(ReviewStatus.APPROVED)
  const [reason, setReason] = useState<string>('')

  const [approveReview, { isLoading: isApproveLoading }] = useApproveReviewMutation()
  const [returnReview, { isLoading: isReturnLoading }] = useReturnReviewMutation()
  const [rejectReview, { isLoading: isRejectLoading }] = useRejectReviewMutation()

  const { data: balanceData } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const onActionSelect = (status: ModerationStatus) => {
    setStatus(status)
    setReason('')
    if (status === ReviewStatus.APPROVED) {
      setConfirmModalShow(true)
    } else {
      setModerationModalShow(true)
    }
  }

  const submitModeration = (reason: string) => {
    setReason(reason)
    setConfirmModalShow(true)
  }

  const confirmSave = async () => {
    try {
      if (status === ReviewStatus.APPROVED) {
        await approveReview(reviewId).unwrap()
        showNotification(
          'Please wait for payment confirmation before the review can be approved.',
          'success'
        )
        setConfirmModalShow(false)
      }
      if (status === ReviewStatus.RETURNED) {
        await returnReview({ id: reviewId, message: reason }).unwrap()
        showNotification('Review successfully returned', 'success')
        setModerationModalShow(false)
        setConfirmModalShow(false)
      }
      if (status === ReviewStatus.REJECTED) {
        await rejectReview({ id: reviewId, message: reason }).unwrap()
        showNotification('Review successfully rejected', 'success')
        setConfirmModalShow(false)
      }
    } catch (e: unknown) {
      showNotification('Failed to change review status. Try again later', 'error')
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', gap: '1rem', mt: '1.5rem', width: '100%' }}>
        {balanceData === undefined || balanceData.balance < reviewPrice ? (
          <Tooltip title='Please navigate to your "Finances" page and add funds to your account'>
            <Box sx={{ width: '33%' }}>
              <Button fullWidth disabled>
                Approve
              </Button>
            </Box>
          </Tooltip>
        ) : (
          <Button onClick={() => onActionSelect(ReviewStatus.APPROVED)} sx={{ width: '33%' }}>
            Approve
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={() => onActionSelect(ReviewStatus.RETURNED)}
          sx={{ width: '33%' }}
        >
          Return
        </Button>
        <Button
          variant="outlined"
          onClick={() => onActionSelect(ReviewStatus.REJECTED)}
          sx={{ width: '33%' }}
        >
          Reject
        </Button>
      </Box>
      {moderationModalShow && (
        <ModerationReviewModal
          reviewId={reviewId}
          productName={productName}
          status={status}
          submit={submitModeration}
          onClose={() => setModerationModalShow(false)}
        />
      )}
      {confirmModalShow && (
        <ConfirmModal
          open
          onConfirm={confirmSave}
          loading={isApproveLoading || isReturnLoading || isRejectLoading}
          importantCancel={status === ReviewStatus.REJECTED}
          onClose={() => setConfirmModalShow(false)}
        >
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 500,
              textAlign: 'center',
              letterSpacing: '0.2px',
              maxWidth: '21.937rem',
            }}
          >
            {modalHeaders[status].confirm}
          </Typography>
        </ConfirmModal>
      )}
    </>
  )
}

export default ReviewActions
