import { Box, Typography, Link } from '@mui/material'

import Modal from 'components/common/Modal'

import {
  POLICIES_TEXT_STYLES,
  POLICIES_LIST_STYLES,
  POLICIES_LIST_ITEM_STYLES,
  POLICIES_LIST_DOTE_STYLES,
  POLICIES_TITLE_STYLES,
} from 'pages/landings/cookiePolicy'
import { POLICIES_LIST_TITLE_STYLES } from 'pages/landings/termsAndConditions'

interface Props {
  onClose: () => void
}

export const TermsAndConditionsModal = ({ onClose }: Props) => {
  return (
    <Modal
      open
      onClose={onClose}
      title="Shopiview Terms and Conditions"
      titleAlign="left"
      titleStyles={{
        p: '1.5rem',
        fontWeight: 700,
      }}
      sx={{ maxWidth: '74.125rem', gap: 0 }}
    >
      <Modal.Content
        sx={{
          p: '0 1.5rem 1.5rem',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              1. Introduction
            </Typography>
            <Typography sx={{ ml: '1rem' }}>
              Welcome to Shopiview, a video review app developed by We Can Develop IT LLC
              (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;) exclusively for use on
              Shopify&apos;s platform. This Privacy Policy explains our data collection, usage, and
              sharing practices in line with U.S. privacy laws, particularly CPRA, and
              Shopify&apos;s Partner Program requirements.
            </Typography>
          </Box>
          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              2. Information We Collect
            </Typography>
            <Box sx={POLICIES_LIST_STYLES}>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Box>
                  <Typography sx={POLICIES_LIST_TITLE_STYLES}>Personal Information</Typography>
                  <Typography>
                    Upon installation and usage of Shopiview, we collect your personal information,
                    including name, email, and payment data, essential for providing app
                    functionality and support.
                  </Typography>
                </Box>
              </Box>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Box>
                  <Typography sx={POLICIES_LIST_TITLE_STYLES}>Usage Data</Typography>
                  <Typography>
                    Data like device type, IP address, and interaction details are gathered for user
                    experience optimization, security, and product improvement.
                  </Typography>
                </Box>
              </Box>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Box>
                  <Typography sx={POLICIES_LIST_TITLE_STYLES}>Cookies and Tracking</Typography>
                  <Typography>
                    Our app uses cookies to monitor user activity for analytics, enhance app
                    performance, and personalize user experience, respecting Shopify&apos;s
                    policies.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              3. How We Use Your Information
            </Typography>
            <Box sx={POLICIES_LIST_STYLES}>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Box>
                  <Typography sx={POLICIES_LIST_TITLE_STYLES}>
                    Service Delivery and Improvement
                  </Typography>
                  <Typography>
                    We use collected data to operate and refine the app per Shopify&apos;s
                    standards, enhancing app functionality and user engagement.
                  </Typography>
                </Box>
              </Box>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Box>
                  <Typography sx={POLICIES_LIST_TITLE_STYLES}>
                    Communications and Marketing
                  </Typography>
                  <Typography>
                    We may use your contact details to communicate updates, promotional materials,
                    and relevant notices, offering an opt-out option for non essential
                    communications.
                  </Typography>
                </Box>
              </Box>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Box>
                  <Typography sx={POLICIES_LIST_TITLE_STYLES}>Security and Compliance</Typography>
                  <Typography>
                    For compliance with Shopify&apos;s Acceptable Use Policy and Partner Program, we
                    monitor user activity to prevent fraud, protect data, and ensure adherence to
                    platform standards.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              4. Sharing Your Information
            </Typography>
            <Box sx={POLICIES_LIST_STYLES}>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Box>
                  <Typography sx={POLICIES_LIST_TITLE_STYLES}>Service Providers</Typography>
                  <Typography>
                    Only trusted third parties aiding app functionality have access to data, limited
                    strictly to operational tasks necessary for service delivery.
                  </Typography>
                </Box>
              </Box>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Box>
                  <Typography sx={POLICIES_LIST_TITLE_STYLES}>Legal Requirements</Typography>
                  <Typography>
                    Your data may be shared when legally required or when necessary to defend our
                    rights or meet Shopify&apos;s compliance obligations.
                  </Typography>
                </Box>
              </Box>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Box>
                  <Typography sx={POLICIES_LIST_TITLE_STYLES}>Business Transfers</Typography>
                  <Typography>
                    In the event of a merger, acquisition, or structural change, personal data may
                    transfer as part of the transaction, following applicable data privacy
                    safeguards.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              5. Data Security
            </Typography>
            <Typography sx={{ ml: '1rem' }}>
              We implement industry-standard security measures to protect data against unauthorized
              access, disclosure, and use. However, complete security cannot be guaranteed.
            </Typography>
          </Box>
          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              6. Data Retention
            </Typography>
            <Typography sx={{ ml: '1rem' }}>
              We retain your information as long as needed for operational, legal, and
              dispute-resolution purposes, generally no longer than seven years, to comply with
              Shopify&apos;s data retention requirements.
            </Typography>
          </Box>
          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              7. Your Rights (California Residents)
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography sx={POLICIES_TEXT_STYLES}>
                In compliance with CPRA, California residents have rights to:
              </Typography>
              <Box sx={POLICIES_LIST_STYLES}>
                <Box sx={POLICIES_LIST_ITEM_STYLES}>
                  <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                  <Typography>
                    <strong>Access</strong> their personal information.
                  </Typography>
                </Box>
                <Box sx={POLICIES_LIST_ITEM_STYLES}>
                  <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                  <Typography>
                    <strong>Request Deletion</strong> of personal data, subject to exceptions.
                  </Typography>
                </Box>
                <Box sx={POLICIES_LIST_ITEM_STYLES}>
                  <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                  <Typography>
                    <strong>Request Correction</strong> for inaccuracies.
                  </Typography>
                </Box>
                <Box sx={POLICIES_LIST_ITEM_STYLES}>
                  <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                  <Typography>
                    <strong>Opt-out</strong> of marketing communications.
                  </Typography>
                </Box>
              </Box>
              <Typography sx={POLICIES_LIST_STYLES}>
                To exercise these rights, contact us at{' '}
                <Link href="mailto: support@shopiview.com" sx={{ display: 'contents' }}>
                  support@shopiview.com
                </Link>
                .
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              8. Children&apos;s Privacy
            </Typography>
            <Typography sx={{ ml: '1rem' }}>
              The Shopiview app is not intended for individuals under 18. We do not knowingly
              collect or process data from minors.
            </Typography>
          </Box>
          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              9. Policy Updates
            </Typography>
            <Typography sx={{ ml: '1rem' }}>
              We reserve the right to update this Policy to reflect changes in practices or legal
              requirements. Updates will be posted on our app&apos;s page, and continued use
              signifies acceptance.
            </Typography>
          </Box>

          <Box>
            <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
              10. Contact Information
            </Typography>
            <Typography sx={{ ml: '1rem' }}>
              For questions or concerns regarding this Privacy Policy, please contact us at:
            </Typography>
            <Typography sx={{ ml: '1rem' }}>
              We Can Develop IT LLC 12000 Ancient Crest Circle, Apt 12107 Franklin, Tennessee, 37067
            </Typography>
            <Typography component="p" sx={{ ml: '1rem' }}>
              Email:{' '}
              <Link href="mailto: support@shopiview.com" sx={{ display: 'contents' }}>
                support@shopiview.com
              </Link>
              .
            </Typography>
          </Box>
        </Box>
      </Modal.Content>
    </Modal>
  )
}
