import { baseApi } from 'api/api'

import { CheckInviteResponse, CreateInviteResponse } from './types'

export const invitesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    activateInvite: build.mutation<CreateInviteResponse, { token: string }>({
      query: (data) => ({
        url: '/invites/activate',
        method: 'POST',
        data
      }),
      invalidatesTags: [{ type: 'Reviews', id: 'LIST' }],
    }),
    checkInvite: build.query<CheckInviteResponse, { token: string }>({
      query: (params) => ({
        url: '/invites/check',
        method: 'GET',
        params
      }),
      //invalidatesTags: [{ type: 'Invites', id: 'LIST' }],
    }),
  }),
})

export const { useActivateInviteMutation, useCheckInviteQuery } = invitesApi
