import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Typography } from '@mui/material'

import { ReviewStatus, ModerationStatus } from 'services/reviews/types'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'
import TextInput from 'components/common/TextInput'

import { modalHeaders } from './modalHeaders'
import { reviewModerationSchema } from './validation'

interface Props {
  reviewId: string
  productName: string
  status: ModerationStatus
  submit: (reason: string) => void
  onClose: () => void
}

interface FormInputs {
  message: string
}

const ModerationReviewModal: FC<Props> = ({ productName, status, onClose, submit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(reviewModerationSchema),
  })

  const onSubmit = ({ message }: FormInputs) => {
    submit(message)
  }

  const handleClose = () => onClose()

  return (
    <Modal
      open
      title={modalHeaders[status].header}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => handleClose()}
      sx={{ maxWidth: '34.5rem' }}
    >
      <Modal.Content>
        {status === ReviewStatus.REJECTED && (
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '1.125rem',
              fontWeight: 600,
              color: '#EA1616',
              pb: '2rem',
            }}
          >
            Rejecting this video review will not allow a user to edit current video
          </Typography>
        )}
        <Typography
          sx={{ fontSize: '1.125rem', fontWeight: 600, lineHeight: '1.361rem', pb: '1rem' }}
        >
          Product Name: {productName}
        </Typography>
        <Box>
          <TextInput
            label="Comments"
            name="message"
            control={control}
            errors={errors}
            multiline
            rows={8}
            placeholder="Please highlight the reason for return (up to 150 characters)"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Modal.Content>
      <Modal.Actions>
        {status === ReviewStatus.REJECTED ? (
          <>
            <Button type="submit" variant="outlined" fullWidth>
              Send
            </Button>
            <Button onClick={handleClose} fullWidth>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleClose} variant="outlined" fullWidth>
              Cancel
            </Button>
            <Button type="submit" fullWidth>
              Send
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default ModerationReviewModal
