import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import NavigationTabs, { TabItem } from 'components/common/NavigationTabs'

const tabs: TabItem[] = [
  { id: '', label: 'All Shops' },
  { id: 'all-products', label: 'All Products' },
  { id: 'sales', label: 'Sales' },
  { id: 'all-reviews', label: 'All Reviews' },
]

const ShopsTabs: FC = () => {
  return (
    <Box>
      <NavigationTabs basePath={`/shops`} tabs={tabs} />
      <Box sx={{ mt: '2.5rem' }}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default ShopsTabs
