import { FC, useState } from 'react'

import { Box, Typography } from '@mui/material'

import { showNotification } from 'utils/showNotification'

import { useGetBalanceQuery, useEmissionMutation } from 'services/balance/api'

import BalanceTopUpModal from 'components/balance/BalanceTopUpModal'
import Button from 'components/common/Button'
import Preloader from 'components/common/Preloader'
import SellerCouponsHistory from 'components/coupons/SellerCouponsHistory'

const MIN_BALANCE = 400
const BALANCE_UP = '100'

const Balance: FC = () => {
  const [modalShow, setModalShow] = useState(false)

  const { data, isLoading, isError } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const [emission, { isLoading: isUpdateLoading }] = useEmissionMutation()
  const BalanceTopUp = () => {
    emission({ amount: BALANCE_UP, description: 'Balance top up' })
      .unwrap()
      .then(() => {
        setModalShow(true)
      })
      .catch((e) => {
        showNotification('Some error occurred', 'error')
      })
  }

  const handlerModalClose = () => {
    setModalShow(false)
  }

  if (isLoading || !data) {
    return <Preloader />
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1rem' }}>
        <Box>
          <Typography component="span" sx={{ fontWeight: '700', fontSize: '1.5rem', mr: '1rem' }}>
            Balance:
          </Typography>
          <Typography
            component="span"
            sx={{ fontWeight: '700', letterSpacing: '0.2px', fontSize: '2.25rem' }}
          >
            {!isError ? `$${data.balance}` : 'not available yet'}
          </Typography>
        </Box>
        {data.balance > 0 && (
          <Button href={`${process.env.REACT_APP_CW_UI_URL}`}>Online Wallet</Button>
        )}
      </Box>
      {data.balance < MIN_BALANCE ? (
        <Typography>
          In order to receive money, click on the &quot;Add Money&quot; for further use on the
          service to pay for reviews for your products
        </Typography>
      ) : (
        <Typography>
          The maximum amount shouldn&apos;t exceed $500, so you won&apos;t be able to add more money
          beyond this limit.
        </Typography>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', mt: '1rem', mb: '3.5rem' }}>
        <Typography
          component="span"
          sx={{
            border: '1px solid #DFDFDF',
            backgroundColor: '#ffffff',
            padding: '0.625rem 3rem',
            borderRadius: '0.25rem',
            mr: '1rem',
            fontSize: '1.5rem',
            lineHeight: '1.75rem',
            fontWeight: 500,
          }}
        >
          $100
        </Typography>
        <Button
          loading={isUpdateLoading}
          onClick={BalanceTopUp}
          disabled={isError || data.balance > MIN_BALANCE}
        >
          Add Money
        </Button>
      </Box>
      {modalShow && <BalanceTopUpModal onClose={handlerModalClose} />}
      <SellerCouponsHistory />
    </>
  )
}

export default Balance
