import { FC } from 'react'

import { Box, Typography } from '@mui/material'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'

interface Props {
  onClose: () => void
}

const BalanceTopUpModal: FC<Props> = ({ onClose }) => {
  return (
    <Modal open onClose={onClose} sx={{ maxWidth: '34.5rem' }}>
      <Modal.Content sx={{ p: '0 3.25rem', justifyContent: 'center' }}>
        <Box
          sx={{
            width: '100%',
            p: '3.25rem 0 0',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            textAlign="center"
            sx={{ fontSize: '1rem', color: '#141414', fontWeight: 500 }}
          >
            The balance has been successfully topped up. <br />
            For further use of gift money, go to the &quot;All reviews&quot; tab
          </Typography>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button href="/shops/all-reviews" fullWidth>
          All reviews
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default BalanceTopUpModal
