import React, { FC } from 'react'
import { Box, CircularProgress } from '@mui/material'

interface Props {
  parentHeight?: string
}

const Preloader: FC<Props> = ({ parentHeight }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ height: parentHeight ?? 'calc(100vh - 4rem)' }}
    >
      <CircularProgress color="inherit" />
    </Box>
  )
}

export default Preloader
