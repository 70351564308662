import { FC, Dispatch, SetStateAction, memo } from 'react'

import { LinearProgress } from '@mui/material'
import { DataGrid, GridColDef, GridSortModel, GridRowParams } from '@mui/x-data-grid'

import { Sort, Pagination } from 'types/Table'

import { ReactComponent as CaretDownIcon } from 'assets/svg/caret-down-outline.svg'
import { ReactComponent as CaretUpIcon } from 'assets/svg/caret-up-outline.svg'

import NoDataInDataGridTable from '../NoDataInTable'

interface Props {
  data: any
  columns: GridColDef[]
  loading: boolean
  paginationModel: Pagination
  setPagination: Dispatch<SetStateAction<Pagination>>
  setSort?: Dispatch<SetStateAction<Sort>>
  initSort?: Sort
  rowHeight?: number
  rowClick?: (params: GridRowParams) => void
  isFiltered?: boolean
}

const PAGE_SIZE_OPTION_10 = 10

const CustomDataGrid: FC<Props> = ({
  data,
  columns,
  loading,
  paginationModel,
  setPagination,
  setSort,
  initSort = { sortField: '', sortDir: null },
  rowHeight = 49,
  rowClick,
  isFiltered = false
}) => {
  return (
    <DataGrid
      rows={data?.data || []}
      columns={columns}
      disableColumnMenu={true}
      rowSelection={true}
      loading={loading}
      sortingMode="server"
      paginationMode="server"
      rowCount={data?.meta?.totalItems || 0}
      paginationModel={{ page: paginationModel.page - 1, pageSize: paginationModel.itemsPerPage }}
      pagination
      onPaginationModelChange={(newPaginationModel) => {
        setPagination({
          page: newPaginationModel.page + 1,
          itemsPerPage: newPaginationModel.pageSize,
        })
      }}
      onSortModelChange={(sortModel: GridSortModel) => {
        if (setSort && sortModel.length) {
          setSort({
            sortField: sortModel[0].field,
            sortDir: sortModel[0].sort,
          })
        }
      }}
      initialState={{
        pagination: {
          paginationModel: {},
        },
        sorting: {
          sortModel: [
            { field: initSort.sortField, sort: initSort.sortDir ? initSort.sortDir : 'asc' },
          ],
        },
      }}
      slots={{
        loadingOverlay: LinearProgress,
        noRowsOverlay: () => <NoDataInDataGridTable isFiltered={isFiltered}/>,
        columnSortedAscendingIcon: CaretUpIcon,
        columnSortedDescendingIcon: CaretDownIcon,
      }}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      pageSizeOptions={[PAGE_SIZE_OPTION_10]}
      rowHeight={rowHeight}
      onRowClick={(params: GridRowParams) => {
        if (rowClick) {
          rowClick(params)
        }
      }}
      sx={{
        borderColor: '#828282',
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#DFDFDF',
        },
        '& .MuiDataGrid-columnHeader': {
          borderRight: '1px solid #F7F5F7',
        },
        '& .MuiDataGrid-columnHeader:last-child': {
          borderRight: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontSize: '1rem',
        },
        '& .MuiDataGrid-row.even': {
          backgroundColor: '#F7F5F7',
        },
        '& .MuiDataGrid-row.odd': {
          backgroundColor: '#FFFFFF',
        },
        '& .MuiDataGrid-cell': {
          borderColor: '#DFDFDF',
        },
        '& .MuiDataGrid-iconButtonContainer': {
          position: 'absolute',
          right: '0px',
        },
        '& .MuiDataGrid-cellContent': {
          color: '#121619',
        },
        '& .MuiDataGrid-sortIcon': {
          opacity: 'inherit',
        },
      }}
    />
  )
}

export default memo(CustomDataGrid)
