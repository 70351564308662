import { FC } from 'react'

import { Box, Typography } from '@mui/material'

import { ReactComponent as NoData } from 'assets/svg/noData.svg'

interface Props {
  isFiltered?: boolean

  text: string
}

const EmptyState: FC<Props> = ({ isFiltered = false, text }) => {
  return (
    <Box
      sx={{
        pt: '2.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <NoData />
      <Typography sx={{ color: 'text.secondary', fontSize: '1rem', textAlign: 'center' }}>
        {isFiltered ? 'Nothing was found for your request.' : text}
      </Typography>
    </Box>
  )
}

export default EmptyState
