import { FC, useState } from 'react'

import { Box } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetShopsQuery } from 'services/shops/api'
import { Shop } from 'services/shops/types'

import EmptyState from 'components/common/EmptyState'
import Pagination from 'components/common/Pagination'
import Preloader from 'components/common/Preloader'
import SearchInput from 'components/common/SearchInput'
import EditShopModal from 'components/shops/EditShopModal'
import ShopCard from 'components/shops/ShopCard'

const Shops: FC = () => {
  const {
    queryParams,
    isFiltered,
    pagination,
    search,
    shopName,
    setPagination,
    setSearch,
    setShopName,
  } = useDataGridPagination()
  const [editShop, setEditShop] = useState<Shop | null>(null)
  const [modalShow, setModalShow] = useState(shopName ? true : false)

  const {
    data: shops,
    isLoading,
    isFetching,
  } = useGetShopsQuery(queryParams, { refetchOnMountOrArgChange: true })

  const handleEdit = (shop: Shop) => {
    setEditShop(shop)
    setModalShow(true)
  }

  const handleModalClose = () => {
    setEditShop(null)
    setModalShow(false)
    setShopName('')
  }

  return (
    <Box height={'100dvh'}>
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: '1rem', mb: '1.5rem' }}>
        <SearchInput initValue={search} onSearchChange={setSearch} />
      </Box>
      {!shops?.data.length && (
        <EmptyState isFiltered={isFiltered} text={"You haven't added any shops yet."} />
      )}
      {isLoading || isFetching ? (
        <Preloader />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mb: '2.5rem',
            gap: '1rem',
            justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
          }}
        >
          {shops?.data.map((shop) => (
            <Box key={shop.id}>
              <ShopCard shop={shop} edit={handleEdit} />
            </Box>
          ))}
        </Box>
      )}
      {!!shops?.meta.totalItems && (
        <Box display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(shops.meta.totalItems / shops.meta.itemsPerPage) || 1}
            page={pagination.page || 1}
            onChange={setPagination}
          />
        </Box>
      )}
      {modalShow && (
        <EditShopModal shop={editShop} shopName={shopName} onClose={handleModalClose} />
      )}
    </Box>
  )
}

export default Shops
