import { FC, useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Paper, // Box,
  Typography, // Link,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@mui/material'

import { subscriptionFAQ } from 'constants/faq'

// import { subscriptionShops } from 'constants/mockData'
import { PrivacyPolicyModal } from 'components/policies/PrivacyPolicyModal'

// import ShopSubscriptionCard from 'components/subscription/ShopSubscriptionCard'

// import FeatureList from '../FeatureList'

const SubscriptionDetails: FC = () => {
  const [privacyModal, setPrivacyModal] = useState(false)
  // const [isSubscriptionFeature, setIsSubscriptionFeature] = useState<boolean>(false)

  return (
    <>
      {/* <Paper
        sx={{
          p: { xs: '1rem', sm: '2rem' },
          maxWidth: '1056px',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>Subscription details</Typography>
        <Typography sx={{ color: '#828282' }}>
          View the{' '}
          <Link
            onClick={() => setPrivacyModal(true)}
            sx={{ color: 'draft.main', textDecorationColor: 'draft.main' }}
          >
            privacy policy
          </Link>
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
          <Box
            sx={{
              mt: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              gap: '1.5rem',
              flexWrap: 'wrap',
            }}
          >
            {subscriptionShops.map((subscription) => (
              <ShopSubscriptionCard key={subscription.id} subscription={subscription} />
            ))}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: 500 }}>
                * Plan name: Standard
              </Typography>
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 400,
                  color: '#828282',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsSubscriptionFeature(!isSubscriptionFeature)
                }}
              >
                show details{' '}
                {isSubscriptionFeature ? (
                  <KeyboardArrowUpIcon sx={{ fontSize: '1rem' }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} />
                )}
              </Typography>
            </Box>
            {isSubscriptionFeature && <FeatureList />}
          </Box>
        </Box>
      </Paper> */}
      <Paper sx={{ p: { xs: '1rem 2rem', md: '1.5rem 4rem' }, maxWidth: '1056px' }}>
        <Typography sx={{ fontSize: '1.25rem', fontWeight: 600, textAlign: 'center' }}>
          FAQ
        </Typography>
        {subscriptionFAQ.map(({ summary, details }, index) => (
          <Accordion
            key={`faq-${index}`}
            disableGutters
            elevation={0}
            sx={{
              borderBottom: '1px #DFDFDF solid',
              py: '1rem',
              '&.MuiButtonBase-root-MuiAccordionSummary-root': { p: '0' },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
              <Typography sx={{ fontSize: '1.25rem', fontWeight: 500, p: 0 }}>{summary}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ fontSize: '0.875rem', textAlign: 'justify', pl: '1rem' }}>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '1rem',
                  fontWeight: '400',
                  lineHeight: '1.5rem',
                  textAlign: 'left',
                }}
              >
                {details}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
      {privacyModal && <PrivacyPolicyModal onClose={() => setPrivacyModal(false)} />}
    </>
  )
}

export default SubscriptionDetails
