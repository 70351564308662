import { FC } from 'react'

import { Box } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetReviewsQuery } from 'services/reviews/api'
import { ReviewStatus } from 'services/reviews/types'

import EmptyState from 'components/common/EmptyState'
import PageHeader from 'components/common/PageHeader'
import Pagination from 'components/common/Pagination'
import Preloader from 'components/common/Preloader'
import SearchInput from 'components/common/SearchInput'
import StatusesFilter from 'components/common/StatusesFilter'
import ReviewCardItem from 'components/reviews/ReviewCardItem'

import { reviewerStatusFilters } from './filters'

const Reviews: FC = () => {
  const {
    queryParams,
    pagination,
    statusFilter,
    search,
    setPagination,
    setStatusFilter,
    setSearch,
    isFiltered,
  } = useDataGridPagination()

  const { data, isLoading, isFetching } = useGetReviewsQuery(
    { ...queryParams, sortField: 'updatedAt', sortDir: 'desc' },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  return (
    <Box mb="2.5rem">
      <PageHeader title="Reviews" />
      <Box sx={{ mb: '1.5rem', display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
        <StatusesFilter<ReviewStatus>
          init={statusFilter}
          filterChange={setStatusFilter}
          filters={reviewerStatusFilters}
        />
        <SearchInput initValue={search} onSearchChange={setSearch} />
      </Box>
      {isLoading || isFetching ? (
        <Preloader />
      ) : (
        <Box>
          {!data?.data.length && (
            <EmptyState isFiltered={isFiltered} text="You haven't added any reviews yet." />
          )}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              mb: '2.5rem',
              gap: '1rem',
              justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
            }}
          >
            {data?.data.map((review) => <ReviewCardItem review={review} key={review.id} />)}
          </Box>
        </Box>
      )}

      {!!data?.meta.totalItems && (
        <Box display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(data.meta.totalItems / data.meta.itemsPerPage) || 1}
            page={pagination.page || 1}
            onChange={setPagination}
          />
        </Box>
      )}
    </Box>
  )
}

export default Reviews
