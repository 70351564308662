import React from 'react'

import { SxProps, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography/Typography'

interface Props {
  sx?: SxProps
  title: string
}

export const EllipsedText = ({ sx, title }: Props) => {
  return (
    <Tooltip title={title} sx={{ minWidth: '0' }}>
      <Typography
        sx={{
          minWidth: '0',
          maxWidth: '100%',
          textWrap: 'nowrap',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          ...sx,
        }}
      >
        {title}
      </Typography>
    </Tooltip>
  )
}
