import { Dispatch, FC, SetStateAction, ChangeEvent } from 'react'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Pagination as MuiPagination, PaginationItem, Typography } from '@mui/material'

import { Pagination as TPagination } from 'types/Table'

interface Props {
  count: number
  page: number
  onChange: Dispatch<SetStateAction<TPagination>>
}

const Pagination: FC<Props> = ({ count, page, onChange }) => {
  const handlePaginationChange = (e: ChangeEvent<unknown>, value: number) => {
    onChange({ page: value, itemsPerPage: 10 })
  }

  return (
    <MuiPagination
      shape="rounded"
      count={count}
      page={page}
      onChange={handlePaginationChange}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            next: () => (
              <Typography
                component="span"
                sx={{ display: 'flex', alignItems: 'center', fontWeight: 500, gap: '0.5rem' }}
              >
                Next <ArrowForwardIosIcon sx={{ fontSize: '1rem' }} />
              </Typography>
            ),
            previous: () => (
              <Typography
                component="span"
                sx={{ display: 'flex', alignItems: 'center', fontWeight: 500, gap: '0.5rem' }}
              >
                <ArrowBackIosIcon sx={{ fontSize: '1rem' }} /> Previous
              </Typography>
            ),
          }}
          {...item}
        />
      )}
      sx={{
        '& .MuiPaginationItem-root.Mui-selected': {
          backgroundColor: '#262626',
          color: '#FFFFFF',
          opacity: 1,
          '&:hover': { backgroundColor: '#262626' },
        },
        '& .MuiPaginationItem-root': {
          height: '2.5rem',
        },
        '& .MuiPaginationItem-ellipsis, & .MuiPaginationItem-page': {
          width: '2.5rem',
        },
        '& .MuiPaginationItem-ellipsis': {
          pt: '0.5rem',
        },
      }}
    />
  )
}

export default Pagination
