import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Typography, Tooltip } from '@mui/material'
import TextField from '@mui/material/TextField'

import { showNotification } from 'utils/showNotification'

import { useCreateShopMutation, useUpdateShopMutation } from 'services/shops/api'
import { NewShop, Shop } from 'services/shops/types'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'
import TextInput from 'components/common/TextInput'

import { shopSchema } from './validation'

interface Props {
  onClose: () => void
  shop?: Shop | null
  shopName?: string | null
}

type FormType = Omit<NewShop, 'shopifyName' | 'url'>

const EditShopModal: FC<Props> = ({ onClose, shop, shopName }) => {
  const shopifyShopUrl = shopName ? shopName : `${shop?.shopifyName}.myshopify.com`
  const [updateShop, { isLoading: isUpdateLoading }] = useUpdateShopMutation()
  const [createShop, { isLoading: isCreateLoading }] = useCreateShopMutation()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    resolver: yupResolver(shopSchema),
    defaultValues: {
      name: shop?.name || '',
      description: shop?.description || '',
      defaultReviewPrice: shop?.defaultReviewPrice || 0,
    },
  })

  const onSubmit = (newShop: FormType) => {
    const shopifyShopUrl = shopName ? shopName : shop?.shopifyName ? shop.shopifyName : ''
    const shopifyName = shopifyShopUrl.split('.myshopify.com')[0]
    const url = `https://${shopifyShopUrl}`

    if (shop) {
      updateShop({ id: shop.id, ...newShop, shopifyName, url })
        .unwrap()
        .then(() => {
          showNotification('Shop successfully updated', 'success')
          onClose()
        })
        .catch((error) => {
          showNotification('Some error occurred', error)
        })
    } else {
      createShop({ ...newShop, shopifyName, url })
        .unwrap()
        .then(() => {
          showNotification('Shop successfully added', 'success')
          onClose()
        })
        .catch((error) => {
          showNotification('Some error occurred', error)
        })
    }
  }

  return (
    <Modal
      title={shop ? 'Edit shop' : 'Add shop'}
      loading={isUpdateLoading || isCreateLoading}
      open={true}
      onClose={onClose}
      sx={{ maxWidth: '34.5rem' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Modal.Content sx={{ paddingTop: '1rem' }}>
        <TextInput label="Shop name" name="name" control={control} errors={errors} />
        <TextField label="Shopify store link" value={shopifyShopUrl} disabled />
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <TextInput
            label="Review Reward, $"
            name="defaultReviewPrice"
            type="number"
            inputProps={{
              min: 0,
              onChange: (event) => {
                if (parseInt(event.currentTarget.value) < 0) {
                  event.currentTarget.value = '1'
                }
              },
            }}
            control={control}
            errors={errors}
          />
          <Tooltip
            title="This is the default bonus amount that will be rewarded to reviewers after their video review is approved. 
              The bonus is an internal currency used for discounts on their next purchase. 
              As a vendor, you won't be paying real money—the reward is part of our service's currency system."
          >
            <Typography sx={{ color: '#BDBDBD', paddingTop: '1rem' }}>
              <ErrorOutlineIcon />
            </Typography>
          </Tooltip>
        </Box>
        <TextInput
          label="Description"
          name="description"
          control={control}
          errors={errors}
          multiline
          rows={8}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={isUpdateLoading || isCreateLoading}
          onClick={onClose}
          variant="outlined"
          fullWidth
        >
          Cancel
        </Button>
        <Button loading={isUpdateLoading || isCreateLoading} type="submit" fullWidth>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default EditShopModal
