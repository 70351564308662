import { Error } from 'types/Error'

export const isErrorWithMessage = (error: unknown): error is Error => {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    typeof (error as any).data.detail === 'string'
  )
}
