import { CouponStatus } from 'services/coupons/types'

export const couponsFilters = [
  {
    label: 'Active',
    status: CouponStatus.ACTIVE,
  },
  {
    label: 'Used',
    status: CouponStatus.USED,
  },
]
