import { GetDataToTablePayload } from 'types/Table'

export enum ReviewStatus {
  NEW = 0,
  MODERATION = 1,
  APPROVED = 2,
  REJECTED = 3,
  RETURNED = 4,
  DRAFT = 5,
  PENDING_TRANSFER = 6,
}

export type ModerationStatus = ReviewStatus.RETURNED | ReviewStatus.REJECTED | ReviewStatus.APPROVED

export interface GetReviewsPayload extends GetDataToTablePayload {
  productId?: string
  reviewerId?: string
}

export interface Review {
  id: string
  name: string
  description?: string | null
  status: ReviewStatus
  price: number
  mediaFile: string
  createdAt: string
  updatedAt: string | null

  userId: string
  userEmail: string
  userFirstName: string
  userLastName: string

  productId: string
  productName: string
  productImageUrl: string
  productLink: string

  sellerId: string
  sellerFirstName: string
  sellerLastName: string

  shopId: string
  shopName: string
  shopDefaultReviewPrice: number
}

export interface ListReviewsRecord {
  id: string
  name: string
  description: string | null
  status: ReviewStatus
  price: number
  mediaFile: string
  createdAt: string
  updatedAt: string | null
  approvedAt: string | null
  productId: string
  productName: string
  productLink: string
  productImageUrl: string
  shopId: string
  shopName: string
  shopDefaultReviewPrice: number
  sellerId: string
  sellerFirstName: string
  sellerLastName: string
  userId: string
  userFirstName: string
  userLastName: string
  userEmail: string
  userProfileImageUrl: string | null
}

export interface Purchase {
  id: string
  price: number
  createdAt: string
  productName: string
  productLink: string
  productImageUrl: string
  sellerId: string
  sellerFirstName: string
  sellerLastName: string
}

export interface ReviewHistoryRecord {
  id: string
  message: string
  status: ReviewStatus
  createdAt: string
  updatedAt: string
  userName: string
}

export interface HistoryItemByDate {
  [date: string]: ReviewHistoryRecord[]
}
