import { Link } from 'react-router-dom'

import { Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'

import {
  SALE_STATUS_DESCRIPTION_BY_NAME,
  SALE_STATUS_NAME,
  SaleStatus,
  Sale,
} from 'services/sales/types'

export const createSaleColumns = (
  handleAction: (reviewerEmail: string, productId: string) => void
) => {
  const columns: GridColDef<Sale>[] = [
    {
      field: 'product',
      headerName: 'Product Name',
      type: 'string',
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row.productName}>
          <Link to={`admin/products/${row.productId}`}>{row.productName}</Link>
        </Tooltip>
      ),
    },
    {
      field: 'shop',
      headerName: 'Shop Name',
      type: 'string',
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row.shopName}>
          <Link to={`/admin/shops/${row.shopId}`}>{row.shopName}</Link>
        </Tooltip>
      ),
    },
    {
      field: 'user',
      headerName: 'User Name',
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Tooltip title={row.userName}>
          <Typography>{row.userName}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'customerEmail',
      headerName: 'Customer Email',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row.userEmail}>
          <Typography>{row.userEmail}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'notificationStatus',
      headerName: 'Email Notification Step',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Tooltip
            title={
              row.notificationStatus !== null
                ? SALE_STATUS_DESCRIPTION_BY_NAME[
                    SALE_STATUS_NAME[row.notificationStatus as SaleStatus]
                  ]
                : SALE_STATUS_DESCRIPTION_BY_NAME.Off
            }
          >
            <Typography
              sx={{ fontSize: '0.875rem', fontWeight: 600, ml: '0.5rem', color: '#262626' }}
            >
              {row.notificationStatus !== null
                ? SALE_STATUS_NAME[row.notificationStatus as SaleStatus]
                : 'Off'}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 0.5,
      getActions: ({ row }) => [
        <GridActionsCellItem
          key={`${row.id}_email_send`}
          label="Send mail"
          onClick={() => {
            handleAction(row.userEmail, row.productId)
          }}
          showInMenu
        />,
      ],
    },
  ]

  return columns
}
