import Box from '@mui/material/Box/Box'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetSellersQuery } from 'services/admins/api'

import PageHeader from 'components/common/PageHeader'
import SearchInput from 'components/common/SearchInput'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'

export const Sellers = () => {
  const { queryParams, pagination, sort, search, setPagination, setSort, setSearch, isFiltered } =
    useDataGridPagination()

  const { data, isLoading, isFetching } = useGetSellersQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  })

  return (
    <>
      <PageHeader title="Sellers" />
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mb: '1.5rem' }}>
          <SearchInput initValue={search} onSearchChange={setSearch} />
        </Box>
        <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
          <CustomDataGrid
            data={data}
            columns={columns}
            loading={isLoading || isFetching}
            paginationModel={pagination}
            setPagination={setPagination}
            setSort={setSort}
            initSort={sort}
            isFiltered={isFiltered}
            rowHeight={72}
          />
        </Box>
      </Box>
    </>
  )
}
