import { Link } from 'react-router-dom'

import { Box, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'

import formatDate from 'utils/formatToLocalTimezone'

import { Seller } from 'services/admins/types'

const fakeSubsMap = {
  0: 'Trial',
  1: 'Standard',
  2: 'Pro',
}
type subsPlanType = keyof typeof fakeSubsMap

export const columns: GridColDef<Seller>[] = [
  {
    field: 'name',
    headerName: 'Full Name',
    type: 'string',
    flex: 3,
    valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`,
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    flex: 2,
  },
  {
    field: 'createdAt',
    headerName: 'Date of Reg',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    flex: 1.5,
    valueFormatter: ({ value }) => formatDate(value, 'MM.dd.yyyy'),
  },
  {
    field: 'subscription', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Subscription Plan',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    flex: 1.5,
    valueGetter: ({ row }) => `${fakeSubsMap[row.subscription as subsPlanType] || 'No data'}`,
  },
  {
    field: 'reviewersCount', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Users',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'reviewsCount', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Reviews',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'approvedReviewsCount', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Approved',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'moderationReviewsCount', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'On Moderation',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'rejectedReviewsCount', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Rejected',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'totalPayments', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Payments',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'shopsCount', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Shops',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,

    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography>{row.shopsCount}</Typography>
        <Link to={`${row.id}/shops`}>Show</Link>
      </Box>
    ),
  },
  {
    field: 'productsCount', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Products',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'couponsCount', //TODO: add field name and show with the help of map then render with valueGetter
    headerName: 'Coupons',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,

    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography>{row.couponsCount}</Typography>
        <Link to={`${row.id}/coupons`}>Show</Link>
      </Box>
    ),
  },
]
