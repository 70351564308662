import { Link } from 'react-router-dom'

import { GridColDef } from '@mui/x-data-grid'

import { AdminShop } from 'services/admin/shops/types'

export const columns: GridColDef<AdminShop>[] = [
  {
    field: 'name',
    headerName: 'Shop name',
    type: 'string',
    flex: 2,
    renderCell: ({ row }) => <Link to={`${row.id}`}>{row.name}</Link>,
  },
  {
    field: 'defaultReviewPrice',
    headerName: 'Default bonus',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'productsCount',
    headerName: 'Products',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'approvedReviewsCount',
    headerName: 'Approved',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'moderationReviewsCount',
    headerName: 'On Moderation',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'rejectedReviewsCount',
    headerName: 'Rejected',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'totalPayments',
    headerName: 'Payments',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
]
