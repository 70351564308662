import { yupResolver } from '@hookform/resolvers/yup'
import { skipToken } from '@reduxjs/toolkit/query'
import { FC, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import AddIcon from '@mui/icons-material/Add'
import { Box, Tooltip } from '@mui/material'

import { MEDIA_URL } from 'constants/urls'

import { showNotification } from 'utils/showNotification'

import {
  useGetReviewByIdQuery,
  useSaveReviewAsDraftMutation,
  useSendReviewForModerationMutation,
} from 'services/reviews/api'

import Button from 'components/common/Button'
import PageBox from 'components/common/Page'
import PageHeader from 'components/common/PageHeader'
import PercentageCircularProgress from 'components/common/PercentageCircularProgress'
import TextInput from 'components/common/TextInput'
import UploadFileButton from 'components/common/UploadFileButton'
import VideoDropzone from 'components/common/VideoDropzone'
import VideoPlayer from 'components/common/VideoPlayer'
import { ReviewerReviewInfo } from 'components/reviews/ReviewerReviewInfo'

import { videoDetailsSchema } from './validation'

export type FormVideoDetailsType = yup.InferType<typeof videoDetailsSchema>

const CreateReview: FC = () => {
  const navigate = useNavigate()

  const [file, setFile] = useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = useState<number>(0)

  const { reviewId } = useParams()

  const { data: review } = useGetReviewByIdQuery(reviewId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  })

  const [saveAsDraft, { isLoading: isSavingAsDraft }] = useSaveReviewAsDraftMutation()
  const [sendForModeration, { isLoading: isSendingForModeration }] =
    useSendReviewForModerationMutation()

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormVideoDetailsType>({
    resolver: yupResolver(videoDetailsSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  })

  const reviewName = watch('name')

  const isSendForModerationButtonDisabled =
    !file || isSavingAsDraft || isSendingForModeration || reviewName === ''
  const isSaveAsDraftButtonDisabled = isSavingAsDraft || isSendingForModeration || reviewName === ''

  const onSubmit = (saveAs: 'DRAFT' | 'MODERATION') => (formValues: FormVideoDetailsType) => {
    if (!file && saveAs !== 'DRAFT') {
      return
    }

    if (reviewId) {
      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }
      formData.append('name', formValues.name)
      formData.append('description', formValues.description || '')

      if (saveAs === 'DRAFT') {
        saveAsDraft({ id: reviewId, formData, setUploadProgress })
          .unwrap()
          .then(() => {
            navigate('/my-reviews')
            showNotification(`Draft successfully saved`, 'success')
          })
          .catch(() => showNotification(`Failed to save draft`, 'error'))
      } else {
        sendForModeration({
          id: reviewId,
          formData,
          setUploadProgress,
        })
          .unwrap()
          .then(() => {
            navigate('/my-reviews')
            showNotification(`Successfully sent for moderation`, 'success')
          })
          .catch(() => {
            showNotification(`Failed to send for moderation`, 'error')
          })
      }
    }
  }

  const videoUrl = useMemo(() => {
    return file
      ? URL.createObjectURL(file)
      : review?.mediaFile
        ? `${MEDIA_URL}${review.mediaFile}`
        : ''
  }, [file])

  return (
    <>
      <PageHeader title={review?.productName || ''} backPath={'/purchases'} />
      <PageBox sx={{ height: '100%' }} paperMaxWidth="64rem">
        <ReviewerReviewInfo review={review} />

        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              flexDirection: { xs: 'column-reverse', sm: 'column-reverse', md: 'row' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '1.5rem',
                flexDirection: 'column',
                width: '100%',
                position: 'relative',
              }}
            >
              <PercentageCircularProgress
                loader={false}
                loaderUpload={isSendingForModeration || isSavingAsDraft}
                value={uploadProgress}
              />

              {videoUrl ? (
                <VideoPlayer src={videoUrl} />
              ) : (
                <VideoDropzone
                  file={file}
                  setFile={setFile}
                  maxSize={268435456} //256MB
                  acceptedFormats={{ 'video/mp4': ['.mp4', '.MP4'] }}
                  wrapperSx={{ height: '21.5rem' }}
                />
              )}

              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: '1rem', sm: '1rem', md: '1.5rem' },
                  flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                }}
              >
                <Box sx={{ width: { xs: '100%', sm: '100%', md: '33%' } }}>
                  <UploadFileButton
                    setFile={setFile}
                    maxSize={268435456} //256MB
                    acceptedFormats={['.mp4']}
                    name={file || videoUrl ? 'Update video' : 'Add video'}
                    endIcon={file ? null : <AddIcon fontSize="small" />}
                  />
                </Box>
                {!file || reviewName === '' ? (
                  <Tooltip
                    title={
                      !file
                        ? 'Please upload a video'
                        : reviewName === ''
                          ? 'Please enter a video review name'
                          : ''
                    }
                  >
                    <Box>
                      <Button type="submit" disabled onClick={handleSubmit(onSubmit('MODERATION'))}>
                        Send for moderation
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <Button
                    type="submit"
                    sx={{ width: { xs: '100%', sm: '100%', md: '33%' } }}
                    disabled={isSendForModerationButtonDisabled}
                    onClick={handleSubmit(onSubmit('MODERATION'))}
                  >
                    Send for moderation
                  </Button>
                )}

                {reviewName === '' ? (
                  <Tooltip title="Please enter a video review name">
                    <Box>
                      <Button
                        type="submit"
                        variant="outlined"
                        disabled
                        onClick={handleSubmit(onSubmit('DRAFT'))}
                      >
                        Save as draft
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={{ width: { xs: '100%', sm: '100%', md: '33%' } }}
                    disabled={isSaveAsDraftButtonDisabled}
                    onClick={handleSubmit(onSubmit('DRAFT'))}
                  >
                    Save as draft
                  </Button>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                //maxWidth: { xs: '40rem', sm: '50rem', md: '20.75rem' },
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                <TextInput name="name" label="Video name" control={control} errors={errors} />
                <TextInput
                  name="description"
                  label="Description"
                  control={control}
                  errors={errors}
                  multiline
                  rows={8}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </PageBox>
    </>
  )
}

export default CreateReview
