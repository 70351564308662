import { FC } from 'react'

import { Box } from '@mui/material'

import { RolesGroups } from 'types/User'

import { userRoles } from 'constants/roles'

import KeycloakService from 'services/KeycloakService'

import PageHeader from 'components/common/PageHeader'
import SubscriptionDetails from 'components/profile/SubscriptionDetails'
import UserInfo from 'components/profile/UserInfo'

const Profile: FC = () => {
  return (
    <Box>
      <PageHeader title="Profile" />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
        <UserInfo />
        {KeycloakService.hasRole([userRoles[RolesGroups.SELLER]]) && <SubscriptionDetails />}
      </Box>
    </Box>
  )
}

export default Profile
