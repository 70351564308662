import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography, Tooltip } from '@mui/material'

import { reviewStatusStyles } from 'constants/reviewStatusRender'

import formatDate from 'utils/formatToLocalTimezone'

import { Review, ReviewStatus } from 'services/reviews/types'

import { ReactComponent as Pencil } from 'assets/svg/pencil.svg'

import EditReviewBonusModal from '../EditReviewBonusModal'

interface Props {
  review: Review
}

const SellerReviewInfo: FC<Props> = ({ review }) => {
  const [modalShow, setModalShow] = useState(false)
  const navigate = useNavigate()

  const status = review.status

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
        flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' },
        minWidth: '0',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: 0, gap: '0.5rem' }}>
        <Box
          sx={{
            fontSize: '1.25rem',
            fontWeight: '700',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'flex',
          }}
        >
          <Typography
            component="span"
            sx={{
              lineHeight: '1.5rem',
              fontSize: '1rem',
              fontWeight: '500',
              mr: '1rem',
            }}
          >
            Product:
          </Typography>
          <Typography
            sx={{
              lineHeight: '1.5rem',
              fontSize: '1.25rem',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              cursor: 'pointer',
            }}
            onClick={() => navigate(`/products/${review.productId}`)}
          >
            {review.productName}
          </Typography>
        </Box>
        <Box
          sx={{
            minWidth: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'flex',
          }}
        >
          <Typography
            component="span"
            sx={{ fontSize: '1rem', fontWeight: '500', mr: '1rem', lineHeight: '1.5rem' }}
          >
            Reviewer:
          </Typography>
          <Typography
            sx={{
              lineHeight: '1.5rem',
              fontSize: '1.25rem',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {`${review.userFirstName} ${review.userLastName}`}
          </Typography>
        </Box>
        <Box
          sx={{
            minWidth: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'flex',
          }}
        >
          <Typography
            component="span"
            sx={{ fontSize: '1rem', fontWeight: '500', mr: '1rem', lineHeight: '1.5rem' }}
          >
            Review date:
          </Typography>
          <Typography
            sx={{
              lineHeight: '1.5rem',
              fontSize: '1.25rem',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {formatDate(review.createdAt, 'MMM dd, yyyy')}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography
            component="span"
            sx={{
              lineHeight: '1.5rem',
              fontSize: '1rem',
              fontWeight: '500',
              mr: '1rem',
            }}
          >
            Status:
          </Typography>
          <Typography
            sx={{
              color: reviewStatusStyles[status].color,
              lineHeight: '1.5rem',
              fontSize: '1.25rem',
              fontWeight: '600',
              display: 'flex',
              gap: '0.3rem',
            }}
          >
            {reviewStatusStyles[status].text}
            {review.status === ReviewStatus.PENDING_TRANSFER && (
              <Tooltip
                title="Please wait for the money transfer to finish and refresh the page"
                enterTouchDelay={0}
              >
                <InfoIcon />
              </Tooltip>
            )}
          </Typography>
        </Box>
        <Box sx={{ fontSize: '1.25rem', fontWeight: '700', display: 'flex', alignItems: 'center' }}>
          <Typography
            component="span"
            sx={{
              lineHeight: '1.5rem',
              fontSize: '1rem',
              fontWeight: '500',
              mr: '1rem',
            }}
          >
            Payment per video:
          </Typography>
          <Typography
            sx={{
              lineHeight: '1.5rem',
              fontSize: '1.25rem',
              fontWeight: '600',
            }}
          >
            ${review.price}
          </Typography>
          {status === ReviewStatus.MODERATION && (
            <Typography
              component="span"
              sx={{
                ml: '1rem',
                position: 'relative',
                top: '0.125rem',
                color: '#828282',
                cursor: 'pointer',
                '&:hover': { color: 'inherit' },
              }}
            >
              <Pencil onClick={() => setModalShow(true)} />
            </Typography>
          )}
        </Box>
      </Box>
      {modalShow && (
        <EditReviewBonusModal
          reviewId={review.id}
          defaultBonus={review.shopDefaultReviewPrice}
          onClose={() => setModalShow(false)}
        />
      )}
    </Box>
  )
}

export default SellerReviewInfo
