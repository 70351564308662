import { FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import KeycloakService from 'services/KeycloakService'

import { Roles } from 'types/User'

interface Props {
  roles: Roles[]
  hide?: boolean
}

const RoleBasedRoute: FC<Props> = ({ roles, hide = false }) =>
  (KeycloakService.hasRole(roles) && !hide) || (!KeycloakService.hasRole(roles) && hide) ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  )

export default RoleBasedRoute
