import { skipToken } from '@reduxjs/toolkit/query'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import { useGetShopQuery } from 'services/shops/api'

import PageHeader from 'components/common/PageHeader'
import Preloader from 'components/common/Preloader'
import UserProductsByStore from 'components/products/UserProductsByStore'

const ShopView: FC = () => {
  const { shopId } = useParams()

  const { data: shop, isFetching } = useGetShopQuery(shopId ?? skipToken, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  if (isFetching || !shop) return <Preloader parentHeight="100vh" />

  return (
    <Box>
      <PageHeader title={shop.name} backPath="/shops" />
      <UserProductsByStore shop={shop} />
    </Box>
  )
}

export default ShopView
