import { FC, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import { Avatar, Tooltip, Link, useMediaQuery, Theme, Skeleton } from '@mui/material'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { APP_BAR_HEIGHT } from 'constants/main-layout'
import { userRoles } from 'constants/roles'
import { MEDIA_URL } from 'constants/urls'

import KeycloakService from 'services/KeycloakService'
import { useGetCurrentUserQuery } from 'services/users/api'

import Logo from 'components/common/Logo'

import SellerBalance from './SellerBalance'

interface Props {
  open: boolean
  handleDrawerOpen: (state: boolean) => void
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps & { open: boolean }>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

const MyAppBar: FC<Props> = ({ open, handleDrawerOpen }) => {
  const navigate = useNavigate()
  const { data: currentUser, isLoading } = useGetCurrentUserQuery()
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const handleOpenUserProfile = (event: MouseEvent<HTMLElement>) => {
    navigate('/profile')
  }

  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{ background: '#262626', color: '#222b45', boxShadow: '1', height: APP_BAR_HEIGHT }}
    >
      <Toolbar sx={{ height: '100%' }}>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', color: '#FFFFFF' }}>
          <IconButton
            aria-label="open drawer"
            onClick={() => handleDrawerOpen(!open)}
            edge="start"
            sx={{ mr: '1rem', color: '#FFFFFF' }}
          >
            <MenuIcon />
          </IconButton>
          <Link href="/dashboard" sx={{ textDecoration: 'none', mt: '0.375rem' }}>
            <Logo hideText={isSm} />
          </Link>
        </Box>
        <Box
          sx={{
            flexGrow: 0,
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Box sx={{ maxWidth: '12rem' }}>
            {!isLoading ? (
              <Typography
                sx={{
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',
                  fontWeight: 500,
                  textAlign: 'right',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {`${currentUser?.firstName} ${currentUser?.lastName}`}
              </Typography>
            ) : (
              <Skeleton
                sx={{
                  width: '6.5rem',
                  fontSize: '0.9rem',
                  bgcolor: 'rgba(255, 255, 255, .2)',
                }}
              />
            )}

            {KeycloakService.hasRole([userRoles.sellers_group]) && <SellerBalance />}
          </Box>
          <Tooltip
            title={
              KeycloakService.hasRole([userRoles.sellers_group])
                ? 'Profile & Settings'
                : 'Your Profile'
            }
          >
            <IconButton sx={{ p: 0 }} onClick={handleOpenUserProfile}>
              <Avatar
                alt={`${currentUser?.firstName} ${currentUser?.lastName}`}
                src={
                  currentUser?.profileImageUrl
                    ? `${MEDIA_URL}${currentUser.profileImageUrl}?${Date.now()}`
                    : ''
                }
                sx={{ width: '3rem', height: '3rem', backgroundColor: 'rgba(255, 255, 255, .2)' }}
              >
                {currentUser?.firstName.charAt(0)?.toUpperCase() ?? ''}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default MyAppBar
