import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Typography } from '@mui/material'

import { showNotification } from 'utils/showNotification'

import { useUpdateReviewPriceMutation } from 'services/reviews/api'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'
import TextInput from 'components/common/TextInput'

import { editBonusSchema } from './validation'

interface Props {
  reviewId: string
  defaultBonus: number
  onClose: () => void
}

interface FormData {
  bonus: number
}

const EditReviewBonusModal: FC<Props> = ({ reviewId, defaultBonus, onClose }) => {
  const [updateReviewBonus, { isLoading: isUpdateLoading }] = useUpdateReviewPriceMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(editBonusSchema),
    context: { defaultBonus },
    defaultValues: {
      bonus: defaultBonus,
    },
  })

  const onSubmit = (formData: FormData) => {
    updateReviewBonus({ id: reviewId, price: formData.bonus })
      .unwrap()
      .then(() => {
        showNotification('Review bonus successfully update', 'success')
        onClose()
      })
      .catch(() => {
        showNotification('Some error occurred', 'error')
      })
  }

  return (
    <Modal
      open
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => onClose()}
      sx={{ maxWidth: '29.5rem' }}
    >
      <Modal.Content>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 500,
              maxWidth: '18.25rem',
              px: 0,
              textAlign: 'center',
              m: '3.25rem 0 3rem',
            }}
          >
            You can&apos;t change the reward amount below the default price($)
          </Typography>
        </Box>
        <TextInput
          label="Bonus, $"
          name="bonus"
          type="number"
          control={control}
          errors={errors}
          fullWidth
        />
      </Modal.Content>
      <Modal.Actions>
        <Button loading={isUpdateLoading} onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button loading={isUpdateLoading} type="submit" fullWidth>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default EditReviewBonusModal
