import { Box, useMediaQuery, Theme, Typography, Container } from '@mui/material'

import { DEMO_STORE_FEATURES } from 'constants/guest-layout'

import firstPageBg from 'assets/png/demo-store-fp.png'
import productImage from 'assets/png/demo_store_product_img.png'

import Button from 'components/common/Button'
import LandingTitle from 'components/common/LandingTitle'
import VideoPlayer from 'components/common/VideoPlayer'
import DemoStoreFeature from 'components/public-layout/DemoStoreFeature'
import DemoStoreStartBoosting from 'components/public-layout/DemoStoreStartBoosting'
import DemoStoreVideoFeedbacks from 'components/public-layout/DemoStoreVideoFeedbacks'
import RunningLine from 'components/public-layout/RunningLine'

const DemoStoreLanding = () => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Box>
      <Box
        sx={{
          height: '40rem',
          background: `linear-gradient(110.41deg, rgba(0, 0, 0, 0.8) -4.47%, rgba(0, 0, 0, 0.788887) ${
            isSm ? '17.91%' : '6.18%'
          }, rgba(0, 0, 0, 0) ${isSm ? '74.57%' : '53.82%'}), url(${firstPageBg}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            p: '0 1rem',
          }}
        >
          <Typography
            component="span"
            sx={{ fontSize: '3.5rem', fontWeight: 900, lineHeight: '4.2rem', color: '#FFFFFF' }}
          >
            Shopify demo store
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: '1.125rem',
              fontWeight: 600,
              lineHeight: '1.3rem',
              color: '#FFFFFF',
              width: '100%',
              maxWidth: '40.75rem',
              textAlign: 'center',
            }}
          >
            Discover the power of authentic feedback with ShopiView. Our service revolutionizes the
            way you gather and utilize customer reviews.
          </Typography>
        </Box>
        {/* <Button sx={{ borderRadius: '1.5rem' }}>Install now</Button> */}
      </Box>

      <Container sx={{ p: '4.625rem 0' }}>
        <LandingTitle text="Key Features" />
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '2rem' }}>
          {DEMO_STORE_FEATURES.map((feature) => (
            <DemoStoreFeature
              key={feature.title}
              icon={feature.icon}
              text={feature.text}
              title={feature.title}
            />
          ))}
        </Box>
      </Container>

      <Box sx={{ bgcolor: '#F7F5F7' }}>
        <Container
          sx={{
            p: '4.625rem 0',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2rem',
            '@media (max-width: 660px)': { flexWrap: 'wrap' },
            '@media (max-width: 600px)': { px: '1.5rem' },
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '41.375rem',
              borderRadius: 1,
              '@media (max-width: 660px)': { maxWidth: '100%' },
            }}
          >
            <img width="100%" src={productImage} alt="The Multi managed Snowboard" />
          </Box>
          <Box sx={{ width: '100%', maxWidth: '24.5rem' }}>
            <Typography
              sx={{ fontSize: '2.5rem', fontWeight: 800, lineHeight: '3rem', pb: '0.5rem' }}
            >
              The Multi managed Snowboard
            </Typography>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 500,
                lineHeight: '1.21rem',
                pb: '1.5rem',
                color: '#828282',
              }}
            >
              Multi managed Vendor
            </Typography>
            <Typography
              sx={{ fontSize: '1.25rem', fontWeight: 500, lineHeight: '1.5rem', pb: '1.5rem' }}
            >
              $629.95
            </Typography>
            <Button
              variant="outlined"
              sx={{
                width: '20.625rem',
                borderRadius: 1,
                transition: '0.2s',
                fontSize: '0.930rem',
                fontWeight: 500,
                mb: '0.5rem',
                '&:hover': {
                  boxShadow: '0px 0px 1px 1px rgba(38, 38, 38, 1)',
                  bgcolor: 'transparent',
                  color: '#262626',
                },
              }}
            >
              Add to cart
            </Button>
            <Button
              sx={{
                width: '20.625rem',
                borderRadius: 1,
                transition: '0.2s',
                fontSize: '0.930rem',
                fontWeight: 500,
                '&:hover': {
                  boxShadow: '0px 0px 1px 1px rgba(38, 38, 38, 1)',
                  bgcolor: '#262626',
                  color: '#FFFFFF',
                },
              }}
            >
              Buy it now
            </Button>
          </Box>
        </Container>
      </Box>

      <DemoStoreVideoFeedbacks />

      <DemoStoreStartBoosting />

      <RunningLine />

      <Container sx={{ p: '4.625rem 0' }}>
        <LandingTitle text="Installing demo store" sx={{ mb: '3rem' }} />
        <VideoPlayer
          sx={{
            borderRadius: '1.25rem 1.25rem 1.25rem 1.25rem',
            overflow: 'hidden',
            aspectRatio: '16/8.7',
          }}
          src="https://youtu.be/rpqsE2qe6tU?si=zEiMmWl0Xju0tXBO"
        />
      </Container>
    </Box>
  )
}

export default DemoStoreLanding
