import { number, object, string } from 'yup'

export const requiredMessage = 'This field is required'

const REVIEW_PRICE_MIN = 0.01
const REVIEW_PRICE_MAX = 50
const NAME_MAX = 50
const DESCRIPTION_MAX = 300

export const shopSchema = object({
  name: string().required(requiredMessage).min(2).max(NAME_MAX),
  defaultReviewPrice: number()
    .typeError('Must be a number')
    .min(
      REVIEW_PRICE_MIN,
      `Default review reward must be greater than or equal to ${REVIEW_PRICE_MIN}`
    )
    .required(requiredMessage)
    .max(
      REVIEW_PRICE_MAX,
      `Default review reward must be less than or equal to ${REVIEW_PRICE_MAX}`
    ),
  description: string()
    .max(DESCRIPTION_MAX, `Description must be less than or equal to ${DESCRIPTION_MAX}`)
    .nullable()
    .transform((v) => (v ? v : null)),
})
