import { FC } from 'react'

import { Box, Skeleton } from '@mui/material'

const CardPreloader: FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#FFFFFF',
      }}
    >
      <Skeleton sx={{ width: '100%', height: '100%', transform: 'none' }} />
    </Box>
  )
}

export default CardPreloader
