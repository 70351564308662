import { CountPayload, CountResponse } from 'types/Count'
import { TableServerResponse } from 'types/Table'

import { baseApi } from 'api/api'

import { GetSalesPayload, Sale } from './types'

export const salesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSales: build.query<TableServerResponse<Sale[]>, GetSalesPayload>({
      query: (data) => ({
        url: `/sales`,
        method: 'GET',
        params: data,
      }),
      providesTags: () => [{ type: 'Sales', id: 'LIST' }],
    }),
    getSalesCount: build.query<CountResponse, CountPayload>({
      query: (data) => ({
        url: `/sales/count`,
        method: 'GET',
        params: data,
      }),
      providesTags: () => [{ type: 'Sales', id: 'LIST' }],
    }),
  }),
})

export const { useGetSalesQuery, useGetSalesCountQuery } = salesApi
