import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios'
import { MAIN_API_URL } from 'constants/urls'
import { useLayoutEffect } from 'react'

import KeycloakService from 'services/KeycloakService'



export const axiosApi = axios.create({
  baseURL: MAIN_API_URL,
})

export const AxiosInterceptor = () => {
  useLayoutEffect(() => {
    const requestInterceptor = axiosApi.interceptors.request.use(
      async (config) => {
        try {
          const token = await KeycloakService.getTokenWithUpdate()
          config.headers.Authorization = `Bearer ${token}`
        } catch (e) {
          //not authorization
        }

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    const responseInterceptor = axiosApi.interceptors.response.use(
      (response: AxiosResponse) => {
        return response
      },
      (error: AxiosError) => {
        if (error.response && error.response.status === HttpStatusCode.Unauthorized) {
          KeycloakService.doLogout()
        }

        return Promise.reject(error)
      }
    )

    return () => {
      axiosApi.interceptors.request.eject(requestInterceptor)
      axiosApi.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  return null
}

export default AxiosInterceptor
