import { Box, Typography, Link, Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'

import {
  SALE_STATUS_DESCRIPTION_BY_NAME,
  SALE_STATUS_NAME,
  SaleStatus,
  Sale,
} from 'services/sales/types'

import { ReactComponent as ProductImage } from 'assets/svg/product.svg'

import { EllipsedText } from 'components/common/EllipsedText'

export const columns: GridColDef<Sale>[] = [
  {
    field: 'productName',
    headerName: 'Product Name',
    flex: 2,
    sortable: true,
    renderCell: ({ row }) => (
      <Link href={`/products/${row.productId}`} sx={{ textDecoration: 'none', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              borderRadius: '0.5rem',
              overflow: 'hidden',
              minWidth: '3rem',
              width: '3rem',
              height: '3rem',
            }}
          >
            {row.productImage ? (
              <img
                src={row.productImage}
                alt={row.productName}
                loading="lazy"
                style={{ minWidth: '3rem', width: '3rem', height: '3rem', objectFit: 'cover' }}
              />
            ) : (
              <ProductImage style={{ width: '3rem', height: '3rem' }} />
            )}
          </Box>

          <EllipsedText
            title={row.productName}
            sx={{ fontSize: '0.875rem', fontWeight: 400, ml: '0.5rem', color: '#262626' }}
          />
        </Box>
      </Link>
    ),
  },
  {
    field: 'shopName',
    headerName: 'Shop Name',
    flex: 2,
    sortable: true,
    renderCell: ({ row }) => (
      <Link href={`/shops/${row.shopId}/products`} sx={{ textDecoration: 'none', width: '100%' }}>
        <EllipsedText
          title={row.shopName}
          sx={{ fontSize: '0.875rem', fontWeight: 400, color: '#262626' }}
        />
      </Link>
    ),
  },
  {
    field: 'userName',
    headerName: 'User Name',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'userEmail',
    headerName: 'Customers E-mail',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Email Notification Step',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => {
      return (
        <Tooltip
          title={
            row.notificationStatus !== null
              ? SALE_STATUS_DESCRIPTION_BY_NAME[
                  SALE_STATUS_NAME[row.notificationStatus as SaleStatus]
                ]
              : SALE_STATUS_DESCRIPTION_BY_NAME.Off
          }
        >
          <Typography
            sx={{ fontSize: '0.875rem', fontWeight: 600, ml: '0.5rem', color: '#262626' }}
          >
            {row.notificationStatus !== null
              ? SALE_STATUS_NAME[row.notificationStatus as SaleStatus]
              : 'Off'}
          </Typography>
        </Tooltip>
      )
    },
  },
]
