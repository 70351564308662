import { SaleStatus } from 'services/sales/types'

export const filters = [
  {
    label: 'Upcoming',
    status: SaleStatus.UPCOMING,
  },
  {
    label: 'New',
    status: SaleStatus.NEW,
  },
  {
    label: '1 week',
    status: SaleStatus.WEEK1,
  },
  {
    label: '2 week',
    status: SaleStatus.WEEK2,
  },
  {
    label: 'Reg Only',
    status: SaleStatus.REG_ONLY,
  },
  {
    label: 'Old',
    status: SaleStatus.OLD,
  },
  {
    label: 'Completed',
    status: SaleStatus.COMPLETED,
  },
  {
    label: 'Off',
    status: 'off',
  },
]
