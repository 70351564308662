import { useNavigate } from 'react-router-dom'

import { Box, Container, Typography } from '@mui/material'

import persent from 'assets/png/demo_store_bg/img9.png'

import Button from 'components/common/Button'
import ColorBox from 'components/common/ColorBox'

const background = {
  background: `url(${persent}) left center no-repeat, 
    url(${persent}) 85% 90% no-repeat`,
  backgroundSize: '15%, 5%',
}

const DemoStoreStartBoosting = () => {
  const navigate = useNavigate()
  return (
    <Box sx={background}>
      <Container
        sx={{
          p: '0 0 4.625rem',
        }}
      >
        <ColorBox
          sx={{
            p: '3rem',
            flexDirection: 'column',
            bgcolor: '#262626',
            width: '100%',
            textAlign: 'justify',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '1.25rem', md: '2rem' },
              color: '#FFFFFF',
              fontWeight: '700',
              mb: '0.5rem',
            }}
          >
            Start Boosting Your Sales Today
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '1rem', md: '1.25rem' },
              fontWeight: '500',
              color: '#FFFFFF',
              mb: '1.5rem',
              textAlign: 'center',
            }}
          >
            Getting started with <b>ShopiView</b> is simple. Just sign up for an account, integrate
            your Shopify store by installing our plugin, and start requesting video reviews from
            your customers.
          </Typography>
          <Button
            onClick={() => navigate('/seller-registration')}
            sx={{
              bgcolor: '#FFFFFF',
              color: '#262626',
              borderRadius: '1.5rem',
              '&:hover': { bgcolor: '#DADADA' },
            }}
          >
            Register
          </Button>
        </ColorBox>
      </Container>
    </Box>
  )
}

export default DemoStoreStartBoosting
