import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import { Avatar, Link, Box, Typography, Alert } from '@mui/material'

import { RolesGroups } from 'types/User'

import { userRoles } from 'constants/roles'

import { showNotification } from 'utils/showNotification'

import KeycloakService from 'services/KeycloakService'
import { isErrorWithMessage } from 'services/errorHelperService'
import { useRegistrationMutation } from 'services/registration/api'

import Button from 'components/common/Button'
import CustomCheckbox from 'components/common/Checkbox'
import TextInput from 'components/common/TextInput'
import { PrivacyPolicyModal } from 'components/policies/PrivacyPolicyModal'
import { TermsAndConditionsModal } from 'components/policies/TermsAndConditionsModal'

import { registrationSchema } from './validation'

type FormData = yup.InferType<typeof registrationSchema>

interface Props {
  role: RolesGroups
}

const Registration: FC<Props> = ({ role }) => {
  const [registration, { isLoading, isSuccess, error, isError }] = useRegistrationMutation()
  const [modal, setModal] = useState<'PRIVACY' | 'TERMS' | ''>('')
  const invite = localStorage.getItem('inviteToken')
  const userData = localStorage.getItem('newUserData')

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(registrationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      agreement: false,
      terms: false,
    },
  })

  const areCheckboxesChecked =
    role !== RolesGroups.SELLER ? watch(['terms']) : watch(['agreement', 'terms'])

  useEffect(() => {
    if (invite && userData && role !== RolesGroups.SELLER) {
      const info = JSON.parse(userData)
      reset({
        firstName: info?.customerFirstName || '',
        lastName: info?.customerLastName || '',
        email: info?.customerEmail || '',
      })
    }
  }, [invite])

  const onSubmit = ({ firstName, lastName, email, password, passwordConfirmation }: FormData) => {
    registration({
      role,
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      ...(role !== RolesGroups.SELLER && invite && { token: JSON.parse(invite) }),
    })
      .unwrap()
      .then(() => {
        if (invite) {
          localStorage.removeItem('newUserData')
          KeycloakService.doLogin({
            redirectUri: `${location.protocol}//${location.host}/purchases`,
          })
        }
      })
      .catch((e) => {
        showNotification('Registration failed', 'error')
      })
  }
  const successBlock = (
    <Box
      sx={{
        mt: '4rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: { xs: '2rem', sm: '3.25rem' },
        height: '40rem',
      }}
    >
      <Avatar sx={{ m: '0.5rem', bgcolor: 'success.main' }}>
        <DoneOutlineIcon />
      </Avatar>
      <Typography component="h1" variant="h6" textAlign="center">
        We have sent verification email to you. Please check your email to complete registration.
      </Typography>
    </Box>
  )

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        //alignItems: 'center',
        //minHeight: '100vh',
        justifyContent: 'center',
        bgcolor: '#f7f5f7',
      }}
    >
      <Box sx={{ maxWidth: '34.5rem', width: '100%', mb: { xs: '0rem', sm: '2rem' } }}>
        {isSuccess && role !== RolesGroups.REVIEWER && successBlock}
        {!isSuccess && (
          <Box
            sx={{
              mt: { xs: '5rem', sm: '7rem' },
              display: 'flex',
              flexDirection: 'column',
              p: { xs: '2rem', sm: '3.25rem' },
              bgcolor: '#fff',
              borderRadius: '0.25rem',
            }}
          >
            <Typography component="h1" variant="h5" sx={{ fontWeight: '700' }}>
              Sign up as a {userRoles[role]}
            </Typography>
            {isError && (
              <Box sx={{ width: '100%', pt: '1rem' }}>
                <Alert variant="filled" severity="error">
                  {isErrorWithMessage(error) ? error.data.detail : 'Oops! Something went wrong'}
                </Alert>
              </Box>
            )}
            {role !== RolesGroups.REVIEWER && (
              <Typography
                sx={{
                  color: '#EA1616',
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  textAlign: 'center',
                  my: 1,
                }}
              >
                Registration at our platform is available only for sellers. Reviewers should proceed
                to a Shopify store and purchase a product first, then you&apos;ll be invited to this
                service.
              </Typography>
            )}
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: '1.5rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <TextInput
                label="First Name"
                name="firstName"
                control={control}
                errors={errors}
                disabled={isLoading || role === 'reviewers_group'}
              />

              <TextInput
                label="Last Name"
                name="lastName"
                control={control}
                errors={errors}
                disabled={isLoading || role === 'reviewers_group'}
              />

              <TextInput
                label="Email Address"
                name="email"
                control={control}
                errors={errors}
                disabled={isLoading || role === 'reviewers_group'}
              />

              <TextInput
                label="Password"
                name="password"
                type="password"
                control={control}
                errors={errors}
                disabled={isLoading}
              />

              <TextInput
                label="Confirm Password"
                name="passwordConfirmation"
                type="password"
                control={control}
                errors={errors}
                disabled={isLoading}
              />

              <CustomCheckbox
                label={
                  <Box>
                    Receive email notifications
                    <Typography sx={{ fontWeight: 400, fontSize: '0.75rem', color: 'grey.600' }}>
                      News, Updates, Promotions
                    </Typography>
                  </Box>
                }
                name="agreement"
                control={control}
                errors={errors}
                disabled={isLoading}
              />

              <CustomCheckbox
                label={
                  <Box sx={{ display: 'inline-flex', gap: '0.2rem' }}>
                    <Typography
                      sx={{
                        color: '#1771CC',
                        fontWeight: 400,
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setModal('TERMS')
                      }}
                    >
                      Terms of Service
                    </Typography>
                    <Typography>and</Typography>
                    <Typography
                      sx={{
                        color: '#1771CC',
                        fontWeight: 400,
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setModal('PRIVACY')
                      }}
                    >
                      Privacy Policy
                    </Typography>
                  </Box>
                }
                name="terms"
                control={control}
                errors={errors}
                disabled={isLoading}
              />

              <Button
                type="submit"
                fullWidth
                sx={{ mt: '1.5rem', mb: '1rem' }}
                disabled={isLoading || !areCheckboxesChecked.every((checkbox) => checkbox)}
              >
                Sign Up
              </Button>
              <Box>
                <Typography
                  component="span"
                  sx={{ fontSize: '1.125rem', fontWeight: '600', pr: '1rem' }}
                >
                  Already have an account?
                </Typography>
                <Link
                  href="#"
                  color="primary"
                  sx={{ fontSize: '1.125rem', fontWeight: 600 }}
                  onClick={() => KeycloakService.doLogin()}
                >
                  Sign in
                </Link>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {modal === 'PRIVACY' && <PrivacyPolicyModal onClose={() => setModal('')} />}
      {modal === 'TERMS' && <TermsAndConditionsModal onClose={() => setModal('')} />}
    </Box>
  )
}

export default Registration
