import StarIcon from '@mui/icons-material/Star'
import { Box, Typography, keyframes } from '@mui/material'

import { DEMO_STORE_RUNNING_LINE } from 'constants/guest-layout'

const scroll = keyframes`
  from {transform: translateX(0);}
  to {transform: translateX(-170.055rem);}
`

const styles = {
  wrap: {
    width: '100%',
    p: '1.125rem 0',
    position: 'relative',
    bgcolor: '#CBFA92',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'nowrap',
    gap: '1.5rem',
    overflow: 'hidden',
  },
  itemsWrap: {
    display: 'flex',
    gap: '1.5rem',
    whiteSpace: 'nowrap',
    width: '168.555rem',
    animation: `${scroll} 20s linear infinite`,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1.5rem',
  },
  text: { fontSize: '1.25rem', fontWeight: 600 },
}

const RunningLine = () => {
  return (
    <Box sx={styles.wrap}>
      <Box sx={{ ...styles.itemsWrap }}>
        {DEMO_STORE_RUNNING_LINE.map((item) => (
          <Box key={item} sx={styles.item}>
            <Typography component="span" sx={styles.text}>
              {item}
            </Typography>
            <Typography component="span">
              <StarIcon />
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ ...styles.itemsWrap }}>
        {DEMO_STORE_RUNNING_LINE.map((item) => (
          <Box key={item} sx={styles.item}>
            <Typography component="span" sx={styles.text}>
              {item}
            </Typography>
            <Typography component="span">
              <StarIcon />
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default RunningLine
