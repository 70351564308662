import { FC } from 'react'

import { Box, Typography } from '@mui/material'

import { reviewStatusStyles } from 'constants/reviewStatusRender'

const ReviewsStatus: FC<{ status: keyof typeof reviewStatusStyles }> = ({ status }) => {
  return (
    <Box component="span" sx={{ p: '0.25rem 0.375rem', borderRadius: '0.25rem' }}>
      <Typography
        variant="caption"
        sx={{
          fontFamily: 'Inter',
          fontSize: '0.75rem',
          fontWeight: '700',
          lineHeight: '120%',
          textAlign: 'left',
          color: reviewStatusStyles[status].color,
        }}
      >
        {reviewStatusStyles[status].text}
      </Typography>
    </Box>
  )
}

export default ReviewsStatus
