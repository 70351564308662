import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Card,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import { grey } from '@mui/material/colors'

import formatDate from 'utils/formatToLocalTimezone'

import KeycloakService from 'services/KeycloakService'
import { ListReviewsRecord } from 'services/reviews/types'

import CardPreloader from 'components/common/CardPreloader'

import ReviewsStatus from './ReviewStatus'

const cellStyle = { fontSize: '0.875rem', fontWeight: 600, whiteSpace: 'nowrap' }

interface Props {
  reviews: ListReviewsRecord[]
  dashboard: 'REVIEWER' | 'SELLER'
  isLoading?: boolean
}

const LatestReviews: FC<Props> = ({ reviews, dashboard, isLoading = false }) => {
  const navigate = useNavigate()

  return (
    <Card sx={{ height: '100%', position: 'relative' }}>
      {isLoading && <CardPreloader />}
      <Box
        sx={{ display: 'flex', gap: '1rem', flexDirection: 'column', height: '100%', p: '1.25rem' }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.5rem' }}>
          Latest Reviews
        </Typography>
        {reviews.length ? (
          <TableContainer>
            <Table sx={{ minWidth: '40.625rem' }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: grey[200] }}>
                <TableRow>
                  <TableCell sx={cellStyle}>Date</TableCell>
                  <TableCell sx={{ ...cellStyle, align: 'left' }}>Shop</TableCell>
                  <TableCell sx={{ ...cellStyle, align: 'left' }}>Product</TableCell>
                  <TableCell sx={{ ...cellStyle, align: 'left' }}>
                    {dashboard === 'SELLER' ? 'Reviewer' : 'Seller'}
                  </TableCell>
                  <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviews.map((review) => (
                  <TableRow
                    key={review.id}
                    onClick={() => {
                      navigate(
                        `/${KeycloakService.hasRole(['Seller']) ? '' : 'my-'}reviews/${review.id}`
                      )
                    }}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: grey[100] },
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row" sx={cellStyle}>
                      {formatDate(review.updatedAt, 'dd MMM, yyyy')}
                    </TableCell>
                    <TableCell sx={{ ...cellStyle, align: 'left' }}>{review.shopName}</TableCell>
                    <TableCell sx={{ ...cellStyle, align: 'left' }}>{review.productName}</TableCell>
                    <TableCell sx={{ ...cellStyle, align: 'left' }}>
                      {dashboard === 'SELLER'
                        ? `${review.userFirstName} ${review.userLastName}`
                        : `${review.sellerFirstName} ${review.sellerLastName}`}
                    </TableCell>
                    <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                      <ReviewsStatus status={review.status} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              py: '5rem',
            }}
          >
            There is no latest reviews yet.
          </Box>
        )}
      </Box>
    </Card>
  )
}

export default LatestReviews
