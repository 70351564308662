import { FC } from 'react'

import { Box, Typography } from '@mui/material'

import { couponsStatusStyle } from 'constants/couponsStatusStyle'

import { CouponStatus as StatusesEnum } from 'services/coupons/types'

interface Props {
  status: StatusesEnum
}

const CouponStatus: FC<Props> = ({ status }) => {
  return (
    <Box
      sx={{
        borderRadius: '0.75rem',
        width: '4rem',
        height: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: couponsStatusStyle[status].color.main,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: couponsStatusStyle[status].color.text,
          fontSize: '0.75rem',
          fontWeight: '700',
        }}
      >
        {couponsStatusStyle[status].text}
      </Typography>
    </Box>
  )
}

export default CouponStatus
