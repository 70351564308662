import { baseApi } from 'api/api'

import { AdminDashboardStats } from './types'

export const balanceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminDashboardStats: build.query<AdminDashboardStats, void>({
      query: () => ({
        url: '/admin/stats/total_counts',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetAdminDashboardStatsQuery } = balanceApi
