import { FC, ReactNode } from 'react'

import { BoxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

interface Props extends BoxProps {
  children?: string | ReactNode
  paperMaxWidth?: string
}

const PageBox: FC<Props> = ({ children, sx, paperMaxWidth, ...rest }) => {
  return (
    <Paper sx={{ maxWidth: paperMaxWidth }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          padding: { xs: '1rem 1.5rem', sm: '2.25rem 3rem' },
          borderRadius: '1.25rem',
          mb: '1.5rem',
          ...sx,
        }}
        {...rest}
      >
        {children}
      </Box>
    </Paper>
  )
}

export default PageBox
