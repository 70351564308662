import { ReviewStatus } from 'services/reviews/types'

export const sellerStatusFilters = [
  {
    label: 'Approved',
    status: ReviewStatus.APPROVED,
  },
  {
    label: 'On Moderation',
    status: ReviewStatus.MODERATION,
  },
  {
    label: 'Returned',
    status: ReviewStatus.RETURNED,
  },
  {
    label: 'Rejected',
    status: ReviewStatus.REJECTED,
  },
]
