import { FC, useState } from 'react'

import { Box } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetSalesAdminQuery } from 'services/admin/sales/api'

import PageHeader from 'components/common/PageHeader'
import SearchInput from 'components/common/SearchInput'
import SendMailToReviewerModal from 'components/reviewers/SendMailToReviewerModal'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { createSaleColumns } from './columns'

const AdminSales: FC = () => {
  const { queryParams, pagination, sort, search, setPagination, setSort, setSearch, isFiltered } =
    useDataGridPagination()

  const { data, isLoading, isFetching } = useGetSalesAdminQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  })

  const [reviewerEmail, setReviewerEmail] = useState<string>('')
  const [productId, setProductId] = useState<string>('')
  const [showSendMailModal, setShowSendMailModal] = useState<boolean>(false)

  const handleSendMailToReviewerModal = (reviewerEmail: string, productId: string) => {
    setReviewerEmail(reviewerEmail)
    setProductId(productId)
    setShowSendMailModal(true)
  }

  const columns = createSaleColumns(handleSendMailToReviewerModal)

  return (
    <>
      <PageHeader title="Sales" wrapperSx={{ justifyContent: 'flex-start', gap: '1rem' }} />
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mb: '1.5rem' }}>
          <SearchInput initValue={search} onSearchChange={setSearch} />
        </Box>
        <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
          <CustomDataGrid
            data={data}
            columns={columns}
            loading={isLoading || isFetching}
            paginationModel={pagination}
            setPagination={setPagination}
            setSort={setSort}
            initSort={sort}
            isFiltered={isFiltered}
            rowHeight={72}
          />
        </Box>
      </Box>
      {showSendMailModal && (
        <SendMailToReviewerModal
          productId={productId}
          customerEmail={reviewerEmail}
          onClose={() => setShowSendMailModal(false)}
        />
      )}
    </>
  )
}

export default AdminSales
