import { skipToken } from '@reduxjs/toolkit/query'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Alert, Paper, List, ListItem, Typography } from '@mui/material'

import KeycloakService from 'services/KeycloakService'
import { isErrorWithMessage } from 'services/errorHelperService'
import { useCheckInviteQuery } from 'services/invites/api'

import Button from 'components/common/Button'
import Preloader from 'components/common/Preloader'
import PublicAppBar from 'components/public-layout/PublicAppBar'

export const InviteCheck = () => {
  const navigate = useNavigate()

  const { token } = useParams()

  const { data, error, isLoading } = useCheckInviteQuery(token ? { token } : skipToken, {
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (data) {
      if (data.isUserExists) {
        localStorage.setItem('inviteToken', JSON.stringify(data.token))
        KeycloakService.doLogin({ redirectUri: `${location.protocol}//${location.host}/purchases` })
      } else {
        localStorage.setItem('newUserData', JSON.stringify(data))
        localStorage.setItem('inviteToken', JSON.stringify(data.token))
        navigate('/reviewer-registration')
      }
    }
  }, [data, error])
  return (
    <>
      <Box sx={{ bgcolor: '#000' }}>
        <PublicAppBar />
      </Box>
      <Box
        sx={{
          width: '100%',
          minHeight: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2.5rem',
          py: { xs: '6rem', sm: '7rem' },
        }}
      >
        {isLoading && (
          <Box
            sx={{ height: 'fit-content', display: 'flex', gap: '1.5rem', flexDirection: 'column' }}
          >
            <Preloader parentHeight="50" />
            Checking your invite. Please, wait.
          </Box>
        )}
        {!!error && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
            <Alert sx={{ px: '4rem' }} variant="filled" severity="error">
              *Attention:{' '}
              {isErrorWithMessage(error) ? error.data.detail : 'Oops! Something went wrong'}*
            </Alert>
            <Paper
              sx={{
                maxWidth: '70rem',
                p: { xs: '1rem', sm: '2rem' },
                m: { xs: '1rem', sm: '2rem' },
              }}
            >
              We regret to inform you that an error occurred with your current invite. This issue
              can arise due to several reasons:
              <List>
                <ListItem sx={{ gap: '0.2rem' }}>
                  <Typography>
                    <Typography component="span" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
                      Invalid Invite:
                    </Typography>{' '}
                    <Typography component="span">
                      Ensure that the invite link you received is correct. Sometimes, invite links
                      can be mistyped or broken. Double-check the URL and try again.
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem sx={{ gap: '0.2rem' }}>
                  <Typography>
                    <Typography component="span" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
                      Expired Invite:
                    </Typography>{' '}
                    <Typography component="span">
                      Unfortunately, your invite has expired. Our invites are time-sensitive for
                      security reasons. You will need to wait for a new invitation to be sent to
                      your email.
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem sx={{ gap: '0.2rem' }}>
                  <Typography>
                    <Typography component="span" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
                      Already Activated:
                    </Typography>{' '}
                    <Typography component="span">
                      It looks like this invite has already been used. Please log in to your account
                      and check the Purchases page to see if the new card has been added. If you are
                      still facing issues, contact our support team for assistance.
                    </Typography>
                  </Typography>
                </ListItem>
              </List>
              We apologize for any inconvenience this may cause and appreciate your understanding as
              we work to resolve these issues. Thank you for choosing our service.
            </Paper>
            <Button href="/reviewers">Return to Landing</Button>
          </Box>
        )}
      </Box>
    </>
  )
}
