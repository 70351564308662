import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'

import {
  REVIEWER_LANDING_FAQ,
  LANDING_TEXT,
  HOW_IT_WORKS_REVIEWER,
  LANDING_KEY_FEATURES,
  LANDING_REVIEWER_BENEFITS,
} from 'constants/guest-layout'

import Accordion from 'components/common/Accordion'
import ColorBox from 'components/common/ColorBox'
import LandingTitle from 'components/common/LandingTitle'
import { HowItWorks } from 'components/public-layout/HowItWorks'
import LandingCarousel from 'components/public-layout/LandingCarousel'

const ReviewerLanding = () => {
  return (
    <>
      <LandingCarousel
        title={LANDING_TEXT.forReviewerPage.title}
        text={LANDING_TEXT.forReviewerPage.text}
        carouselItems={LANDING_TEXT.forReviewerPage.carouselItems}
      />

      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5rem',
          alignItems: 'center',
          pb: '5rem',
        }}
      >
        <Box
          id="benefits"
          sx={{
            width: '100%',
            pt: '5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            alignItems: 'center',
          }}
        >
          <LandingTitle text="Benefits" />
          <Typography
            sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              lineHeight: 1.5,
              textAlign: 'center',
            }}
          >
            Join our community of reviewers and enjoy the following benefits
          </Typography>
          <Box sx={{ display: 'flex', gap: '4rem 1.5rem', flexWrap: 'wrap', mt: '1.5rem' }}>
            {LANDING_REVIEWER_BENEFITS.map((benefit) => (
              <ColorBox
                key={benefit.text}
                sx={{
                  p: '4rem 1.5rem',
                  position: 'relative',
                  bgcolor: '#000000',
                  color: '#FFFFFF',
                  minWidth: '21.5rem',
                  width: '100%',
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                }}
              >
                <Box sx={{ position: 'absolute', top: '-3.125rem', left: '1rem' }}>
                  {benefit.icon}
                </Box>
                {benefit.text}
              </ColorBox>
            ))}
          </Box>
        </Box>

        <Box
          id="howItWorks"
          sx={{
            width: '100%',
            pt: '5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            alignItems: 'center',
          }}
        >
          <LandingTitle text="How It Works?" />
          <HowItWorks items={HOW_IT_WORKS_REVIEWER} direction="column" textMaxWidth={464} />
        </Box>

        <Box
          id="features"
          sx={{ width: '100%', pt: '5rem', display: 'flex', flexDirection: 'column', gap: '3rem' }}
        >
          <LandingTitle text="Key Features" />
          <Box sx={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap' }}>
            {LANDING_KEY_FEATURES.map((item) => (
              <ColorBox
                key={item.text}
                sx={{
                  bgcolor: grey[200],
                  minWidth: '20rem',
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                }}
              >
                {item.text}
              </ColorBox>
            ))}
          </Box>
        </Box>

        <Box
          id="faq"
          sx={{ width: '100%', pt: '5rem', display: 'flex', flexDirection: 'column', gap: '3rem' }}
        >
          <LandingTitle text="FAQ" />
          <Box>
            {REVIEWER_LANDING_FAQ.map((faq) => (
              <Accordion key={faq.summary} summary={faq.summary} details={faq.details} />
            ))}
          </Box>
        </Box>

        <ColorBox
          sx={{
            p: '3rem',
            flexDirection: 'column',
            bgcolor: '#CBFA92',
            width: '100%',
            textAlign: 'justify',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '1.25rem', md: '2rem' },
              fontWeight: 800,
              mb: { xs: '2rem', md: '0.5rem' },
            }}
          >
            Get Invited to Review Today
          </Typography>
          <Typography
            sx={{ fontSize: { xs: '1rem', md: '1.25rem' }, fontWeight: 500, textAlign: 'center' }}
          >
            Join our community of reviewers and start sharing your experiences. Receive an
            invitation from a Shopify shop owner and start earning rewards with ShopiView.
          </Typography>
        </ColorBox>
      </Container>
    </>
  )
}

export default ReviewerLanding
