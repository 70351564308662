import { FC, useEffect, useRef } from 'react'

import { Box, Paper, Typography, CircularProgress } from '@mui/material'

import { reviewStatusStyles } from 'constants/reviewStatusRender'

import formatDate from 'utils/formatToLocalTimezone'
import { transformReviewHistoryByDate } from 'utils/transformReviewHistory'

import KeycloakService from 'services/KeycloakService'
import { useGetHistoryRecordByReviewQuery } from 'services/reviews/api'
import { HistoryItemByDate, ReviewStatus } from 'services/reviews/types'

const ReviewHistory: FC<{ reviewId: string }> = ({ reviewId }) => {
  const { data, isLoading } = useGetHistoryRecordByReviewQuery(reviewId, {
    refetchOnMountOrArgChange: true,
  })
  const historyByDate: HistoryItemByDate = transformReviewHistoryByDate(data?.data || [])

  const currentUserRole = KeycloakService.hasRole(['Reviewer']) ? 'Reviewer' : 'Seller'

  const oppositeRole = currentUserRole === 'Reviewer' ? 'Seller' : 'Reviewer'

  const chooseMessageAlignment = (currentUserRole: string, messageStatus: number) => {
    const reviewerStatuses = [ReviewStatus.MODERATION]
    const sellerStatuses = [
      ReviewStatus.APPROVED,
      ReviewStatus.REJECTED,
      ReviewStatus.RETURNED,
      ReviewStatus.PENDING_TRANSFER,
    ]

    if (
      (currentUserRole === 'Reviewer' && reviewerStatuses.includes(messageStatus)) ||
      (currentUserRole === 'Seller' && sellerStatuses.includes(messageStatus))
    ) {
      return 'end'
    }
    return 'start'
  }

  const paperRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (data?.data.length) paperRef.current?.scroll({ top: paperRef.current.scrollHeight })
  }, [data?.data.length])

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: '51.5rem',
        border: '1px solid #262626',
        overflowY: 'auto',
        position: 'absolute',
        top: '0.5rem',
        left: 0,
        height: 'calc(100% - 0.5rem)',
      }}
      ref={paperRef}
    >
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            p: { xs: '1rem', sm: '2rem' },
            width: '100%',
            height: '100%',
          }}
        >
          {!Object.keys(historyByDate).length && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontFamily: 'Inter, sans-serif',
                  lineHeight: '1.5',
                  color: '#797D80',
                  textAlign: 'center',
                }}
              >
                The status of this video feedback hasn&apos;t been changed
              </Typography>
            </Box>
          )}
          {Object.keys(historyByDate).map((key) => (
            <Box key={key}>
              <Typography
                sx={{ pb: '0.5rem', fontSize: '0.75rem', color: '#828282', textAlign: 'center' }}
              >
                {formatDate(key, 'MMM dd, yyyy')}
              </Typography>

              {historyByDate[key].map(({ message, status, createdAt }) => (
                <Box key={createdAt}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: chooseMessageAlignment(currentUserRole, status),
                    }}
                  >
                    <Box
                      sx={{
                        mb: '0.625rem',
                        p: '0.5rem',
                        borderRadius: '0.5rem',
                        backgroundColor: '#F7F5F7',
                        maxWidth: '80%',
                      }}
                    >
                      <Typography
                        //component="span"
                        sx={{
                          mb: '0.5rem',
                          fontSize: '0.75rem',
                          color: '#828282',
                          fontWeight: 700,
                        }}
                      >
                        {chooseMessageAlignment(currentUserRole, status) === 'end'
                          ? currentUserRole
                          : oppositeRole}
                        :
                      </Typography>

                      <Typography component="span" sx={{ whiteSpace: 'nowrap', width: '100%' }}>
                        Status:{' '}
                        <Typography color={reviewStatusStyles[status].color} component="span">
                          {reviewStatusStyles[status].text}
                        </Typography>
                      </Typography>
                      {message && (
                        <Typography sx={{ wordWrap: 'break-word', width: '100%' }}>
                          Comments: {message}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      )}
    </Paper>
  )
}

export default ReviewHistory
