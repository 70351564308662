import { skipToken } from '@reduxjs/toolkit/query'
import { Link, useParams } from 'react-router-dom'

import { Box, Skeleton } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetCouponsAdminQuery } from 'services/admin/coupons'
import { useGetUserQuery } from 'services/users/api'

import PageHeader from 'components/common/PageHeader'
import SearchInput from 'components/common/SearchInput'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'

export const ReviewersCoupons = () => {
  const { reviewerId } = useParams()

  const { data: reviewer, isLoading: isSellerLoading } = useGetUserQuery(
    reviewerId ? { id: reviewerId } : skipToken
  )

  const { queryParams, pagination, sort, search, setPagination, setSort, setSearch, isFiltered } =
    useDataGridPagination()

  const { data, isLoading, isFetching } = useGetCouponsAdminQuery(
    reviewerId ? { reviewerId: reviewerId, ...queryParams } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  )

  return (
    <>
      <PageHeader
        title="Coupons"
        rightContent={
          isSellerLoading ? (
            <Skeleton width="10rem" />
          ) : (
            <Link
              to={`/admin/reviewers/${reviewer?.id}`}
            >{`${reviewer?.firstName} ${reviewer?.lastName}`}</Link>
          )
        }
        wrapperSx={{ justifyContent: 'flex-start', gap: '1rem' }}
      />
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mb: '1.5rem' }}>
          <SearchInput initValue={search} onSearchChange={setSearch} />
        </Box>
        <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
          <CustomDataGrid
            data={data}
            columns={columns}
            loading={isLoading || isFetching}
            paginationModel={pagination}
            setPagination={setPagination}
            setSort={setSort}
            initSort={sort}
            isFiltered={isFiltered}
            rowHeight={72}
          />
        </Box>
      </Box>
    </>
  )
}
