import { FC } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Box, Link, Paper, Tooltip, Typography } from '@mui/material'

import { reviewStatusStyles as status } from 'constants/reviewStatusRender'
import { MEDIA_URL } from 'constants/urls'

import formatDate from 'utils/formatToLocalTimezone'

import { ListReviewsRecord, ReviewStatus } from 'services/reviews/types'

import { EllipsedText } from 'components/common/EllipsedText'
import VideoPlayer from 'components/common/VideoPlayer'

import ReviewActions from '../ReviewActions'

interface Props {
  review: ListReviewsRecord
}

const ReviewItem: FC<Props> = ({ review }) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '32rem',
        width: '100%',
        height: '100%',
        padding: '1.5rem',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '0.5rem' }}>
        <Tooltip title={review.productName}>
          <Typography
            sx={{
              fontWeight: 500,
              mb: '1.5rem',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {review.productName}
          </Typography>
        </Tooltip>
        <Link href={`/reviews/${review.id}`} sx={{ whiteSpace: 'nowrap' }}>
          Show details
        </Link>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1rem',
          mb: '1.5rem',
          flexDirection: ['column', 'column', 'row', 'row', 'row'],
        }}
      >
        <Box sx={{ display: 'flex', minWidth: '0' }}>
          <Avatar
            alt="User Name"
            src={review.userProfileImageUrl ? `${MEDIA_URL}${review.userProfileImageUrl}` : ''}
            sx={{ width: '3rem', height: '3rem' }}
          >
            <PersonIcon />
          </Avatar>
          <Box sx={{ ml: '1rem', minWidth: '0' }}>
            <Tooltip title={`${review.userFirstName} ${review.userLastName}`}>
              <Typography
                component="div"
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  color: '#000000',
                  lineHeight: '1.2',
                  width: '100%',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {`${review.userFirstName} ${review.userLastName}`}
              </Typography>
            </Tooltip>

            <EllipsedText title={review.userEmail} sx={{ fontWeight: 700, color: '#BDBDBD' }} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: '1rem', gap: '0.5rem' }}>
            <Typography sx={{ lineHeight: 1 }}>Date:</Typography>
            <Typography sx={{ lineHeight: 1, fontWeight: 700 }}>
              {formatDate(review.updatedAt || review.createdAt, 'MM.dd.yy')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: review.status === ReviewStatus.PENDING_TRANSFER ? '0.7rem' : '1rem',
              gap: '0.5rem',
            }}
          >
            <Typography sx={{ lineHeight: 1 }}>Bonus:</Typography>
            <Typography sx={{ lineHeight: 1, fontWeight: 700 }}>
              ${review.price || review.shopDefaultReviewPrice}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              whiteSpace: 'nowrap',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <Typography sx={{ lineHeight: 1 }}>Status:</Typography>
            <Typography
              sx={{
                lineHeight: 1,
                fontWeight: 700,
                color: status[review.status].color,
                display: 'flex',
                alignItems: 'center',
                gap: '0.3rem',
              }}
            >
              {status[review.status].text}
              {review.status === ReviewStatus.PENDING_TRANSFER && (
                <Tooltip
                  title="Please wait for the money transfer to finish and refresh the page"
                  enterTouchDelay={0}
                >
                  <InfoIcon />
                </Tooltip>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mb: '0.5rem', gap: '0.3rem', width: '100%' }}>
        <Typography sx={{ lineHeight: 1, whiteSpace: 'nowrap' }}>Review name: </Typography>
        <Tooltip title={review.name || 'No title'}>
          <Typography
            sx={{
              lineHeight: 1,
              fontWeight: 700,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {review.name || 'No title'}
          </Typography>
        </Tooltip>
      </Box>

      <VideoPlayer src={`${MEDIA_URL}${review.mediaFile}`} sx={{ maxWidth: '29rem' }} />

      {review.status === ReviewStatus.MODERATION && (
        <ReviewActions
          reviewId={review.id}
          productName={review.productName}
          reviewPrice={review.price || review.shopDefaultReviewPrice}
        />
      )}
    </Paper>
  )
}

export default ReviewItem
