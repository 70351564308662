import { TableServerResponse } from 'types/Table'

import { baseApi } from 'api/api'

import { GetReviewsPayload } from 'services/reviews/types'

import { AdminReview } from './types'

export const adminReviewsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getReviewsAdmin: build.query<TableServerResponse<AdminReview[]>, GetReviewsPayload>({
      query: (params) => ({
        url: `/admin/reviews`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const { useGetReviewsAdminQuery } = adminReviewsApi
