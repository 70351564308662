import { FC, useState } from 'react'

import { Box, Card, CardContent, Typography, CardActions } from '@mui/material'

import { Received } from 'services/balance/types'

import Button from 'components/common/Button'
import BuyCouponModal from 'components/coupons/BuyCouponModal'

interface Props {
  received: Received
}

const ReceivedItem: FC<Props> = ({ received }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <>
      <Card sx={{ width: '100%', maxWidth: '21.5rem' }}>
        <CardContent sx={{ p: '1.5rem', display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: '600',
              fontSize: '1.5rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textWrap: 'nowrap',
            }}
          >
            {received.sellerName}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography fontSize="1rem">Balance</Typography>
            <Typography fontSize="1rem" fontWeight="bold">
              ${received.balance}
            </Typography>
          </Box>
          <CardActions sx={{ p: 0 }}>
            <Button fullWidth onClick={() => setShowModal(true)}>
              Buy
            </Button>
          </CardActions>
        </CardContent>
      </Card>
      {showModal && (
        <BuyCouponModal
          sellerId={received.sellerId}
          sellerName={received.sellerName}
          currencyCode={received.sellerCurrencyCode}
          maxAmount={received.balance}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

export default ReceivedItem
