import { ReactNode, useState } from 'react'
import { Outlet } from 'react-router-dom'

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { Box, useMediaQuery, Theme } from '@mui/material'
import { grey } from '@mui/material/colors'

import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from 'constants/main-layout'

import KeycloakService from 'services/KeycloakService'

import { ReactComponent as BagIcon } from 'assets/svg/sidebar/bag-handle-outline.svg'
import { ReactComponent as CartIcon } from 'assets/svg/sidebar/cart-outline.svg'
import { ReactComponent as CashIcon } from 'assets/svg/sidebar/cash-outline.svg'
import { ReactComponent as ChatboxIcon } from 'assets/svg/sidebar/chatbox-outline.svg'
import { ReactComponent as DiscountIcon } from 'assets/svg/sidebar/discount-percent.svg'
import { ReactComponent as HomeIcon } from 'assets/svg/sidebar/home.svg'
import { ReactComponent as PersonIcon } from 'assets/svg/sidebar/person-outline.svg'

import AppBar from 'components/main-layout/AppBar'
import SidebarMenu from 'components/main-layout/SidebarMenu'

export interface MenuItemType {
  text: string
  icon: ReactNode
  link: string
  protected?: boolean
}

const SellerMenuItems = [
  { text: 'Dashboard', icon: <HomeIcon />, link: `/dashboard` },
  { text: 'Shops', icon: <CartIcon />, link: `/shops` },
  { text: 'Finances', icon: <CashIcon />, link: `/balance` },
  { text: 'Profile', icon: <PersonIcon />, link: `/profile` },
]

const ReviewerMenuItems = [
  { text: 'Dashboard', icon: <HomeIcon />, link: `/dashboard` },
  { text: 'Reviews', icon: <ChatboxIcon />, link: `/my-reviews` },
  { text: 'Purchases', icon: <BagIcon />, link: `/purchases` },
  { text: 'Coupons', icon: <DiscountIcon />, link: `/coupons` },
  { text: 'Finances', icon: <CashIcon />, link: `${process.env.REACT_APP_CW_UI_URL}` },
  { text: 'Profile', icon: <PersonIcon />, link: `/profile` },
]

const AdminsMenuItems = [
  { text: 'Dashboard', icon: <HomeOutlinedIcon />, link: `/dashboard` },
  { text: 'Sellers', icon: <PeopleOutlineIcon />, link: `/admin/sellers` },
  { text: 'Reviewers', icon: <PeopleOutlineIcon />, link: `/admin/reviewers` },
  { text: 'All Shops', icon: <CartIcon />, link: `/admin/shops` },
  { text: 'All Products', icon: <BagIcon />, link: `/admin/products` },
  { text: 'Sales', icon: <ReceiptLongIcon />, link: `/admin/sales` },
]

const MainLayout = () => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [open, setOpen] = useState(isMd ? false : true)
  const menuItems: MenuItemType[] = KeycloakService.hasRole(['Admin'])
    ? AdminsMenuItems
    : KeycloakService.hasRole(['Seller'])
      ? SellerMenuItems
      : ReviewerMenuItems

  const handleDrawerOpen = (state: boolean) => {
    setOpen(state)
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <SidebarMenu open={open} menuItems={menuItems} onClick={() => handleDrawerOpen(false)} />
      <Box
        component="main"
        sx={{
          background: grey[100],
          width: `calc(100% - ${open ? DRAWER_WIDTH : isSm ? '0' : MINI_DRAWER_WIDTH}px)`,
          flexGrow: 1,
          p: { xs: '1rem', sm: '2rem' },
          pt: { xs: '6rem', sm: '7rem' },
          pb: { xs: '2.5rem', sm: '3.5rem' },
        }}
      >
        <Box
          sx={{
            width: '100%',
            minHeight: 'calc(100vh - 10.5rem)',
            display: 'inline-block',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default MainLayout
