import { useNavigate } from 'react-router-dom'

import { Box, Typography, Container } from '@mui/material'

import {
  LANDING_SELLER_BENEFITS,
  LANDING_TEXT,
  SELLER_LANDING_FAQ,
  HOW_IT_WORKS_SELLER,
} from 'constants/guest-layout'

import Accordion from 'components/common/Accordion'
import Button from 'components/common/Button'
import ColorBox from 'components/common/ColorBox'
import LandingTitle from 'components/common/LandingTitle'
import { HowItWorks } from 'components/public-layout/HowItWorks'
import LandingCarousel from 'components/public-layout/LandingCarousel'
import SellerPlansPricing from 'components/public-layout/SellerPlansPricing'

const SellerLanding = () => {
  const navigate = useNavigate()
  return (
    <>
      <LandingCarousel
        title={LANDING_TEXT.forSellerPage.title}
        text={LANDING_TEXT.forSellerPage.text}
        buttonText={LANDING_TEXT.forSellerPage.buttonText}
        carouselItems={LANDING_TEXT.forSellerPage.carouselItems}
        secondButtonText="Watch a demo"
        secondButtonAction={() => navigate('/demo-store')}
      />

      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5rem',
          alignItems: 'center',
          margin: '0 auto',
        }}
      >
        <Box
          id="benefits"
          sx={{
            width: '100%',
            pt: '5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            alignItems: 'center',
          }}
        >
          <Box>
            <LandingTitle text="Benefits" />
            <Typography
              sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                lineHeight: 1.5,
                textAlign: 'center',
                mt: '0.5rem',
              }}
            >
              Join our community of sellers and enjoy the following benefits
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '4rem 1.5rem',
              flexWrap: 'wrap',
              mt: '1.5rem',
              width: '100%',
            }}
          >
            {LANDING_SELLER_BENEFITS.map((benefit) => (
              <ColorBox
                sx={{
                  width: '100%',
                  p: '4rem 1.25rem',
                  position: 'relative',
                  bgcolor: '#000000',
                  color: '#FFFFFF',
                  minWidth: '21.5rem',
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                }}
                key={benefit.text}
              >
                <Box sx={{ position: 'absolute', top: '-3.125rem', left: '1rem' }}>
                  {benefit.icon}
                </Box>
                {benefit.text}
              </ColorBox>
            ))}
          </Box>
        </Box>

        <Box
          id="howItWorks"
          sx={{
            width: '100%',
            pt: '5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            alignItems: 'center',
          }}
        >
          <LandingTitle text="How It Works?" />
          <HowItWorks items={HOW_IT_WORKS_SELLER} direction="column" textMaxWidth={462} />
        </Box>
      </Container>

      <Box
        sx={{
          backgroundColor: '#262626',
          p: '3rem 4rem',
          m: '5rem 0 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '1.25rem', md: '2rem' },
            fontWeight: 700,
            color: '#FFFFFF',
            textAlign: 'center',
          }}
        >
          Limited Time Offer:
          <br />
          Sign Up Now and Enjoy Our Exclusive Offer!
        </Typography>
        <Typography
          sx={{ fontSize: '1rem', fontWeight: 600, color: '#FFFFFF', textAlign: 'center' }}
        >
          Take advantage of our limited-time offer and join ShopiView for free!
        </Typography>
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5rem',
          alignItems: 'center',
          margin: '0 auto',
          pb: '5rem',
        }}
      >
        <SellerPlansPricing />

        <Box
          id="faq"
          sx={{
            width: '100%',
            pt: '2.5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
          }}
        >
          <LandingTitle text="FAQ" />
          <Box>
            {SELLER_LANDING_FAQ.map((faq) => (
              <Accordion key={faq.summary} summary={faq.summary} details={faq.details} />
            ))}
          </Box>
        </Box>

        <ColorBox
          sx={{
            p: '3rem',
            flexDirection: 'column',
            bgcolor: '#CBFA92',
            width: '100%',
            textAlign: 'justify',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{ fontSize: { xs: '1.25rem', md: '2rem' }, fontWeight: '700', mb: '0.5rem' }}
          >
            Start Boosting Your Sales Today
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '1rem', md: '1.25rem' },
              fontWeight: '500',
              mb: '1.5rem',
              textAlign: 'center',
            }}
          >
            Getting started with <b>ShopiView</b> is simple. Just sign up for an account, integrate
            your Shopify store by installing our plugin, and start requesting video reviews from
            your customers.
          </Typography>
          <Button onClick={() => navigate('/seller-registration')} sx={{ borderRadius: '1.5rem' }}>
            Start as a Seller
          </Button>
        </ColorBox>
      </Container>
    </>
  )
}

export default SellerLanding
