export const SELLER_FAQ = [
  {
    summary: 'How do I add a new product to my ShopiView account?',
    details:
      'Adding a new product to your ShopiView account is easy. Simply navigate to your sidebar, select the "Shops", choose the “All shops” tab, and click on the "Add Shop" button. From there, you can enter the Shop details and link it to your Shopify store. Then click on the “Sync with Shopify” button for seamless synchronization.',
  },
  {
    summary: 'Can I customize the reward value for video feedback?',
    details:
      "You have the flexibility to customize the reward value for video feedback. Simply navigate to your Shop’s settings and you'll find options to adjust the reward amount based on your preferences.",
  },
  {
    summary: 'What is the "Online Wallet" feature, and how does it work?',
    details:
      'The "Online Wallet" is a financial service integrated with ShopiView to streamline transactions and manage finances for sellers. It allows you to track earnings, process payments to reviewers, and maintain a centralized account for your ShopiView activities.',
  },
  {
    summary: 'How can I set up email notifications for customers who purchase my products?',
    details:
      "Setting up email notifications is simple with ShopiView. Once you've linked your Shopify store, our platform automatically sends email reminders to customers at strategic intervals, encouraging them to leave video feedback. You can customize the notification settings and frequency to suit your preferences.",
  },
  {
    summary: 'How are video reviews moderated?',
    details:
      'Video reviews are moderated directly by you, the seller. Upon submission, you review the video and provide feedback or suggestions for improvement. If adjustments are needed, you may return the video to the reviewer with comments for further refinement. This process continues until you approve the video review for publication.',
  },
  {
    summary: 'What should I do if I encounter technical issues with ShopiView?',
    details:
      'If you encounter any technical issues while using ShopiView, we recommend reaching out to our support team for assistance by clicking the “Leave Feedback” button.. Our dedicated support staff is available to troubleshoot issues, provide guidance, and ensure your experience with our platform is seamless.',
  },
  {
    summary: 'Is my personal information secure with ShopiView?',
    details:
      'Yes, protecting your personal information is our top priority. We adhere to strict security measures and protocols to safeguard your data against unauthorized access, disclosure, or misuse. Rest assured that your privacy and confidentiality are paramount to us.',
  },
  {
    summary: 'How can I provide feedback or suggestions for improving ShopiView?',
    details:
      'We value your feedback and suggestions for enhancing our platform. You can provide your input by using the feedback form available on our website. Your insights help us continually improve and refine ShopiView to better meet your needs.',
  },
  {
    summary: 'Are there any restrictions on the content of video reviews?',
    details:
      'While we encourage honest and constructive feedback from our users, certain restrictions may apply to the content of video reviews. You, as a Seller, and a future owner of this content are going to be the main moderator of a video content that you’d like to be added to your shopify store.',
  },
  {
    summary: 'Can I use ShopiView on mobile devices?',
    details:
      'Answer: Yes, you can access ShopiView on mobile devices. However, please note that we are continuously working on enhancing and optimizing our platform for mobile usage.',
  },
]

export const REVIEWER_FAQ = [
  {
    summary: 'How do I record and submit a video review?',
    details:
      "Recording and submitting a video review is easy! Simply follow the link provided in your invitation email, or log in to your ShopiView account. Then, use your device's camera to record your feedback, add any necessary details, and submit it through our platform.",
  },
  {
    summary: 'Can I edit or delete my video review after submission?',
    details:
      "Unfortunately, once you've submitted your video review, it cannot be edited or deleted. We encourage you to take your time and ensure your feedback is complete and accurate before submitting. Although the seller may return it to you for further improvements after reviewing your feedback.",
  },
  {
    summary: 'How long should my video review be?',
    details:
      "There's no specific length requirement for video reviews, but we recommend keeping them concise and focused on the key aspects of the product. Aim for clarity and provide relevant details to help other shoppers.",
  },
  {
    summary: 'How can I track the status of my submitted video reviews?',
    details:
      "Once you've submitted a video review, you can track its status by logging in to your ShopiView account. You'll be able to enter the Reviews page and see the status of your recent feedback.",
  },
  {
    summary: 'How are rewards calculated, and when do I receive them?',
    details:
      "Rewards are calculated based on the price that the seller sets for a video feedback. Once your feedback is approved, you'll receive rewards. These rewards can then be redeemed for discounts at participating stores.",
  },
  {
    summary: 'Can I track my earnings and manage my reviews?',
    details:
      'Yes, absolutely! Our platform provides a user-friendly dashboard where you can conveniently track your earnings, manage your reviews, and stay updated on the status of your submissions.',
  },
  {
    summary: 'Why should I leave video reviews instead of written ones?',
    details:
      'Answer: Video reviews offer a more engaging and informative way to share your experiences with products. They allow you to showcase the product in action, provide visual demonstrations, and convey your thoughts and emotions effectively.',
  },
]

export const subscriptionFAQ = [
  {
    summary: 'Can I cancel my subscription plan?',
    details:
      'Yes, you can cancel your subscription at any time by opening your dashboard and clicking “Manage Subscription.” Alternatively, go to the Shopify Partners app, click "Manage App," navigate to Pricing Plans, and select the option to cancel. Please note that subscriptions are not refundable',
  },
  {
    summary: 'Can I upgrade my subscription plan?',
    details:
      'Currently, we offer only one paid plan. However, we’re always looking to expand our offerings, so stay tuned for future updates!',
  },
  {
    summary: 'How can I delete my account?',
    details:
      'You don’t need to worry about account deletion. If your profile remains inactive for one month, it will be automatically blocked',
  },
  {
    summary: 'What happens to my unused subscription amount?',
    details:
      'Your subscription gives you access to our service and remains active until you cancel it. Unused subscription time or features are not refundable',
  },
  {
    summary: 'At which point in time can I cancel my subscription?',
    details:
      'You can cancel your subscription at any time. However, please note that no refunds will be issued for the remaining subscription period',
  },
]
