import { format } from 'date-fns'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Grid } from '@mui/material'
import { teal } from '@mui/material/colors'

// import { subscription } from 'constants/mockData'
import { reviewStatusStyles } from 'constants/reviewStatusRender'
import { DAYS_IN_WEEK, HOURS_IN_DAY, MINS_IN_HOUR, MSECS_IN_SEC, SECS_IN_MIN } from 'constants/time'

import { useGetPopularProductsQuery } from 'services/products/api'
import { useGetReviewsCountQuery, useGetReviewsQuery } from 'services/reviews/api'
import { ReviewStatus } from 'services/reviews/types'
import { useGetSalesCountQuery } from 'services/sales/api'
import { useGetUsersCountQuery, useSellerPaymentsWithNullQuery } from 'services/users/api'

import { ReactComponent as CircleCheckIcon } from 'assets/svg/stat_cart_circle_check.svg'
import { ReactComponent as MessageIcon } from 'assets/svg/stat_cart_mess.svg'
import { ReactComponent as ShopCartIcon } from 'assets/svg/stat_cart_shop.svg'
import { ReactComponent as UsersIcon } from 'assets/svg/stat_cart_users.svg'

import PageHeader from 'components/common/PageHeader'
import Finances from 'components/dashboard/Finances'
import LatestReviews from 'components/dashboard/LatestReviews'
import PopularProducts from 'components/dashboard/PopularProducts'
import StatisticsCard from 'components/dashboard/StatisticsCards'
import Subscription from 'components/dashboard/Subscription'

const SellerDashboard: FC = () => {
  const { data: userCountTotal, isLoading: isUserCountLoading } = useGetUsersCountQuery(
    {},
    { refetchOnMountOrArgChange: true }
  )
  const { data: userCountLastWeek, isLoading: isUserCountLastWeekLoading } = useGetUsersCountQuery(
    {
      startDate: format(
        new Date(
          Date.now() - MSECS_IN_SEC * SECS_IN_MIN * MINS_IN_HOUR * HOURS_IN_DAY * DAYS_IN_WEEK
        ),
        'yyyy-MM-dd'
      ),
      endDate: format(Date.now(), 'yyyy-MM-dd'),
    },
    { refetchOnMountOrArgChange: true }
  )
  const { data: reviewsCountTotal, isLoading: isReviewsCountTotalLoading } =
    useGetReviewsCountQuery({}, { refetchOnMountOrArgChange: true })
  const { data: reviewsCountLastWeek, isLoading: isReviewsCountLastWeekLoading } =
    useGetReviewsCountQuery(
      {
        startDate: format(
          new Date(
            Date.now() - MSECS_IN_SEC * SECS_IN_MIN * MINS_IN_HOUR * HOURS_IN_DAY * DAYS_IN_WEEK
          ),
          'yyyy-MM-dd'
        ),
        endDate: format(Date.now(), 'yyyy-MM-dd'),
      },
      { refetchOnMountOrArgChange: true }
    )
  const { data: latestReviews, isLoading: isLatestReviewsLoading } = useGetReviewsQuery(
    {
      page: 0,
      itemsPerPage: 5,
      sortField: 'updatedAt',
      sortDir: 'desc',
    },
    { refetchOnMountOrArgChange: true }
  )

  const { data: reviewsOnModerationCountTotal, isLoading: isReviewsOnModerationCountTotalLoading } =
    useGetReviewsCountQuery({ status: 1 }, { refetchOnMountOrArgChange: true })

  const { data: salesCountTotal, isLoading: isSalesCountTotalLoading } = useGetSalesCountQuery(
    {},
    { refetchOnMountOrArgChange: true }
  )
  const { data: salesCountLastWeek, isLoading: isSalesCountLastWeekLoading } =
    useGetSalesCountQuery(
      {
        startDate: format(
          new Date(
            Date.now() - MSECS_IN_SEC * SECS_IN_MIN * MINS_IN_HOUR * HOURS_IN_DAY * DAYS_IN_WEEK
          ),
          'yyyy-MM-dd'
        ),
        endDate: format(Date.now(), 'yyyy-MM-dd'),
      },
      { refetchOnMountOrArgChange: true }
    )

  const { data: popularProducts, isLoading: isPopularProductsLoading } = useGetPopularProductsQuery(
    undefined,
    { refetchOnMountOrArgChange: true }
  )

  const paymentsPeriod = {
    startDate: format(
      new Date(
        Date.now() - MSECS_IN_SEC * SECS_IN_MIN * MINS_IN_HOUR * HOURS_IN_DAY * (DAYS_IN_WEEK - 1)
      ),
      'yyyy-MM-dd'
    ),
    endDate: format(Date.now(), 'yyyy-MM-dd'),
  }

  const { data: payments, isLoading: isPaymentsLoading } = useSellerPaymentsWithNullQuery(
    {
      startDate: paymentsPeriod.startDate,
      endDate: paymentsPeriod.endDate,
    },
    { refetchOnMountOrArgChange: true }
  )

  return (
    <>
      <PageHeader title="Dashboard" />
      <Grid container spacing={3}>
        <Grid item xs={12} xl={7}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={6}>
              <StatisticsCard
                icon={<UsersIcon />}
                title="Users"
                count={userCountTotal?.count || 0}
                additional={{
                  amount: userCountLastWeek?.count || 0,
                  label: 'for the last week',
                }}
                background={reviewStatusStyles[ReviewStatus.DRAFT].color}
                isLoading={isUserCountLoading || isUserCountLastWeekLoading}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <Link
                className="hoverCard"
                to="/shops/sales"
                style={{ textDecoration: 'none', borderRadius: '5px' }}
              >
                <StatisticsCard
                  icon={<ShopCartIcon />}
                  title="Sales"
                  count={salesCountTotal?.count || 0}
                  additional={{
                    amount: salesCountLastWeek?.count || 0,
                    label: 'for the last week',
                  }}
                  background={teal[500]}
                  isLoading={isSalesCountTotalLoading || isSalesCountLastWeekLoading}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <Link
                className="hoverCard"
                to="/shops/all-reviews"
                style={{ textDecoration: 'none', borderRadius: '5px' }}
              >
                <StatisticsCard
                  icon={<MessageIcon />}
                  title="Total Reviews"
                  count={reviewsCountTotal?.count || 0}
                  additional={{
                    amount: reviewsCountLastWeek?.count || 0,
                    label: 'for the last week',
                  }}
                  background={reviewStatusStyles[ReviewStatus.APPROVED].color}
                  isLoading={isReviewsCountTotalLoading || isReviewsCountLastWeekLoading}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <Link
                className="hoverCard"
                to={`/shops/all-reviews?statuses=${ReviewStatus.MODERATION}`}
                style={{ textDecoration: 'none', borderRadius: '5px' }}
              >
                <StatisticsCard
                  icon={<CircleCheckIcon />}
                  title="Reviews on Moderation"
                  count={reviewsOnModerationCountTotal?.count || 0}
                  additional={{
                    amount: '',
                    label: 'Requires your attention',
                  }}
                  background={reviewStatusStyles[ReviewStatus.MODERATION].color}
                  isLoading={isReviewsOnModerationCountTotalLoading}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} xl={3}>
          <Finances payments={payments} isLoading={isPaymentsLoading} />
        </Grid>
        <Grid item xs={12} sm={4} xl={2}>
          <Subscription />
        </Grid>
        <Grid item xs={12} lg={8}>
          <LatestReviews
            dashboard="SELLER"
            reviews={latestReviews?.data || []}
            isLoading={isLatestReviewsLoading}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <PopularProducts products={popularProducts || []} isLoading={isPopularProductsLoading} />
        </Grid>
      </Grid>
    </>
  )
}

export default SellerDashboard
