import React from 'react'

import { Box, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material'

import { reviewStatusStyles } from 'constants/reviewStatusRender'

import { Review } from 'services/reviews/types'

import { ReactComponent as ProductIcon } from 'assets/svg/image-icon.svg'

import { ExternalLink } from 'components/common/ExternalLink'

interface Props {
  review: Review | undefined
}

export const ReviewerReviewInfo = ({ review }: Props) => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1.5rem',
        flexDirection: isSm ? 'column' : 'row',
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '3rem',
          minWidth: '10rem',
          height: '10rem',
          justifyContent: 'center',
          borderRadius: '0.5rem',
          overflow: 'hidden',
          border: '2px solid #D0D0D0',
        }}
      >
        {review?.productImageUrl ? (
          <img
            src={review.productImageUrl}
            alt={review.productName}
            loading="lazy"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <ProductIcon width="1.5rem" height="1.5rem" fill="#D0D0D0" />
        )}
      </Box>
      <Box
        sx={{
          gap: '0.5rem',
          display: 'flex',
          justifyContent: 'start',
          flexDirection: 'column',
          minWidth: 0,
        }}
      >
        <Box sx={{ display: 'flex', whiteSpace: 'nowrap', minWidth: 0, gap: '1rem' }}>
          <Typography
            component="span"
            sx={{ lineHeight: '1.5rem', fontSize: '1rem', fontWeight: '500' }}
          >
            Seller:{' '}
          </Typography>
          <Tooltip title={`${review?.sellerFirstName} ${review?.sellerLastName}`}>
            <Typography
              sx={{
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
                fontWeight: 600,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${review?.sellerFirstName} ${review?.sellerLastName}`}
            </Typography>
          </Tooltip>
        </Box>
        {review?.productLink && (
          <Box sx={{ display: 'flex', whiteSpace: 'nowrap', minWidth: 0, gap: '1rem' }}>
            <Typography
              component="span"
              sx={{ lineHeight: '1.5rem', fontSize: '1rem', fontWeight: '500' }}
            >
              Product link:{' '}
            </Typography>
            <ExternalLink
              href={review.productLink}
              sx={{
                fontWeight: 600,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
              }}
            >
              Click Here
            </ExternalLink>
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Typography
            component="span"
            sx={{ lineHeight: '1.5rem', fontSize: '1rem', fontWeight: '500' }}
          >
            Payment per video:
          </Typography>
          <Typography
            sx={{
              fontSize: '1.25rem',
              lineHeight: '1.5rem',
              fontWeight: 600,
            }}
          >
            ${review?.price}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Typography
            component="span"
            sx={{ lineHeight: '1.5rem', fontSize: '1rem', fontWeight: '500' }}
          >
            Status:
          </Typography>
          <Typography
            sx={{
              fontSize: '1.25rem',
              lineHeight: '1.5rem',
              fontWeight: 600,
              color:
                review?.status || review?.status === 0
                  ? reviewStatusStyles[review.status].color
                  : '#fff',
            }}
          >
            {review?.status || review?.status === 0 ? reviewStatusStyles[review.status].text : ''}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
