import { FC } from 'react'
import Chart from 'react-apexcharts'

import { Box, Typography } from '@mui/material'

import formatDate from 'utils/formatToLocalTimezone'

import { PaymentChart } from 'services/dashboard/types'

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']

const LastPaymentsChart: FC<PaymentChart> = ({ categories, data }) => {
  const sum = data.reduce((acc, num) => acc + num, 0)

  const series = [
    {
      name: 'Paid, $',
      data: data,
    },
  ]
  const options = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
      // height: '5.375rem',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.4,
      },
    },
    stroke: {
      show: true,
      curve: 'monotoneCubic',
      width: 4,
    },
    grid: {
      show: true,
      padding: {
        top: 8,
        left: 8,
        right: 8,
      },
      xaxis: {
        lines: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: categories,
      position: 'top',
      show: true,
      labels: {
        show: false,
        formatter: function (value: string) {
          return `${daysOfWeek[new Date(value).getDay()]} ${formatDate(value, 'd')}`
        },
        style: {
          fontSize: '0.6125rem',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: sum > 0 ? 0 : -1,
      max: sum > 0 ? undefined : 1,
      labels: {
        show: false,
      },
    },
  } as const

  return series[0].data.length ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          mx: '-0.3rem',
        }}
      >
        {categories.map((item) => (
          <Box key={item} sx={{ width: '1.875rem' }}>
            <Typography
              sx={{
                textAlign: 'center',
                color: '#D0D0D0',
                fontSize: '0.6125rem',
                fontWeight: 700,
              }}
            >
              {daysOfWeek[new Date(item).getDay()]}
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                color: '#262626',
                fontSize: '0.6125rem',
                fontWeight: 700,
              }}
            >
              {formatDate(item, 'd')}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ height: '100%', maxHeight: '8.4375rem' }}>
        <Chart options={options} series={series} type="area" height="100%" width="100%" />
      </Box>
    </Box>
  ) : (
    <Box sx={{ height: '78px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography sx={{ color: 'text.secondary' }}>Not enough data to display chart.</Typography>
    </Box>
  )
}

export default LastPaymentsChart
