import { Box, Typography, Link } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'

import { ListProductsRecord } from 'services/products/types'

import { ReactComponent as ProductImage } from 'assets/svg/product.svg'

import { EllipsedText } from 'components/common/EllipsedText'

export const columns: GridColDef<ListProductsRecord>[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => (
      <Link
        href={`/products/${row.id}`}
        sx={{
          textDecoration: 'none',
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <Box
            sx={{
              borderRadius: '0.5rem',
              overflow: 'hidden',
              minWidth: '3rem',
              width: '3rem',
              height: '3rem',
            }}
          >
            {row.imageUrl ? (
              <img
                src={row.imageUrl}
                alt={row.name}
                loading="lazy"
                style={{
                  minWidth: '3rem',
                  width: '3rem',
                  height: '3rem',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <ProductImage style={{ width: '3rem', height: '3rem' }} />
            )}
          </Box>
          <EllipsedText
            title={row.name}
            sx={{ fontSize: '0.875rem', fontWeight: 500, ml: '0.5rem', color: '#121619' }}
          />
        </Box>
      </Link>
    ),
  },
  {
    field: 'shop',
    headerName: 'Shop Name',
    type: 'string',
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => (
      <Link href={`/shops/${row.shopId}/products`} sx={{ textDecoration: 'none', width: '100%' }}>
        <EllipsedText
          title={row.shopName}
          sx={{ fontSize: '0.875rem', fontWeight: 500, color: '#121619' }}
        />
      </Link>
    ),
  },
  {
    field: 'defaultReviewPrice',
    headerName: 'Bonus',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    flex: 0.5,
    sortable: true,
    valueGetter: ({ row }) => {
      return row.shopDefaultReviewPrice
    },
    valueFormatter: ({ value }) => `$${value}`,
  },
  {
    field: 'approvedReviewsCount',
    headerName: 'Published reviews',
    type: 'string',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: true,
  },
  {
    field: 'moderationReviewsCount',
    headerName: 'Reviews on moderation',
    type: 'string',
    flex: 1.1,
    headerAlign: 'center',
    align: 'center',
    sortable: true,
  },
  {
    field: 'notificationDisabledAt',
    headerName: 'Mailer status',
    type: 'string',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography color={!row.notificationDisabledAt ? '#09C132' : '#828282'}>
        {!row.notificationDisabledAt ? 'Active' : 'Inactive'}
      </Typography>
    ),
  },
]
