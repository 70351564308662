import { FC, memo } from 'react'

import { Typography, Skeleton } from '@mui/material'

import { useGetBalanceQuery } from 'services/balance/api'

const SellerBalance: FC = () => {
  const { data, isLoading, isError } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  return (
    <Typography sx={{ color: '#BDBDBD', fontWeight: 500, display: 'flex', justifyContent: 'end' }}>
      {!isLoading ? (
        <>
          Balance:
          <Typography component="span" color="#FFFFFF">
            {!isError ? `$${data?.balance}` : 'Not Available Yet'}
          </Typography>
        </>
      ) : (
        <Skeleton
          sx={{
            width: '5.5rem',
            fontSize: '0.9rem',
            bgcolor: 'rgba(255, 255, 255, .2)',
          }}
        />
      )}
    </Typography>
  )
}

export default memo(SellerBalance)
