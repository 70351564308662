import { skipToken } from '@reduxjs/toolkit/query'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Skeleton } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetReviewsAdminQuery } from 'services/admin/reviews/api'
import { useGetProductQuery } from 'services/products/api'

import PageHeader from 'components/common/PageHeader'
import SearchInput from 'components/common/SearchInput'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'

const AdminReviews: FC = () => {
  const { sellerId, shopId, productId } = useParams()

  const { data: product, isLoading: isProductLoading } = useGetProductQuery(productId ?? skipToken)

  const { queryParams, pagination, sort, search, setPagination, setSort, setSearch, isFiltered } =
    useDataGridPagination()

  const { data, isLoading, isFetching } = useGetReviewsAdminQuery(
    { productId, ...queryParams },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  return (
    <>
      <PageHeader
        title={isProductLoading ? <Skeleton width="12rem" height="3.2rem" /> : product?.name}
        backPath={`/admin${sellerId ? `/sellers/${sellerId}` : ''}${
          shopId ? `/shops/${shopId}` : ''
        }/products`}
      />
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mb: '1.5rem' }}>
          <SearchInput initValue={search} onSearchChange={setSearch} />
        </Box>
        <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
          <CustomDataGrid
            data={data}
            columns={columns}
            loading={isLoading || isFetching}
            paginationModel={pagination}
            setPagination={setPagination}
            setSort={setSort}
            initSort={sort}
            isFiltered={isFiltered}
            rowHeight={72}
          />
        </Box>
      </Box>
    </>
  )
}

export default AdminReviews
