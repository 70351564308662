import { FC } from 'react'

import { Box } from '@mui/material'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetSalesQuery } from 'services/sales/api'
import { SaleStatus } from 'services/sales/types'

import SearchInput from 'components/common/SearchInput'
import StatusesFilter from 'components/common/StatusesFilter'
import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'
import { filters } from './filters'

const Sales: FC = () => {
  const {
    queryParams,
    pagination,
    sort,
    statusFilter,
    search,
    isFiltered,
    setPagination,
    setSort,
    setStatusFilter,
    setSearch,
  } = useDataGridPagination('notificationStatuses')

  const { data, isFetching } = useGetSalesQuery(queryParams, { refetchOnMountOrArgChange: true })

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', mb: '1.5rem' }}>
        <StatusesFilter<SaleStatus | string>
          filters={filters}
          init={statusFilter}
          filterChange={setStatusFilter}
        />
        <SearchInput initValue={search} onSearchChange={setSearch} />
      </Box>
      <Box sx={{ height: 'calc(100vh - 16.5rem)', width: '100%' }}>
        <CustomDataGrid
          data={data}
          columns={columns}
          loading={isFetching}
          paginationModel={pagination}
          setPagination={setPagination}
          setSort={setSort}
          initSort={sort}
          rowHeight={72}
          isFiltered={isFiltered}
        />
      </Box>
    </Box>
  )
}

export default Sales
