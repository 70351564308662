import { GridColDef } from '@mui/x-data-grid'

import { reviewStatusStyles as status } from 'constants/reviewStatusRender'

import formatDate from 'utils/formatToLocalTimezone'

import { ListReviewsRecord } from 'services/reviews/types'

import { EllipsedText } from 'components/common/EllipsedText'

export const columns: GridColDef<ListReviewsRecord>[] = [
  {
    field: 'reviewer',
    headerName: 'Reviewer Name',
    flex: 1,
    sortable: true,
    filterable: false,
    valueGetter: ({ row }) => `${row.userFirstName} ${row.userLastName}`,
  },
  {
    field: 'userEmail',
    headerName: 'Email',
    flex: 1,
    filterable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    flex: 1,
    filterable: false,
    align: 'center',
    headerAlign: 'center',
    valueGetter: ({ row }) => formatDate(row.createdAt, 'MM.dd.yyyy'),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => (
      <EllipsedText
        title={status[row.status as keyof typeof status].text}
        sx={{ color: status[row.status as keyof typeof status].color, fontWeight: '600' }}
      />
    ),
  },
  {
    field: 'name',
    headerName: 'Video Review',
    flex: 1,
    filterable: false,
  },
  {
    field: 'price',
    headerName: 'Paid, $',
    flex: 1,
    filterable: false,
  },
]
