import { skipToken } from '@reduxjs/toolkit/query'
import { ChangeEvent, FC } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Switch, Tooltip, Typography } from '@mui/material'

import { showNotification } from 'utils/showNotification'

import { useGetProductQuery, useSetNotificationStatusMutation } from 'services/products/api'

import { ReactComponent as ImageIcon } from 'assets/svg/image-icon.svg'

import { ExternalLink } from 'components/common/ExternalLink'
import PageHeader from 'components/common/PageHeader'
import Preloader from 'components/common/Preloader'

import ReviewsByProduct from 'pages/seller/reviews/reviewsByProduct'

const Product: FC = () => {
  const { productId, shopId } = useParams<{ productId: string; shopId: string | undefined }>()
  const {
    data: product,
    isLoading,
    refetch,
  } = useGetProductQuery(productId ?? skipToken, { refetchOnMountOrArgChange: true })

  const [requestStatusChange, { isLoading: isStatusLoading }] = useSetNotificationStatusMutation()

  const handleToggleSwitch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (productId) {
      try {
        await requestStatusChange({
          id: productId,
          use_notification: event.target.checked,
        }).unwrap()
        await refetch()
        showNotification('Status successfully changed', 'success')
      } catch (e) {
        showNotification('Failed to change status', 'error')
      }
    }
  }

  if (isLoading || !product) return <Preloader />

  return (
    <Box>
      <PageHeader
        title={product.name}
        backPath={shopId ? `/shops/${shopId}/products` : `/shops/all-products`}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3.5rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              minWidth: '18.75rem',

              height: '11rem',
              bgcolor: '#D0D0D0',
              borderRadius: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            {product.imageUrl ? (
              <img
                src={product.imageUrl}
                alt={product.name}
                loading="lazy"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <ImageIcon style={{ width: '1.5rem', height: '1.5rem', fill: '#fff' }} />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: '0.5rem',
              minWidth: '0',
            }}
          >
            <Box
              sx={{
                minWidth: '0',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <Typography component="span" sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Shop:{' '}
              </Typography>
              <Tooltip title={product.shop.name} sx={{ minWidth: '0' }}>
                <Typography
                  component="span"
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: '#000000',
                  }}
                >
                  {product.shop.name}
                </Typography>
              </Tooltip>
            </Box>
            <Box
              sx={{
                minWidth: '0',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {product.link && (
                <>
                  <Typography component="span" sx={{ fontSize: '1rem', fontWeight: 500 }}>
                    Product link:{' '}
                  </Typography>
                  <ExternalLink
                    href={product.link}
                    sx={{
                      fontSize: '1.25rem',
                      fontWeight: 600,
                    }}
                  >
                    Click here
                  </ExternalLink>
                </>
              )}
            </Box>
            <Box>
              <Typography component="span" sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Payment Per Video:{' '}
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: '#000000',
                }}
              >
                ${product.shop.defaultReviewPrice}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Disable/enable products mailings:{' '}
              </Typography>
              <Switch
                color="default"
                onChange={handleToggleSwitch}
                disabled={isStatusLoading}
                checked={!product.notificationDisabledAt}
              />
            </Box>
          </Box>
        </Box>
        <ReviewsByProduct />
      </Box>
    </Box>
  )
}

export default Product
