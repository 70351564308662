import { FC, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Box, Grid } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid'

import { useDataGridPagination } from 'hooks/useDataGridPagination'

import { useGetReviewsQuery } from 'services/reviews/api'

import CustomDataGrid from 'components/table/CustomDataGrid'

import { columns } from './columns'

const ReviewsByProduct: FC = () => {
  const navigate = useNavigate()
  const { productId } = useParams()
  const { queryParams, pagination, sort, setPagination, setSort, isFiltered } =
    useDataGridPagination()

  const { data, isLoading, isFetching } = useGetReviewsQuery(
    {
      ...queryParams,
      productId: productId,
    },
    { refetchOnMountOrArgChange: true }
  )

  const handlerRowClick = useCallback((params: GridRowParams) => {
    navigate(`${params.id}`)
  }, [])

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Box sx={{ height: 'calc(100vh - 17.125rem)', width: '100%' }}>
          <CustomDataGrid
            data={data}
            columns={columns}
            loading={isLoading || isFetching}
            paginationModel={pagination}
            setPagination={setPagination}
            setSort={setSort}
            initSort={sort}
            rowHeight={72}
            rowClick={handlerRowClick}
            isFiltered={isFiltered}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default ReviewsByProduct
