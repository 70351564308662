import { TableServerResponse } from 'types/Table'

import { showNotification } from 'utils/showNotification'

import { baseApi } from 'api/api'

import {
  NewShop,
  NewShopResponse,
  Shop,
  ShopsPaginationPayload,
  SyncWithShopifyResponse,
} from './types'

export const shopsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getShops: build.query<TableServerResponse<Shop[]>, ShopsPaginationPayload>({
      query: (params) => ({
        url: `/shops`,
        method: 'GET',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Shops', id }) as const),
              { type: 'Shops', id: 'LIST' },
            ]
          : [{ type: 'Shops', id: 'LIST' }],
    }),
    createShop: build.mutation<NewShopResponse, NewShop>({
      query: (data) => ({
        url: '/shops',
        method: 'POST',
        data: data,
        fromData: true,
      }),
      invalidatesTags: [{ type: 'Shops', id: 'LIST' }],
    }),
    getShop: build.query<Shop, string>({
      query: (id) => ({
        url: `/shops/${id}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Shops', id: 'LIST' }],
    }),
    updateShop: build.mutation<NewShopResponse, NewShop & { id: string }>({
      query: ({ id, ...put }) => ({
        url: `/shops/${id}`,
        method: 'PUT',
        data: put,
      }),
      invalidatesTags: [{ type: 'Shops', id: 'LIST' }],
    }),
    deleteShop: build.mutation<string, string>({
      query: (id) => ({
        url: `shops/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Shops', id: 'LIST' }],
    }),
    syncShopWithShopify: build.mutation<SyncWithShopifyResponse, string>({
      query: (id) => ({
        url: `/shops/${id}/products-sync`,
        method: 'POST',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const {
            data: { success, message },
          } = await queryFulfilled
          showNotification(message, success ? 'success' : 'error')
          /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (e: any) {
          showNotification(e?.error?.data?.detail, 'error')
        }
      },
      invalidatesTags: [{ type: 'Products', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetShopsQuery,
  useGetShopQuery,
  useCreateShopMutation,
  useUpdateShopMutation,
  useDeleteShopMutation,
  useSyncShopWithShopifyMutation,
} = shopsApi
