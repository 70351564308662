import { requiredMessage } from 'validation'
import { boolean, object, ref, string } from 'yup'

const MIN_PASSWORD = 8

export const registrationSchema = object({
  firstName: string().required(requiredMessage),
  lastName: string().required(requiredMessage),
  email: string().email('Please enter a valid email address').required(requiredMessage),
  password: string()
    .required(requiredMessage)
    .min(MIN_PASSWORD, 'Password should be at least 8 characters ')
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/,
      'Password should include at least 1 lowercase character, 1 uppercase character, 1 digit and 1 special character (!@#$%^&*)'
    ),
  passwordConfirmation: string()
    .required(requiredMessage)
    .oneOf([ref('password')], 'Please Confirm your password.'),
  agreement: boolean(),
  terms: boolean(),
})
