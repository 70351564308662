import { GetDataToTablePayload } from 'types/Table'

export enum SaleStatus {
  UPCOMING = 0,
  NEW = 3,
  WEEK1 = 6,
  WEEK2 = 9,
  REG_ONLY = 12,
  OLD = 15,
  COMPLETED = 18,
}

export const SALE_STATUS_NAME = {
  0: 'Upcoming',
  3: 'New',
  6: '1 week',
  9: '2 weeks',
  12: 'Reg only',
  15: 'Old',
  18: 'Completed',
} as const

export const SALE_STATUS_DESCRIPTION_BY_NAME = {
  Upcoming: 'Sale confirmed, no emails sent yet.',
  New: 'Email sent 1 week after purchase.',
  '1 week': 'Reminder sent 1 week after the initial email.',
  '2 weeks': 'Final reminder sent 2 weeks after the initial email.',
  'Reg only': 'Registered, no video feedback yet. Reminder sent 3 days after invite activation.',
  Old: 'Over 3 weeks since initial email, no feedback.',
  Completed: 'Video feedback submitted successfully.',
  Off: 'Email notifications disabled for this product',
}
export type saleStatusDescriptionKeys = keyof typeof SALE_STATUS_DESCRIPTION_BY_NAME
export interface Sale {
  id: string
  createdAt: string
  shopId: string
  shopName: string
  userEmail: string
  userName: string
  productId: string
  productName: string
  productImage: string
  notificationStatus: SaleStatus | null
  notificationDisabledAt: string | null
}

export interface GetSalesPayload extends GetDataToTablePayload {
  offerStatus?: number
}
