import { baseApi } from 'api/api'

import { Feedback } from './types'

export const feedbackApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    feedback: build.mutation<void, Feedback>({
      query: (data: Feedback) => ({
        url: '/feedbacks',
        method: 'POST',
        data: data,
      }),
    }),
  }),
})

export const { useFeedbackMutation } = feedbackApi
