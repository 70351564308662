import { baseApi } from 'api/api'

import { SendMailPayload } from './types'

export const adminMailerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    sendMail: build.mutation<void, SendMailPayload>({
      query: (data) => ({
        url: '/admin/email_notification/send',
        method: 'POST',
        data: data,
      }),
    }),
  }),
})

export const { useSendMailMutation } = adminMailerApi
