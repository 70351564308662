import { FC } from 'react'

import { Typography, Box, Container } from '@mui/material'

import LegalLendings from 'components/public-layout/LegalLendings'

export const POLICIES_TITLE_STYLES = { fontWeight: 600, fontSize: '1.25rem', mb: '0.5rem' }
export const POLICIES_TEXT_STYLES = { pl: '1rem', lineHeight: '1.225rem' }
export const POLICIES_LIST_STYLES = {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  pl: '1rem',
}
export const POLICIES_LIST_ITEM_STYLES = { display: 'flex', gap: '0.5rem', alignItems: 'center' }
export const POLICIES_LIST_DOTE_STYLES = { fontSize: '2rem', lineHeight: 0, marginTop: '-0.2rem' }

const GOOGLE_COOKIE_SETTINGS = 'https://support.google.com/chrome/answer/95647?hl=en'
const SAFARI_COOKIE_SETTINGS = 'https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac'
const MOZILLA_COOKIE_SETTINGS =
  'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US'
const EDGE_COOKIE_SETTINGS =
  'https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d'

const CookiePolicy: FC = () => {
  return (
    <LegalLendings title="Shopiview Cookie Policy" date="Oct 29, 2024">
      <Container
        sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginBottom: '6.25rem' }}
      >
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            1. Introduction
          </Typography>
          <Typography component="p" sx={POLICIES_TEXT_STYLES}>
            This Cookie Policy explains how Shopiview, operated and owned by We Can Develop IT LLC
            (&quot;We&quot;, &quot;Us&quot;, &quot;Our&quot;), uses cookies and similar tracking
            technologies on our platform (&quot;Platform&quot;). By using the Platform, you agree to
            the use of cookies as outlined in this policy
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            2. What Are Cookies?
          </Typography>
          <Typography component="p" sx={POLICIES_TEXT_STYLES}>
            Cookies are small text files that are placed on your device when you visit a website.
            They are widely used to make websites work more efficiently and to provide information
            to the website owners.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            3. Types of Cookies We Use
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Essential Cookies: These cookies are necessary for the Platform to function
                properly. They enable you to navigate the site and use its features, such as
                accessing secure areas.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Performance Cookies: These cookies collect information about how visitors use the
                Platform, such as which pages are visited most often. This helps us improve the
                Platform&apos;s functionality.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Functionality Cookies: These cookies allow the Platform to remember choices you make
                (such as your username and language preferences) and provide enhanced, more
                personalized features.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Targeting/Advertising Cookies: These cookies are used to deliver advertisements more
                relevant to you and your interests. They are also used to limit the number of times
                you see an ad and to measure the effectiveness of advertising campaigns.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            4. How We Use Cookies
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                To Improve User Experience: We use cookies to understand how you interact with the
                Platform, which helps us improve its functionality and performance.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                To Personalize Content: Cookies allow us to provide you with a more personalized
                experience by remembering your preferences and settings.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                To Analyze Usage: We use cookies to gather statistical information about the usage
                of the Platform, which helps us understand how users engage with our content and
                services.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                To Provide Targeted Advertising: Cookies help us deliver relevant advertisements to
                you based on your interests and online activities.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            5. Managing Cookies
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography sx={POLICIES_TEXT_STYLES}>
              You can manage or delete cookies using your browser settings. Here&apos;s how you can
              do it in some popular browsers:
            </Typography>
            <Box sx={POLICIES_LIST_STYLES}>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Typography>
                  Google Chrome:{' '}
                  <a href={GOOGLE_COOKIE_SETTINGS} target="_blank" rel="noreferrer">
                    Chrome Cookie Settings
                  </a>
                </Typography>
              </Box>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Typography>
                  Mozilla Firefox:{' '}
                  <a href={MOZILLA_COOKIE_SETTINGS} target="_blank" rel="noreferrer">
                    Firefox Cookie Settings
                  </a>
                </Typography>
              </Box>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Typography>
                  Safari:{' '}
                  <a href={SAFARI_COOKIE_SETTINGS} target="_blank" rel="noreferrer">
                    Safari Cookie Settings
                  </a>
                </Typography>
              </Box>
              <Box sx={POLICIES_LIST_ITEM_STYLES}>
                <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
                <Typography>
                  Microsoft Edge:{' '}
                  <a href={EDGE_COOKIE_SETTINGS} target="_blank" rel="noreferrer">
                    Edge Cookie Settings
                  </a>
                </Typography>
              </Box>
            </Box>
            <Typography sx={POLICIES_LIST_STYLES}>
              Please note that if you choose to block cookies, you may not be able to access all or
              parts of our Platform or some features may not function properly.
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            6. Content Ownership and Rights
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                By submitting a review, you grant Shopiview a non-exclusive, worldwide, royalty-free
                license to use, reproduce, and distribute your content.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                You retain ownership of your content but grant Shopiview the right to modify and
                display it on the Platform and related promotional materials.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            7. Privacy
          </Typography>
          <Box sx={POLICIES_LIST_STYLES}>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                Shopiview collects and uses your personal information in accordance with its Privacy
                Policy.
              </Typography>
            </Box>
            <Box sx={POLICIES_LIST_ITEM_STYLES}>
              <Typography sx={POLICIES_LIST_DOTE_STYLES}>&#x2022;</Typography>
              <Typography>
                By using the Platform, you consent to the collection and use of your information as
                described in the Privacy Policy.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </LegalLendings>
  )
}

export default CookiePolicy
