import { FC } from 'react'

import { ContentCopy } from '@mui/icons-material'
import { Box, Card, CardContent, Typography, Tooltip } from '@mui/material'

import formatToLocalTimezone from 'utils/formatToLocalTimezone'
import { showNotification } from 'utils/showNotification'

import { Coupon, CouponStatus as StatusesEnum } from 'services/coupons/types'

import CouponStatus from '../CouponStatus'

interface Props {
  coupon: Coupon
}

const tooltipMessage = {
  [StatusesEnum.FAILED]: 'Sorry! Something has happened. Please contact support.',
  [StatusesEnum.INACTIVE]: 'Your coupon is going to be created soon. Please wait for a while.',
}

const CouponItem: FC<Props> = ({ coupon }) => {
  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(coupon.name)
    showNotification('Copied!', 'success')
  }

  return (
    <Card sx={{ width: '100%', maxWidth: '21.5rem' }}>
      <CardContent sx={{ p: '1.5rem', display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
        <Typography sx={{ fontWeight: '400', fontSize: '0.75rem', color: '#828282' }}>
          {formatToLocalTimezone(coupon.createdAt, 'MM-dd-yyyy')}
        </Typography>
        <Box sx={{ display: 'flex', gap: '1.5rem', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: 0,
            }}
          >
            <Tooltip title={`${coupon.sellerFirstName} ${coupon.sellerLastName}`}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: '1.5rem',
                  lineHeight: '130%',
                  fontWeight: '700',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textWrap: 'nowrap',
                }}
              >
                {coupon.sellerFirstName} {coupon.sellerLastName}
              </Typography>
            </Tooltip>
            {coupon.status === StatusesEnum.FAILED || coupon.status === StatusesEnum.INACTIVE ? (
              <Tooltip title={tooltipMessage[coupon.status]}>
                <Typography component="span" color="success" sx={{ borderRadius: '50%' }}>
                  <CouponStatus status={coupon.status} />
                </Typography>
              </Tooltip>
            ) : (
              <Typography component="span" color="success" sx={{ borderRadius: '50%' }}>
                <CouponStatus status={coupon.status} />
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
              <Typography sx={{ textWrap: 'nowrap' }}>Name of shop:</Typography>
              <Tooltip title={coupon.shopName}>
                <Typography
                  sx={{
                    fontWeight: '700',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                  }}
                >
                  {coupon.shopName}
                </Typography>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
              <Typography sx={{ textWrap: 'nowrap' }}>Discount code:</Typography>
              <Tooltip title={coupon.name}>
                <Typography
                  sx={{
                    fontWeight: '700',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                  }}
                >
                  {coupon.name}
                </Typography>
              </Tooltip>
              {coupon.status === StatusesEnum.ACTIVE && (
                <Tooltip title="Copy your discount code">
                  <ContentCopy
                    sx={{ fill: '#d0d0d0', '&:hover': { fill: '#a9a9a9' } }}
                    onClick={handleCopyToClipBoard}
                  />
                </Tooltip>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
              <Typography sx={{ textWrap: 'nowrap' }}>Coupon value:</Typography>
              <Typography
                sx={{
                  fontWeight: '700',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textWrap: 'nowrap',
                }}
              >
                ${coupon.value}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default CouponItem
