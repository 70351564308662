import { useState, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Sort, SortDirection, Pagination } from 'types/Table'

import { DEFAULT_PAGINATION } from 'constants/default-params'

interface Filter {
  [key: string]: string
}

export const useDataGridPagination = (statusFieldName?: string, isStatusMulti = true) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const params: Filter = {}

  searchParams.forEach((value, key) => {
    params[key] = value
  })

  const [pagination, setPagination] = useState<Pagination>({
    page: 'page' in params ? +params.page : DEFAULT_PAGINATION.page,
    itemsPerPage: 'itemsPerPage' in params ? +params.itemsPerPage : DEFAULT_PAGINATION.itemsPerPage,
  })

  const [sort, setSort] = useState<Sort>({
    sortField: 'field' in params ? params.field : '',
    sortDir: 'dir' in params ? (params.dir as SortDirection) : null,
  })

  const [statusFilter, setStatusFilter] = useState<string[]>(
    'statuses' in params ? params.statuses.split(',') : []
  )

  const [search, setSearch] = useState<string>('search' in params ? params.search : '')

  //only for shops page, var for shop name like shop-name.myshopify.com
  const [shopName, setShopName] = useState<string>('shopName' in params ? params.shopName : '')

  const queryParams = useMemo(() => {
    const query = {
      page: pagination.page,
      itemsPerPage: pagination.itemsPerPage,
      sortField: sort.sortField,
      sortDir: sort.sortDir,
      [statusFieldName || 'statuses']: isStatusMulti ? statusFilter : statusFilter[0],
      search: search,
      shopName: shopName,
    }
    return Object.fromEntries(
      Object.entries(query).filter(
        ([, value]) => value !== undefined && value !== null && value !== ''
      )
    )
  }, [pagination, sort, statusFilter, search, shopName])

  const isFiltered = useMemo(() => {
    return !!queryParams.sortField || !!queryParams.status || !!queryParams.search
  }, [queryParams])

  const collectFilters = () => {
    const filters: Filter = {}

    if (pagination.page !== DEFAULT_PAGINATION.page) {
      filters.page = pagination.page.toString()
    }

    if (pagination.itemsPerPage !== DEFAULT_PAGINATION.itemsPerPage) {
      filters.itemsPerPage = pagination.itemsPerPage.toString()
    }

    if (sort.sortField !== '' && sort.sortDir !== null && sort.sortDir !== undefined) {
      filters.field = sort.sortField
      filters.sortDir = sort.sortDir
    }

    if (statusFilter.length) {
      filters.statuses = statusFilter.toString()
    }

    if (search) {
      filters.search = search
    }

    if (shopName) {
      if (shopName.match(/^[a-zA-Z0-9-]+\.myshopify\.com$/) !== null) {
        filters.shopName = shopName
      }
    }

    setSearchParams(filters)
  }

  useEffect(() => {
    collectFilters()
  }, [pagination, sort, statusFilter, search, shopName])

  return {
    queryParams,
    isFiltered,
    pagination,
    sort,
    statusFilter,
    search,
    shopName,
    setPagination,
    setSort,
    setStatusFilter,
    setSearch,
    setShopName,
  }
}
