import { yupResolver } from '@hookform/resolvers/yup'
import { skipToken } from '@reduxjs/toolkit/query'
import { useState, SyntheticEvent, useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import AddIcon from '@mui/icons-material/Add'
import { Box, Typography, Tab, Tabs, Tooltip } from '@mui/material'

import { Error } from 'types/Error'

import { MEDIA_URL } from 'constants/urls'

import { showNotification } from 'utils/showNotification'

import { isErrorWithMessage } from 'services/errorHelperService'
import { useGetReviewByIdQuery, useSendReviewForModerationMutation } from 'services/reviews/api'
import { ReviewStatus } from 'services/reviews/types'

import Button from 'components/common/Button'
import PageBox from 'components/common/Page'
import PageHeader from 'components/common/PageHeader'
import PercentageCircularProgress from 'components/common/PercentageCircularProgress'
import Preloader from 'components/common/Preloader'
import TextInput from 'components/common/TextInput'
import UploadFileButton from 'components/common/UploadFileButton'
import VideoDropzone from 'components/common/VideoDropzone'
import VideoPlayer from 'components/common/VideoPlayer'
import ReviewHistory from 'components/reviews/ReviewHistory'
import { ReviewerReviewInfo } from 'components/reviews/ReviewerReviewInfo'

import { videoDetailsSchema } from '../createReview/validation'

export type FormVideoDetailsType = yup.InferType<typeof videoDetailsSchema>

const MyReview = () => {
  const { reviewId } = useParams()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const { data: review, isLoading } = useGetReviewByIdQuery(reviewId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  })
  const navigate = useNavigate()
  const handleTabChange = (e: SyntheticEvent, tabIndex: number) => {
    setCurrentTabIndex(tabIndex)
  }

  const [file, setFile] = useState<File | null>(null)
  const [fileError, setFileError] = useState<string | null>(null)

  const [sendForModeration, { isLoading: isSendingForModeration }] =
    useSendReviewForModerationMutation()

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormVideoDetailsType>({
    resolver: yupResolver(videoDetailsSchema),
    defaultValues: {
      name: review?.name,
      description: review?.description,
    },
  })

  const reviewName = watch('name')

  useEffect(() => {
    if (review)
      reset({
        name: review.name,
        description: review.description,
      })
  }, [review])

  const onSubmit = (data: FormVideoDetailsType) => {
    if (!file && !review?.mediaFile && review?.status === ReviewStatus.DRAFT) {
      setFileError('Video required')
      return
    }
    const formData = new FormData()
    if (file) formData.append('file', file)
    formData.append('name', data.name)
    formData.append('description', data.description || '')

    if (reviewId) {
      sendForModeration({
        id: reviewId,
        formData,
        setUploadProgress,
      })
        .unwrap()
        .then(() => {
          navigate('/my-reviews')
          showNotification(`Successfully sent for moderation`, 'success')
        })
        .catch((error: Error) =>
          showNotification(
            `Failed to send for moderation - ${
              isErrorWithMessage(error) ? error.data.detail : 'Something went wrong'
            }`,
            'error'
          )
        )
    }
  }

  const videoUrl = useMemo(() => {
    return file
      ? URL.createObjectURL(file)
      : review?.mediaFile
        ? `${MEDIA_URL}${review.mediaFile}`
        : ''
  }, [file, review?.mediaFile])

  if (isLoading || !review) return <Preloader />

  return (
    <>
      <PageHeader title={`${review.name || 'No title'}`} backPath={`/my-reviews`} />
      <PageBox sx={{ height: '100%' }} paperMaxWidth="70rem">
        <ReviewerReviewInfo review={review} />

        <Box>
          <Tabs value={currentTabIndex} onChange={handleTabChange}>
            <Tab label="video" sx={{ width: '8.5rem' }} />
            <Tab label="history" sx={{ width: '8.5rem' }} />
          </Tabs>
        </Box>

        <Box sx={{ mt: '0.5rem', position: 'relative' }}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                flexDirection: { xs: 'column-reverse', sm: 'column-reverse', md: 'row' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '1.5rem',
                  flexDirection: 'column',
                  width: '100%',
                  position: 'relative',
                  maxWidth: { xs: '100%', sm: '100%', md: '42.25rem' },
                }}
              >
                <PercentageCircularProgress
                  loader={false} //TODO: add history loading
                  loaderUpload={isSendingForModeration}
                  value={uploadProgress}
                />
                <Box>
                  {videoUrl ? (
                    <VideoPlayer src={`${videoUrl}`} />
                  ) : (
                    <VideoDropzone
                      file={file}
                      setFile={setFile}
                      maxSize={268435456} //256MB
                      acceptedFormats={{ 'video/mp4': ['.mp4', '.MP4'] }}
                      wrapperSx={{ height: '21.5rem' }}
                    />
                  )}
                  {fileError && (
                    <Typography sx={{ color: 'error.main', ml: '0.5rem' }}>{fileError}</Typography>
                  )}
                </Box>
                {(review.status === ReviewStatus.RETURNED ||
                  review.status === ReviewStatus.DRAFT) && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: { xs: '1rem', sm: '1rem', md: '1.5rem' },
                      flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                    }}
                  >
                    <Box sx={{ width: { xs: '100%', sm: '100%', md: '50%' } }}>
                      <UploadFileButton
                        setFile={setFile}
                        maxSize={268435456} //256MB
                        acceptedFormats={['.mp4']}
                        name={file || videoUrl ? 'Update video' : 'Add video'}
                        endIcon={file ? null : <AddIcon fontSize="small" />}
                      />
                    </Box>

                    {(!file &&
                      !review.mediaFile &&
                      (review.status as ReviewStatus) === ReviewStatus.DRAFT) ||
                    (!file && (review.status as ReviewStatus) === ReviewStatus.RETURNED) ||
                    reviewName === '' ? (
                      <Tooltip
                        title={
                          !file
                            ? 'Please upload a video'
                            : reviewName === ''
                              ? 'Please enter a video review name'
                              : ''
                        }
                      >
                        <Button
                          sx={{ width: { xs: '100%', sm: '100%', md: '50%' } }}
                          type="submit"
                          disabled
                        >
                          Send for moderation
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        type="submit"
                        sx={{ width: { xs: '100%', sm: '100%', md: '50%' } }}
                        disabled={isSendingForModeration}
                      >
                        Send for moderation
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  maxWidth: { xs: '100%', sm: '100%', md: '20.75rem' },
                  width: '100%',
                }}
              >
                {review.status === ReviewStatus.RETURNED || review.status === ReviewStatus.DRAFT ? (
                  <>
                    <TextInput
                      name="name"
                      label="Video name"
                      control={control}
                      errors={errors}
                      sx={{ height: 'auto' }}
                    />
                    <TextInput
                      name="description"
                      label="Description"
                      control={control}
                      errors={errors}
                      sx={{ height: 'auto' }}
                      multiline
                      rows={8}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontWeight: '700',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontSize: '1rem',
                      }}
                    >
                      {review.name || 'No title'}
                    </Typography>
                    <Typography sx={{ wordWrap: 'break-word' }}>
                      {review.description || 'No description'}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          {currentTabIndex === 1 && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: 'calc(100% + 0.5rem)',
                top: '-0.5rem',
                left: 0,
                overflow: 'hidden',
                bgcolor: '#FFFFFF',
                zIndex: 9,
              }}
            >
              <ReviewHistory reviewId={review.id} />
            </Box>
          )}
        </Box>
      </PageBox>
    </>
  )
}

export default MyReview
