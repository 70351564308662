import { HistoryItemByDate, ReviewHistoryRecord } from 'services/reviews/types'

// transformation review history list by date
export const transformReviewHistoryByDate = (inputHistoryList: ReviewHistoryRecord[]) => {
  const outputHistoryList: HistoryItemByDate = {}
  inputHistoryList.map((inputHistoryItem) => {
    const date = inputHistoryItem.createdAt.split('T')[0]
    outputHistoryList[date] = outputHistoryList[date]
      ? [...outputHistoryList[date], inputHistoryItem]
      : [inputHistoryItem]
  })

  return outputHistoryList
  // return inputHistoryList.reduce((prev: HistoryItemByDate, curr) => {
  //   const date = curr.createdAt.split('T')[0]
  //   return prev?.[date] ? { ...prev, [date]: [...prev[date], curr] } : { ...prev, [date]: [curr] }
  // }, {})
}
