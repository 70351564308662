import { object, string } from 'yup'

export const requiredMessage = 'This field is required'

const SUBJECT_MIN = 5
const SUBJECT_MAX = 50
const DESCRIPTION_MIN = 5
const DESCRIPTION_MAX = 150

export const feedbackSchema = object({
  subject: string().required(requiredMessage).min(SUBJECT_MIN).max(SUBJECT_MAX),
  description: string().required(requiredMessage).min(DESCRIPTION_MIN).max(DESCRIPTION_MAX),
})
